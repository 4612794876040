import React from 'react'

import Switch from '@material-ui/core/Switch';
import CheckboxMU from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {FormHelperText} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";

import renderHTML from 'react-render-html';

export default class Checkbox extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      data: props.data,
      index: props.index,
      checked: props.data.answer?.bool || false,
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  _handleChange(e){
    let {
      data,
      index,
      checked
    } = this.state

    let value = {field: data.id, bool: e.target.checked};
    data.error = false
    data.answer = value
    this.setState({data})
    this.props.onUpdated(index, value)
    this.setState({checked: !checked})
  }

  _renderCheckbox(){
    let {
      data,
      checked
    } = this.state

    if(data.is_required){
      let label = data.title;
      if(data.url){
        label = `<a href=${data.url} target="_blank">${data.title}</a>`
      }
      return (
        <>
          <FormControlLabel
            control={
              <CheckboxMU
                checked={checked}
                onChange={e => this._handleChange(e)}
              />
            }
            label={renderHTML(label)}
          />
        </>
      )
    }else{
      return (
        <FormControlLabel
          control={
            <Switch
              checked={checked}
              onChange={e => this._handleChange(e)}
            />
          }
          label={data.title}
        />
      )
    }
  }

  render() {
    let {
      data,
      index,
      checked
    } = this.state

    let errorMessage = "This field is required."

    if(data.error_message){
      errorMessage = data.error_message
    }

    return (
      <>
        <FormControl
          className='field-checkbox'
          fullWidth
          variant={"outlined"}
          error={data.error}
        >
          {this._renderCheckbox()}
          {
            data.error &&
            <FormHelperText>{errorMessage}</FormHelperText>
          }
        </FormControl>
      </>
    )
  }
}
