import extractDomain from 'extract-domain';

export default class Website {
  static getDomain(website){
    let url = Website.getCustomDomain(website) || Website.getNativeDomain(website)
    return url
  }

  static getCustomDomain(website){
    if(!website.url){
      return null
    }

    if(website.url.startsWith("http://") || website.url.startsWith("https://")){
      return website.url
    }
    return "http://" + website.url
  }

  static getNativeDomain(website){
    return "https://" + website.slug + "." + window.General.WebsiteNakedDomain
  }

  static getCleanDomain(website){
    let url = Website.getDomain(website)
    url = url.replace("https://", "").replace("http://", "")
    return url
  }

  static getDashboardUrl(website){
    if(website.url){
      return window.General.ClientDashboardUrl
    }
    return Website.getRelativeUrl(website, "dashboard")
  }

  static getCleanDomain(website){
    let url = Website.getDomain(website)
    url = url.replace("https://", "").replace("http://", "")
    return url
  }

  static getRelativeUrl(website, subdomain){
    let url = window.location.hostname
    let domain = extractDomain(url, { tld: true }) || url;

    if (url.indexOf("localhost") === -1) {
      return `https://${subdomain}.${domain}`
    } else {
      return window.General.ClientDashboardUrl
    }
  }
}
