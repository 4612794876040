import React from 'react'

import Dropdown from './Dropdown';

import General from '../utils/General'
import Website from '../utils/Website'
import Backend from '../utils/Backend'

export default class FormSelect extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
        forms: props.forms,
        selectedOption: null
    }
  }

  componentDidMount(){
    this.setState({
      options: this._getOptions()
    })
  }

  componentWillReceiveProps(nextProps){
    this.setState({
      ...nextProps,
      options: this._getOptions(),
    })
  }

  _onAddFormClicked() {
    let url = Website.getDashboardUrl(window.CURRENT_WEBSITE );

    this.setState({ loadingHash: true });

    let editWebsiteTab = window.open();
    return Backend.createLoginHash()
    .then((hash) => {
      editWebsiteTab.location.href = url + "/forms?h=" + hash.value + "&wid=" + window.CURRENT_WEBSITE_ID
      this.setState({ loadingHash: false });
    })
    .catch((error) => {
      window.open(url);
      this.setState({ loadingHash: false });
    });
  }

  _getEistingForms(){
    let { forms } = this.state;
    let options = []
    for(var i = 0; i < forms.length; i++){
      let form = forms[i]

      let formOption = {
        value: form.id,
        label: form.name,
      }

      options.push(formOption)
    }
    return options
  }

  _getOptions(){
    let options = [
      { value: 'new', label: 'No Remaining Forms' }
    ];

    if(this.state.forms.length > 0){
      options.push({
         type: 'group',
         name: 'Add New Form',
         items: this._getEistingForms()
      })
    }

    return options
  }

  _getSelected(forms, value){
    if(forms.length === 0){
      return null
    }

    for(var i=0; i < forms.length; i++){
      let form = forms[i];
      if(value === form.id){
        return form
      }
    }

    return null
  }

  render() {
    let {
      forms,
      selectedOption,
      loadingHash
    } = this.state

    let options = this._getOptions()
    return (
      <>
        <Dropdown
            options={options}
            value={selectedOption}
            onChange={selectedOption => {
              let selectedForm = this._getSelected(forms, selectedOption.value)
              if(selectedForm){
                this.props.onFormSelected(selectedForm)
              }
              else{
                this.props.onAddNewFormSelected()
              }

              this.setState({
                selectedOption: null,
                selectedForm: null
              })
            }}
            placeholder="Add New Form"
        />
        <a
          className="btn btn--primary w-100"
          style={{
            backgroundColor: "#222",
            borderColor: "#222"
          }}
          onClick={() => this._onAddFormClicked()}
          disabled={loadingHash}
        >
          <span className="btn__text">
            Add New Form
          </span>
        </a>
      </>
    )
  }
}
