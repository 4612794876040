import React from "react";

import Collapsible from "react-collapsible";
import Dropdown from '../../Dropdown';

import H2 from "./H2";
import Img from "./Img";

import ImageUploader from "./ImageUploader";
import GradientPicker from "./GradientPicker";

import ColorPicker from "../../ColorPicker";
import Border from "../../Border";

import AddContentBlockButton from "../../AddContentBlockButton";

import SectionFormValues from "./SectionFormValues";

import LocationInput from "./LocationInput";

import PortalModal from "../../modal/PortalModal";

import General from "../../../utils/General";
import Backend from "../../../utils/Backend";
import DeleteModal from "../../modal/settings/DeleteModal";

const BLOCK_ANIMATION_OPTIONS = [
  {
    value: "none",
    label: "None"
  },
  {
    value: "fade-up",
    label: "Fade Up"
  },
  {
    value: "fade-down",
    label: "Fade Down"
  },
  {
    value: "fade-left",
    label: "Fade Left"
  },
  {
    value: "fade-right",
    label: "Fade Right"
  },
  {
    value: "flip-up",
    label: "Flip Up"
  },
  {
    value: "flip-down",
    label: "Flip Down"
  },
  {
    value: "flip-left",
    label: "Flip Left"
  },
  {
    value: "flip-right",
    label: "Flip Right"
  },
  {
    value: "slide-up",
    label: "Slide Up"
  },
  {
    value: "slide-down",
    label: "Slide Down"
  },
  {
    value: "slide-left",
    label: "Slide Left"
  },
  {
    value: "slide-right",
    label: "Slide Right"
  },
  {
    value: "zoom-in-up",
    label: "Zoom In Up"
  },
  {
    value: "zoom-in-down",
    label: "Zoom In Down"
  },
  {
    value: "zoom-in-left",
    label: "Zoom In Left"
  },
  {
    value: "zoom-in-right",
    label: "Zoom In Right"
  }
]

export default class Section extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
      index: props.index,
      showSettingsModal: false,
      uuid: General.uuid(),
      className: props.className,
      isImageLoading: false,
      editable: props.editable,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  render() {
    let { className, data, index, uuid, editable } = this.state;

    let classNames = "add-content-block section ";

    if (index === 0) {
      className += " first-section ";
    }

    if (editable) {
      classNames += " editable-block ";
    }

    let anchor = data.anchor ? data.anchor : "cb_" + General.uuid();

    let sectionProps = {
      id: anchor,
      className: classNames + className,
      "data-overlay": data.settings.overlay,
      "data-name": data.block.name,
      "data-index": index,
      style: {
        marginTop: data.settings.margin_top,
        marginBottom: data.settings.margin_bottom,
        marginLeft: data.settings.margin_left,
        marginRight: data.settings.margin_right,
        paddingTop: data.settings.padding_top,
        paddingBottom: data.settings.padding_bottom,
        paddingLeft: data.settings.padding_left,
        paddingRight: data.settings.padding_right,
        backgroundColor: data.settings.tertiary_color,
        position: "relative",
      },
    };

    // Website Card Style overwrites
    if( window.IS_CARD_STYLE_WEBSITE ){
        sectionProps.style = {
          backgroundColor: data.settings.tertiary_color
        }
    }

    if (
      data.background_gradient &&
      data.background_gradient.indexOf("#") > -1 &&
      data.gradient !== false
    ) {
      sectionProps["data-gradient-bg"] = data.background_gradient;
    }

    let deepCardifyStyles = [10,12,18,25,26,38,39,40,43,46,48,53].indexOf(data.block_id) > -1 && window.IS_CARD_STYLE_WEBSITE;

    return (
      <>
        <section
          {...sectionProps}
          data-aos={data.settings.animation}
          data-aos-id={sectionProps.className.search(' parallax ')!=-1?"custom":""}
        >
          {this.props.children}
          {this._renderEditIcons(data, uuid, editable)}
          {this._renderSettingsModal(data)}
          {deepCardifyStyles &&
            <style
              dangerouslySetInnerHTML={{
                __html: `
                section#${sectionProps.id}.deep-cardify.block-${data.block_id} .item {
                  background-color: ${data.settings.tertiary_color} !important;
                }
              `,
              }}
            />
          }
        </section>
        {this._renderAddContentBlockButton(editable, index)}
        {this._renderDeleteModal()}
      </>
    );

  }

  _renderAddContentBlockButton(editable, index) {
    if (!editable) {
      return null;
    }
    return (
      <AddContentBlockButton
        index={index}
        onAddContentBlockPressed={(contentBlock) =>
          this.props.onAddContentBlockPressed(contentBlock)
        }
      />
    );
  }

  _renderMap(data) {
    if (!data.map) {
      return null;
    }

    return (
      <Border>
        <Collapsible trigger="Location">
          <br></br>
          <LocationInput
            location={data.map.location}
            onUpdated={(location) => {
              let updatedLocation = {
                ...data.map.location,
                ...location,
              };
              data.map.location = updatedLocation;
              this.props.onUpdated(data);
            }}
          />
        </Collapsible>
      </Border>
    );
  }

  _renderEditImage(image) {
    if (!this.props.onImageUpdated) {
      return null;
    }

    let src = image ? image.thumbnail : "";
    return (
      <Border>
        <Collapsible trigger={this.props.imageTitle}>
          <br></br>
          <ImageUploader
            title={""}
            src={src}
            onUploaded={(image) => this.props.onImageUpdated(image)}
          />
        </Collapsible>
      </Border>
    );
  }

  _renderEditPreviewImage() {
    let { data } = this.state;
    if (window.CURRENT_PAGE_ID != window.Api.AddBlocksPageId) {
      return null;
    }

    let src = data.preview_image ? data.preview_image.thumbnail : "";
    return (
      <Border>
        <Collapsible trigger="Add Block Image">
          <br />
          <ImageUploader
            title={""}
            src={src}
            onUploaded={(image) => {
              data.preview_image = image;
              Backend.updatePreviewImage(data).then(() => {
                this.props.onUpdated(data);
              });
            }}
          />
        </Collapsible>
      </Border>
    );
  }

  _renderCurrentImage(src) {
    if (!src) {
      return null;
    }

    return (
      <div className="col-md-3 image d-flex align-items-center justify-content-center">
        <Img className="image-uploaded" src={src} />
      </div>
    );
  }

  _renderGradientPicker(gradient) {
    if (!this.props.onGradientUpdated) {
      return null;
    }

    return (
      <Border>
        <Collapsible trigger="Gradient">
          <br></br>
          <GradientPicker
            gradient={gradient}
            onUpdated={(colors) => {
              if (colors.length > 0) {
                colors = colors.join(",");
              } else {
                colors = null;
              }
              this.props.onGradientUpdated(colors);
            }}
          />
        </Collapsible>
      </Border>
    );
  }

  _renderSectionFormValue(data, editable) {
    if (!editable) {
      return null;
    }

    return <SectionFormValues data={data} index={this.props.index} />;
  }

  _updateIntSetting(key, value) {
    let { data } = this.state;
    data.settings[key] = parseInt(value);

    this.props.onUpdated(data);
  }

  _updateSetting(key, value) {
    let { data } = this.state;
    data.settings[key] = value;

    this.props.onUpdated(data);
  }

  _updateData(key, value) {
    let { data } = this.state;
    data[key] = value;

    this.props.onUpdated(data);
  }

  _renderSetting(title, key, value) {
    return (
      <div className="col-sm-3">
        <p>{title}</p>
        <input
          type="number"
          defaultValue={value}
          onChange={(event) => {
            this._updateIntSetting(key, event.target.value || 0);
          }}
        />
      </div>
    );
  }

  _renderSettings(data) {

    let selectedBlockAnimation = BLOCK_ANIMATION_OPTIONS.find(blockAnimation => blockAnimation.value === data.settings.animation )

    return (
      <>

        <Border>
          <Collapsible 
            className = "v-sub-title"
            openedClassName  = "v-sub-title"
            trigger={
              <>
                Animation
                <small>Add animation to your block as you scroll</small>
              </>
            }
          >
            <br/>
            <span>Select Block Animation Type</span>
            <Dropdown
                options={BLOCK_ANIMATION_OPTIONS}
                value={selectedBlockAnimation}
                onChange={option => {
                  data.settings.animation = option.value
                  this.props.onUpdated(data)
                  this.setState({ data }, () => General.updateBlockAnimateOnScroll())
                }}
                placeholder="Select An Option"
            />
          </Collapsible>
        </Border>

        { !window.IS_CARD_STYLE_WEBSITE &&
          <Border>
            <Collapsible 
              className = "v-sub-title"
              openedClassName  = "v-sub-title"
              trigger={
                <>
                  Margin & Padding
                  <small>Adjust the spacing of the block</small>
                </>
              }
            >
              {this.props.renderAdvancedSettings()}
              <br />
              <br />
              <h5>Margin</h5>
              <div className="row">
                {this._renderSetting(
                  "Top",
                  "margin_top",
                  data.settings.margin_top
                )}
                {this._renderSetting(
                  "Right",
                  "margin_right",
                  data.settings.margin_right
                )}
                {this._renderSetting(
                  "Bottom",
                  "margin_bottom",
                  data.settings.margin_bottom
                )}
                {this._renderSetting(
                  "Left",
                  "margin_left",
                  data.settings.margin_left
                )}
              </div>
              <br />
              <h5>Padding</h5>
              <div className="row">
                {this._renderSetting(
                  "Top",
                  "padding_top",
                  data.settings.padding_top
                )}
                {this._renderSetting(
                  "Right",
                  "padding_right",
                  data.settings.padding_right
                )}
                {this._renderSetting(
                  "Bottom",
                  "padding_bottom",
                  data.settings.padding_bottom
                )}
                {this._renderSetting(
                  "Left",
                  "padding_left",
                  data.settings.padding_left
                )}
              </div>
              <br />
            </Collapsible>
          </Border>
        }

        <Border>
          <Collapsible 
              className = "v-sub-title"
              openedClassName  = "v-sub-title"
              trigger={
                <>
                  Advanced Settings
                  <small>Delete, duplicate or hide your block</small>
                </>
              }
            >
            <br></br>
            {this._renderAdvancedBlockSettings(data)}
          </Collapsible>
        </Border>

      </>
    );
  }

  _onDuplicateBlock(contentBlockId){
    Backend.getDuplicateData(contentBlockId).then(contentBlock => {
      this.props.onAddContentBlockPressed(contentBlock)
    }).catch(e => {})
  }

  _renderAdvancedBlockSettings(data){
    let title = "Hide Block"
    if(data.hidden){
      title = "Unhide Block"
    }

    return (
      <>
        <div className="row">
          <div className="col-md-12">
            <div>
              <a
                className="btn btn-hide-block border-0"
                onClick={() => {
                  this.setState({ showSettingsModal: false });
                  this.props.onVisibilityButtonPressed(this.state.index);
                }}
              >
                <i className={`icon-Eye pr-2`}></i>
                {title}
              </a>
            </div>
            <div>
              <a
                className="btn btn-hide-block border-0 "
                onClick={() => {
                  this.setState({ showSettingsModal: false });
                  this._onDuplicateBlock(data.id);
                }}
              >
                <i className="icon-Duplicate-Layer pr-2"></i>
                Duplicate Block
              </a>
            </div>
            <div>
              <a
                className="btn btn-delete-block border-0 "
                onClick={() => {
                  this.setState({ showSettingsModal: false });
                  this.setState({ showDeleteModal: true });
                }}
              >
                <i className="flaticon2-trash pr-2 text-danger"></i>
                Delete Block
              </a>
            </div>
          </div>
        </div>
      </>
    );
  }

  _renderMoveUpButton() {
    if (!this.props.canMoveUp) {
      return null;
    }
    return this._renderMoveButton("up");
  }

  _renderMoveDownButton() {
    if (!this.props.canMoveDown) {
      return null;
    }
    return this._renderMoveButton("down");
  }

  _renderMoveButton(direction) {
    return (
      <a
        href="javascript:void(0)"
        className={"button " + direction}
        onClick={() => {
          if (direction === "up") {
            this.props.onMoveUpPressed();
          } else {
            this.props.onMoveDownPressed();
          }
        }}
      >
        {direction == "up" && (
          <>
            <span class="slide-text">Move Block Up</span>
            <i class="material-icons m-icon-s2 c-white-opa-8">
              keyboard_arrow_up
            </i>
          </>
        )}
        {direction == "down" && (
          <>
            <span class="slide-text">Move Block Down</span>
            <i class="material-icons m-icon-s2 c-white-opa-8">
              keyboard_arrow_down
            </i>
          </>
        )}
      </a>
    );
  }

  _renderEditIcons(data, uuid, editable) {
    if (!editable) {
      return null;
    }

    return (
      <div className="settings">
        <div className="buttons">
          <div
            className="button-move up button-slide"
            data-html2canvas-ignore="true"
          >
            {this._renderMoveUpButton()}
          </div>

          {/* Starts : Button : Edit Block Settings */}
          <div className="s-edit">
            {
              data.hidden &&
              <>
                {/* Starts : Button : Unhide Block */}
                <div
                  className="button-unhide button-slide"
                  data-html2canvas-ignore="true"
                >
                  <a
                    href="javascript:void(0)"
                    onClick={() => {
                      this.props.onVisibilityButtonPressed(this.state.index);
                    }}
                    className="button unhide"
                  >
                    <span className="slide-text">Unhide Block</span>
                    <i className="material-icons m-icon-s1 c-white-opa-8">
                      visibility_off
                    </i>
                  </a>
                </div>
                {/* End : Button : Unhide Block */}
              </>
            }

            <div
              className="button-edit button-slide"
              data-html2canvas-ignore="true"
            >
              <a
                href="javascript:void(0)"
                onClick={() => this.setState({ showSettingsModal: true })}
                className="button"
              >
                <span class="slide-text">Edit Block Settings</span>
                <i class="material-icons m-icon-s1 c-white-opa-8">settings</i>
              </a>
            </div>

            <div className="c-tool-tips four">
              {General.renderTourButtonTitle("left", "Block Settings")}
            </div>
          </div>
          {/* End : Button : Edit Block Settings */}

          <div
            className="button-move down button-slide"
            data-html2canvas-ignore="true"
          >
            {this._renderMoveDownButton()}
          </div>
        </div>
      </div>
    );
  }

  _renderSettingsModal(data) {
    return (
      <PortalModal
        show={this.state.showSettingsModal}
        onHide={() => this.setState({ showSettingsModal: false })}
      >
        <H2
          text={data.name || data.block.name}
          color="black"
          textAlign="left"
          className="textarea-edit-modal mb-5"
          editable={true}
          onUpdated={(name) => {
            data.name = name;
            this.props.onUpdated(data);
          }}
        />

        {this._renderTitles(data)}
        {this.props.renderModalContent()}
        {this._renderMap(data)}
        {this._renderEditImage(this.props.image)}
        {this._renderGradientPicker(data.background_gradient)}
        {this._renderButtonToggles(data)}
        {this._renderColors(data)}
        {this.props.renderModalBottomContent()}
        {this._renderSettings(data)}
        {this._renderEditPreviewImage()}
      </PortalModal>
    );
  }

  _renderButtonToggles(data) {
    if (!this.props.primaryButtonToggle && !this.props.secondaryButtonToggle) {
      return null;
    }

    return (
      <Border>
        <Collapsible trigger="Buttons">
          <br />
          {this._renderPrimaryButtonToggle(data)}
          {this._renderSecondaryButtonToggle(data)}
        </Collapsible>
      </Border>
    );
  }

  _renderPrimaryButtonToggle(data) {
    if (!this.props.primaryButtonToggle) {
      return null;
    }
    return (
      <>
        <div class="row">
          <div class="col-md-8">
            <h4>Primary</h4>
          </div>
          <div class="col-md-4 text-right">
            <div class="input-checkbox input-checkbox--switch">
              <input
                id={data.id + "__cb_pb"}
                type="checkbox"
                defaultChecked={data.primary_button}
                onChange={(e) => {
                  if (e.target.checked) {
                    data.primary_button = DEFAULT_PRIMARY_BUTTON;
                  } else {
                    data.primary_button = null;
                  }
                  this.props.onUpdated(data);
                }}
              />
              <label for={data.id + "__cb_pb"}></label>
            </div>
          </div>
        </div>
      </>
    );
  }

  _renderSecondaryButtonToggle(data) {
    if (!this.props.secondaryButtonToggle) {
      return null;
    }
    return (
      <>
        <div class="row">
          <div class="col-md-8">
            <h4>Secondary</h4>
          </div>
          <div class="col-md-4 text-right">
            <div class="input-checkbox input-checkbox--switch">
              <input
                id={data.id + "__cb_sb"}
                type="checkbox"
                defaultChecked={data.secondary_button}
                onChange={(e) => {
                  if (e.target.checked) {
                    data.secondary_button = DEFAULT_SECONDARY_BUTTON;
                  } else {
                    data.secondary_button = null;
                  }
                  this.props.onUpdated(data);
                }}
              />
              <label for={data.id + "__cb_sb"}></label>
            </div>
          </div>
        </div>
      </>
    );
  }

  _renderTitles(data) {

    if (this.props.renderBlockTitleCard == false) {
      return null;
    }
    
    let showBlockTitlesCard = data.block.title || data.block.subtitle
    let showBlockTitleToggle = data.block.title
    let showBlockSubtitleToggle = data.block.subtitle

    if ( !showBlockTitlesCard ) {
      return null;
    }

    return (
      <Border>
        <Collapsible trigger="Block Titles">
          <br />
          {showBlockTitleToggle && this._renderToggle("title", "Large Text Heading", "Title Text")}
          {showBlockSubtitleToggle && this._renderToggle(
            "subtitle",
            "Small Description Text Line",
            "Hover and click into this text to change it your own text to suit your website."
          )}
        </Collapsible>
      </Border>
    );
  }

  _renderToggle(
    key,
    title,
    defaultOnValue,
    defaultOffValue = null,
    callback = null,
    onChange = null
  ) {
    let { data } = this.state;

    let checked = data[key] != null && data[key] != false;

    let labelId = "tg_" + data.id + "__" + key + "__cb_pb";
    return (
      <div class="row">
        <div class="col-md-9">
          <h4>{title}</h4>
        </div>
        <div class="col-md-3 text-right">
          <div class="input-checkbox input-checkbox--switch">
            <input
              id={labelId}
              type="checkbox"
              checked={checked}
              onChange={(e) => {
                if (onChange) {
                  onChange(e);
                  return;
                }
                data[key] = e.target.checked ? defaultOnValue : defaultOffValue;
                this.props.onUpdated(data, callback);
              }}
            />
            <label for={labelId}></label>
          </div>
        </div>
      </div>
    );
  }

  _renderColors(data) {

    if (
      data.primaryColor == false &&
      data.secondaryColor == false &&
      data.tertiaryColor == false
    ) {
      return null;
    }

    let showBlockTitleColor = data.block.title && data.title
    let showBlockSubtitleColor = data.block.subtitle && data.subtitle

    return (
      <Border>
        <Collapsible 
          className = "v-sub-title"
          openedClassName  = "v-sub-title"
          trigger={
            <>
              Colours
              <small>Adjust the colours of the UI elements</small>
            </>
          }
        >
          <br />
          {showBlockTitleColor && this._renderBlockTitleColorPicker(data)}
          {showBlockSubtitleColor && this._renderBlockSubTitleColorPicker(data)}
          {this._renderPrimaryColorPicker(data)}
          {this._renderSecondaryColorPicker(data)}

          {this._renderQuaternaryColorPicker(data)}

          {this._renderQuinaryColorPicker(data)}

          {this._renderSenaryColorPicker(data)}

          {this._renderTertiaryColorPicker(data)}

          {this.props.renderSupplimentaryColors()}
        </Collapsible>
      </Border>
    );
  }

  _renderBlockTitleColorPicker(data) {
    if (this.props.blockTitleColor == false) {
      return false;
    }
    let title = this.props.blockTitleColourText || "Block Large Text Heading Colour";
    return (
      <>
        <ColorPicker
          title={title}
          color={data.title_color}
          onColorSelected={(color) => {
            data.title_color = color;
            this._updateData("title_color", color);
          }}
        />
        <br />
      </>
    );
  }

  _renderBlockSubTitleColorPicker(data) {
    let title = this.props.blockSubTitleColourText || "Block Small Description Text Colour";
    return (
      <>
        <ColorPicker
          title={title}
          color={data.subtitle_color}
          onColorSelected={(color) => {
            data.subtitle_color = color;
            this._updateData("subtitle_color", color);
          }}
        />
        <br />
      </>
    );
  }

  _renderPrimaryColorPicker(data) {
    if (this.props.primaryColor == false) {
      return false;
    }
    let title = this.props.primaryColorTitle || "Title Colour";
    return (
      <>
        <ColorPicker
          title={title}
          color={data.settings.primary_color}
          onColorSelected={(color) => {
            this._updateSetting("primary_color", color);
          }}
        />
        <br />
      </>
    );
  }

  _renderSecondaryColorPicker(data) {
    if (this.props.secondaryColor == false) {
      return false;
    }
    let title = this.props.secondaryColorTitle || "Text Colour";
    return (
      <>
        <ColorPicker
          title={title}
          color={data.settings.secondary_color}
          onColorSelected={(color) => {
            this._updateSetting("secondary_color", color);
          }}
        />
        <br />
      </>
    );
  }

  _renderTertiaryColorPicker(data) {
    if (this.props.tertiaryColor == false) {
      return false;
    }
    let title = this.props.tertiaryColorTitle || "Background Colour";
    return (
      <>
        <ColorPicker
          title={title}
          color={data.settings.tertiary_color}
          onColorSelected={(color) => {
            this._updateSetting("tertiary_color", color);
          }}
        />
        <br />
      </>
    );
  }

  _renderQuaternaryColorPicker(data) {
    if (
      this.props.quaternaryColor == false ||
      !this.props.quaternaryColorTitle
    ) {
      return false;
    }
    return (
      <>
        <ColorPicker
          title={this.props.quaternaryColorTitle}
          color={data.settings.quaternary_color}
          onColorSelected={(color) => {
            this._updateSetting("quaternary_color", color);
          }}
        />
        <br />
      </>
    );
  }

  _renderQuinaryColorPicker(data) {
    if (this.props.quinaryColor == false || !this.props.quinaryColorTitle) {
      return false;
    }
    return (
      <>
        <ColorPicker
          title={this.props.quinaryColorTitle}
          color={data.settings.quinary_color}
          onColorSelected={(color) => {
            this._updateSetting("quinary_color", color);
          }}
        />
        <br />
      </>
    );
  }

  _renderSenaryColorPicker(data) {
    if (this.props.senaryColor == false || !this.props.senaryColorTitle) {
      return false;
    }
    return (
      <>
        <ColorPicker
          title={this.props.senaryColorTitle}
          color={data.settings.senary_color}
          onColorSelected={(color) => {
            this._updateSetting("senary_color", color);
          }}
        />
        <br />
      </>
    );
  }

  _renderDeleteModal() {
    return (
      <DeleteModal
        showModal={this.state.showDeleteModal}
        onDelete={() => {
          this.setState({ showDeleteModal: false });
          this.props.onDelete();
        }}
        onHide={() => {
          this.setState({ showDeleteModal: false });
        }}
      />
    );
  }
}

Section.defaultProps = {
  renderModalContent: () => {},
  renderModalBottomContent: () => {},
  renderAdvancedSettings: () => {},
  primaryButtonToggle: false,
  secondaryButtonToggle: false,
  renderSupplimentaryColors: () => {}
};

export const DEFAULT_PRIMARY_BUTTON = {
  settings: {
    primary_color: "#ffffff",
    msc_enabled: true,
  },
  badge_settings: {
    primary_color: "#ffffff",
    secondary_color: null,
    msc_enabled: true,
  },
  title: "PRIMARY BUTTON TEXT",
  url: "#",
  badge: null,
};

export const DEFAULT_SECONDARY_BUTTON = {
  settings: {
    secondary_color: "transparent",
    msc_enabled: true,
  },
  badge_settings: {
    primary_color: "#ffffff",
    secondary_color: null,
    tertiary_color: "#007ec1",
    msc_enabled: true,
  },
  title: "SECONDARY BUTTON TEXT",
  url: "#",
  badge: null,
};
