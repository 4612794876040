import React, { useState } from "react";
import {
  format,
  subMonths,
  addMonths,
  startOfWeek,
  addDays,
  isSameDay,
  lastDayOfWeek,
  getWeek,
  addWeeks,
  subWeeks
} from "date-fns";

const WeekCalendar = ({ onSelected, selectedDate, isDisabled }) => {
  const [currentMonth, setCurrentMonth] = useState(selectedDate);
  const [currentWeek, setCurrentWeek] = useState(getWeek(currentMonth));

  const changeWeekHandle = (btnType) => {
    //console.log("current week", currentWeek);
    if (btnType === "prev") {
      //console.log(subWeeks(currentMonth, 1));
      setCurrentMonth(subWeeks(currentMonth, 1));
      setCurrentWeek(getWeek(subWeeks(currentMonth, 1)));
    }
    if (btnType === "next") {
      //console.log(addWeeks(currentMonth, 1));
      setCurrentMonth(addWeeks(currentMonth, 1));
      setCurrentWeek(getWeek(addWeeks(currentMonth, 1)));
    }
  };

  const renderHeader = () => {
    const dateFormat = "MMM yyyy";

    return (
      <div className="top-bar">
        <a 
          href="javascript:void(0)" 
          onClick={() => changeWeekHandle("prev")}
        >
          PREV
        </a>
        <span>
          {format(currentMonth, dateFormat)}
        </span>
        <a 
          href="javascript:void(0)" 
          onClick={() => changeWeekHandle("next")}
        >
          NEXT
        </a>
      </div>
    )
  }

  const renderCells = () => {
    const startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });
    const endDate = lastDayOfWeek(currentMonth, { weekStartsOn: 1 });
    const dateFormat = "d";
    const rows = [];
    let days = [];
    let day = startDate;
    let formattedDate = "";
    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, dateFormat);
        const cloneDay = day;

        let isToday = isSameDay(day, new Date())
        let isSelected = isSameDay(day, selectedDate)
        let disabled = isDisabled(day)
        let className = ""
        if(isSelected){
          className += " selected"
        }
        if(disabled){
          className += " disabled"
        }
        if(isToday){
          className += " today"
        }
        days.push(
          <button
            key={day}
            disabled={disabled}
            className={`btn btn--secondary skip ${className}`}
            onClick={() => {
              onSelected(cloneDay);
            }}
          >
            {format(day, "EEE").toUpperCase()}
            <span>{formattedDate}</span>
          </button>
        );
        day = addDays(day, 1);
      }

      rows.push(
        <>
          {days}
        </>
      );

      days = [];
    }
    return <div className="days">{rows}</div>;
  };


  return (
    <div className="week-calendar">
      {renderHeader()}
      {renderCells()}
    </div>
  );
};

export default WeekCalendar;
