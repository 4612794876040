import React from 'react'

import Button from '../Button'

import H2 from './editable/H2'
import Img, { Sizes } from './editable/Img'
import P from './editable/P'
import Section from './editable/Section'

import General from '../../utils/General'
import Backend from '../../utils/Backend'

export default class Block36 extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      data: props.data,
      editable: props.editable
    }

    this.updateContentBlock = General.debounce(() => {
      console.log("3333", this.state.data)
      Backend.updateContentBlock(this.state.data)
    }, 1000, false)
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _update(data, callback){
    this.setState({ data }, () => {

      if(callback){
        callback()
      }
      this.updateContentBlock()
    })
  }

  render(){
    let { data, editable } = this.state
    let showTitle = data.title
    let showSubtitle = data.subtitle

    return (
      <Section
          data={ data }
          index={ this.props.index }
          className={ "text-center block-36 unpad--bottom switchable cta cta-3 imagebg " + this.props.className }
          editable={ editable }
          onUpdated={data => this._update(data)}
          onDelete={() => this.props.onDelete()}
          primaryColor={false}
          secondaryColor={false}
          onAddContentBlockPressed={contentBlock => this.props.onAddContentBlockPressed(contentBlock)}
          onGradientUpdated={gradient => {
            data.background_gradient = gradient
            this._update(data, () => General.updateGradients())
          }}
          canMoveUp={ this.props.canMoveUp }
          canMoveDown={ this.props.canMoveDown }
          onVisibilityButtonPressed={(index) => this.props.onVisibilityButtonPressed(index)}
          onMoveUpPressed={ () => this.props.onMoveUpPressed() }
          onMoveDownPressed={ () => this.props.onMoveDownPressed() }
      >
          <div className="container">
              <div className="row">
                  <div className="col-md-6 col-lg-7 col-content">
                      <div className="switchable__text">
                        {showTitle &&
                          <H2
                            text={ data.title}
                            color={ data.title_color }
                            editable={ editable }
                            onUpdated={text => {
                              data.title = text
                              this._update(data)
                            }}
                          />
                        }
                        {showSubtitle &&
                          <P
                            text={ data.subtitle }
                            color={ data.subtitle_color }
                            editable={ editable }
                            onUpdated={text => {
                              data.subtitle = text
                              this._update(data)
                            }}
                          />
                        }
                          <Button
                            data={ data.primary_button }
                            editable={ editable }
                            onUpdated={button => {
                              data.primary_button = button
                              this._update(data)
                            }}
                          />
                      </div>
                  </div>
                  <div className="col-md-6 col-lg-5">
                    <Img
                      className="block m-b-0"
                      img={ data.foreground_image }
                      block={36}
                      priority={this.props.index}
                      alt={ "" }
                      editable={ editable }
                      onUpdated={image => {
                        data.foreground_image = image
                        this._update(data)
                      }}
                    />
                  </div>
              </div>
          </div>
      </Section>
    )
  }
}
