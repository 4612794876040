import React from 'react'

import Collapsible from 'react-collapsible';
import Border from '../Border'

import H5 from './editable/H5'
import Img from './editable/Img'
import P from './editable/P'
import Span from './editable/Span'
import BlockQuote from './editable/BlockQuote'
import Section from './editable/Section'

import TextInfoInputItem from '../sectioninputs/TextInfoInputItem'

import General from '../../utils/General'
import Backend from '../../utils/Backend'

import Titles from './editable/Titles';

export default class Block49 extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      key: 0,
      data: props.data,
      editable: props.editable,
      uuid: "cb_"+General.uuid()
    }

    this.section = React.createRef();

    this.updateContentBlock = General.debounce(() => {
      console.log("3333", this.state.data)
      Backend.updateContentBlock(this.state.data)

      this.setState({
        key: this.state.key + 1
      }, () => General.updateSlides())
    }, 1000, false)
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _update(data, callback){
    let {
      key
    } = this.state

    this.setState({ ...data }, () => {

      if(callback){
        callback()
      }
      this.updateContentBlock()
    })
  }

  _addTextInfo(data){
    let textInfo = {
      title: "James Murphy",
      body: "One of the best companies that we have ever worked with.",
      subtitle: "CEO (ABC Ltd)",
      settings: {}
    }

    data.text_infos.push(textInfo)
    this._update(data)
  }

  _getModalContent(data){
    return (
      <Border>
        <Collapsible
          trigger="Testimonials"
        >
          <br/>
          { this._getModalContentList(data) }
        </Collapsible>
      </Border>
    )
  }

  _getModalContentList(data){
    let textInfos = data.text_infos
    let textInfosContent = textInfos.map((textInfo, index) => {
      return (
        <TextInfoInputItem
          key={index}
          index={index}
          titleName="Customer Name"
          subtitleName="Customer Title"
          bodyName="Customer Testimonial"
          textInfo={textInfo}
          renderSubtitle={false}
          onUpdated={textInfo => {
            data.text_infos[index] = textInfo
            this._update(data)
          }}
          onDelete={() => {
            if(data.text_infos.length === 1){
              alert("There must be at least 1 testimonial")
              return
            }
            textInfos.splice(index, 1)
            data.text_infos = textInfos
            this._update({
              data,
              key: this.state.key + 1
            })
          }}
        />
      )
    })

    return (
      <Border>
          { textInfosContent }
          <div className="row">
            <div className="col-md-12">
              <a className="btn"
                onClick={() => this._addTextInfo(data)}
              >
                  <span className="btn__text">
                      Add Testimonial
                  </span>
              </a>
            </div>
          </div>
      </Border>
    )
  }


  render(){
    let { data, editable, uuid, key } = this.state
    return (
      <Section
          ref={this.section}
          data={ data }
          index={ this.props.index }
          className={ " block-49 text-center " + this.props.className }
          editable={ editable }
          onUpdated={data => this._update(data)}
          onDelete={() => this.props.onDelete()}
          primaryColorTitle="Customer Name Colour"
          secondaryColorTitle="Customer Title/Quote Colour"
          onAddContentBlockPressed={contentBlock => this.props.onAddContentBlockPressed(contentBlock)}
          renderModalContent={() => this._getModalContent(data)}
          canMoveUp={ this.props.canMoveUp }
          canMoveDown={ this.props.canMoveDown }
          onVisibilityButtonPressed={(index) => this.props.onVisibilityButtonPressed(index)}
          onMoveUpPressed={ () => this.props.onMoveUpPressed() }
          onMoveDownPressed={ () => this.props.onMoveDownPressed() }
      >
          <div class="container">

            <Titles
              data={ data }
              editable={ editable }
              onUpdated={data => this._update(data)}
              headingNumber={2}
            />

              <div class="row">
                  <div class="col-lg-8 col-md-10">
                      <div key={key} class="slider" data-paging="true">
                          <ul class="slides">
                            { data.text_infos.map((textInfo, index) => {
                                return(
                                  <li key={index}>
                                    <div class="testimonial">
                                      <BlockQuote
                                        text={'“'+textInfo.body+'”'}
                                        color={ data.settings.secondary_color }
                                        editable={ editable && false }
                                        onUpdated={text => {
                                          textInfo.body = text
                                          data.text_infos[index] = textInfo
                                          this._update(data)
                                        }}
                                      />
                                      <H5
                                        text={ textInfo.title }
                                        color={ data.settings.primary_color }
                                        editable={ editable && false }
                                        onUpdated={text => {
                                          textInfo.title = text
                                          data.text_infos[index] = textInfo
                                          this._update(data)
                                        }}
                                      />
                                      <Span
                                        text={ textInfo.subtitle }
                                        color={ data.settings.secondary_color }
                                        editable={ editable && false }
                                        onUpdated={text => {
                                          textInfo.subtitle = text
                                          data.text_infos[index] = textInfo
                                          this._update(data)
                                        }}
                                      />
                                    </div>
                                  </li>
                                )
                              })
                            }
                          </ul>
                      </div>
                  </div>
              </div>
          </div>
      </Section>
    )
  }
}
