import React from "react";
import {Link} from "react-router-dom"

import moment from "moment";

import Currency from '../../utils/Currency'

export default class PackageItem extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      pkg: props.pkg,
      data: props.data
    }
  }

  render() {
    let {
      pkg,
      data
    } = this.state

    let date = moment(pkg.created_at).format('MMMM Do YYYY')

    let submit_cta = pkg.submit_cta || "Create My Site"

    let priceLabel = Currency.format(pkg.recurring_amount).replace("€", "")
    let interval = ""

    if(pkg.payment_type === "one_time" && pkg.one_time_amount > 0){
        priceLabel = Currency.format(pkg.one_time_amount).replace("€", "")
    }
    else if(pkg.recurring_amount > 0){
      if(pkg.interval === "month"){
        interval = "/mo"
      }
      if(pkg.interval === "year"){
        interval = "/yr"
      }
    }
    return (

      <>
        
        <div class="col-lg-3 col-md-4 col-12 mt-4 pt-2">
          <div
            class="card pricing-rates business-rate shadow rounded text-center border-0 cursor-pointer"
            onClick={() => window.location = `/sign-up/${pkg.slug}`}
          >
              <div class="card-body d-flex flex-column py-5">

                  <h6 class="title fw-bold text-uppercase mb-4">{pkg.name}</h6>

                  <div class="d-flex justify-content-center mb-4">
                      <span class="h4 mb-0 mt-2">{ Currency.getSymbol(pkg.currency) }</span>
                      <span class="price h1 mb-0">{ priceLabel }</span>
                      <span class="h4 align-self-end mb-1">{ interval }</span>
                  </div>

                  <ul class="list-unstyled text-left mb-0 ps-0">
                      { pkg.benefits.map(benefit => {
                          return (
                            <li class="mb-0 p">
                                {/* begin::Svg */}
                                <svg
                                    xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" width="16" height="16" viewBox="0 0 24 24">
                                    <path d="M22,10.1c0.1-0.5-0.3-1.1-0.8-1.1l-5.7-0.8L12.9,3c-0.1-0.2-0.2-0.3-0.4-0.4C12,2.3,11.4,2.5,11.1,3L8.6,8.2L2.9,9C2.6,9,2.4,9.1,2.3,9.3c-0.4,0.4-0.4,1,0,1.4l4.1,4l-1,5.7c0,0.2,0,0.4,0.1,0.6c0.3,0.5,0.9,0.7,1.4,0.4l5.1-2.7l5.1,2.7c0.1,0.1,0.3,0.1,0.5,0.1v0c0.1,0,0.1,0,0.2,0c0.5-0.1,0.9-0.6,0.8-1.2l-1-5.7l4.1-4C21.9,10.5,22,10.3,22,10.1z"/>
                                </svg>
                                {/* end::Svg */}
                               { benefit.title }
                            </li>
                          )
                        })
                      }
                  </ul>

                  <div className="mt-auto">
                    <a href="javascript:void(0)" class="btn type--uppercase mt-4 btn-block">
                      <span class="btn__text">{ submit_cta }</span>
                    </a>
                  </div>

              </div>

          </div>
        </div>

        <style dangerouslySetInnerHTML={{__html: `
          
          .block-51 .business-rate {
            background-color: ${data.settings.senary_color};
          }            
          .block-51 .business-rate .h4,
          .block-51 .business-rate .h1{
            color: ${data.settings.secondary_color};
          }
          .block-51 .business-rate h6,
          .block-51 .business-rate ul li{
            color: ${data.settings.quinary_color};
          }
          .block-51 .business-rate ul li svg{
            fill: ${data.settings.quinary_color};
          }
          .block-51 .business-rate .btn{
            background-color: ${data.settings.quinary_color} !important;
            border-color: ${data.settings.quinary_color} !important;
          }
          .block-51 .business-rate .btn .btn__text{
            color: ${data.settings.senary_color} !important;
          }
          
        `}}/>

      </>
    )
  }
}
