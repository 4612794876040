import React from 'react'

import Collapsible from 'react-collapsible';

import ImageUploader from '../blocks/editable/ImageUploader'


export default class ImageInfoInputItem extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      imageInfo: props.imageInfo,
      index: props.index,
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  render(){
    let {
      imageInfo,
      index,
    } = this.state
    return (
      <Collapsible
        trigger={ imageInfo.title }
        className="mb--1"
        triggerSibling={() => {
          return (
            <a
              className="delete-item delete-item-collapsible"
              style={{ right: 50 }}
              onClick={() => {
                this.props.onDelete(index)
              }}
            />
          )
        }}
      >
        <div className="boxed boxed--sm">
          
          <ImageUploader
            title={false}
            src={imageInfo.image.thumbnail}
            closeOnUploaded={false}
            onUploaded={image => {
              imageInfo.image = image
              this.setState({ imageInfo })
              this.props.onUpdated(imageInfo)
            }}
          />

          <br/>

          <span>
            Title
          </span>
          <input
              type="text"
              defaultValue={ imageInfo.title }
              onChange={event => {
                let title = event.target.value
                imageInfo.title = title
                this.setState({ imageInfo })
                this.props.onTextUpdated(imageInfo)
              }}
          />

          <br/><br/>

          <span>
            Subtitle
          </span>
          <input
              type="text"
              defaultValue={ imageInfo.subtitle }
              onChange={event => {
                let subtitle = event.target.value
                imageInfo.subtitle = subtitle
                this.setState({ imageInfo })
                this.props.onTextUpdated(imageInfo)
              }}
          />
        </div>
      </Collapsible>
    )
  }
}
