import React from "react";

import $ from "jquery";

import AddContentBlock from "./AddContentBlock";

import PortalModal from "./modal/PortalModal";

import General from "../utils/General";

export default class AddContentBlockButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: General.uuid(),
      showModal: false,
    };
  }

  _onAddContentBlockPressed() {
    var el = $("#" + this.state.id);
    var elOffset = el.offset().top;
    var elHeight = el.height();
    var windowHeight = $(window).height();
    var offset;

    if (elHeight < windowHeight) {
      offset = elOffset - (windowHeight / 2 - elHeight / 2);
    } else {
      offset = elOffset;
    }
    var speed = (offset % $(window).height()) / 2;
    $("html, body").animate(
      {
        scrollTop: offset,
      },
      speed,
      () => {
        this.setState({ showModal: true });
      }
    );
  }

  _renderModal() {
    return (
      <PortalModal
        show={this.state.showModal}
        onHide={() => this.setState({ showModal: false })}
        contentSuperContainerClassName={"add-content-block"}
      >
        <AddContentBlock
          onSelected={(contentBlock) => {
            this.props.onAddContentBlockPressed(contentBlock);
            this.setState({ showModal: false });
          }}
        />
      </PortalModal>
    );
  }

  _renderSection() {
    return (
      <section>
        <div className="text-center">
          <a
            id={this.state.id}
            href="javascript:void(0)"
            class="btn btn--primary"
            style={{ color: "white" }}
            onClick={() => this._onAddContentBlockPressed()}
          >
            Add Your First Block
          </a>
        </div>
        {this._renderModal()}
      </section>
    );
  }

  render() {
    if (this.props.asSection) {
      return this._renderSection();
    }

    let { index } = this.props;

    let className = index === 0 ? "first-section " : "";
    return (
      <>

        <div class={`add-content-block-button ${className}`}>

          <div className="c-tool-tips three">
            {General.renderTourButtonTitle("top", "Add Block")}
          </div>

          <a
            id={this.state.id}
            href="javascript:void(0)"
            class="button"
            onClick={() => this._onAddContentBlockPressed()}
          >
            <span class="default material-icons-outlined m-icon-s1 c-white-opa-8">
              add
            </span>
            <span className="hover">Add New Block</span>
          </a>

        </div>

        {this._renderModal()}

      </>
    );
  }
}

AddContentBlockButton.defaultProps = {
  asSection: false,
};
