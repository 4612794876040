import React from 'react'

import Button from '../Button'

import H1 from './editable/H1'
import Section from './editable/Section'

import General from '../../utils/General'
import Backend from '../../utils/Backend'

export default class Block6 extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      data: props.data,
      editable: props.editable
    }

    this.updateContentBlock = General.debounce(() => {
      console.log("3333", this.state.data)
      Backend.updateContentBlock(this.state.data)
    }, 1000, false)
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _update(data, callback){
    this.setState({ data }, () => {

      if(callback){
        callback()
      }
      this.updateContentBlock()
    })
  }

  render(){
    let { data, editable } = this.state
    return (
      <Section
        data={ data }
        index={ this.props.index }
        className={ "cover height-100 block-06 text-center event-banner "+this.props.className }
        editable={ editable }
        onUpdated={data => this._update(data)}
        onDelete={() => this.props.onDelete()}
        secondaryColor={false}
        primaryButtonToggle={true}
        onAddContentBlockPressed={contentBlock => this.props.onAddContentBlockPressed(contentBlock)}
        canMoveUp={ this.props.canMoveUp }
        canMoveDown={ this.props.canMoveDown }
        onVisibilityButtonPressed={(index) => this.props.onVisibilityButtonPressed(index)}
        onMoveUpPressed={ () => this.props.onMoveUpPressed() }
        onMoveDownPressed={ () => this.props.onMoveDownPressed() }
      >
          <div className="container section_06 pos-vertical-center">
              <div className="row">
                  <div className="col-md-6 col-lg-6">
                      <H1
                        text={ data.subtitle }
                        color={ data.settings.primary_color }
                        editable={ editable }
                        onUpdated={text => {
                          data.subtitle = text
                          this._update(data)
                        }}
                      />

                      <Button
                        data={data.primary_button}
                        editable={ editable }
                        onUpdated={button => {
                          data.primary_button = button
                          this._update(data)
                        }}
                      />

                      <div className="inside-btn-inline">
                        <Button
                          data={data.video_button}
                          type="video"
                          editable={ editable }
                          onUpdated={button => {
                            data.video_button = button
                            this._update(data)
                          }}
                        />
                      </div>

                  </div>
              </div>
          </div>
      </Section>
    )
  }
}
