import React from 'react'

import { Portal } from 'react-portal';

import General from '../../utils/General'

export default class PortalModal extends React.Component {

  

  render() {
    if(!this.props.show){
      return null
    }

    return (
      <Portal node={document && document.getElementById(this.props.id)}>
        { this.props.showAsSideMenu=="false" && General._renderDisableBodyScrollStyle() }
        <div
          id={`${this.props.showAsSideMenu=="false" ? "" : "modal-side-menu"}`}
          className={`${this.props.modalClasses} ${this.props.showAsSideMenu=="false" ? "modal-container modal-active modal-regular" : "modal-container modal-active modal-side-menu"}`}
          >
            <div
              className="modal-overlay"
              onClick={e => {
                let className = e.target.className
                if(className !== "modal-overlay"){
                  return
                } else if(className == "modal-overlay"){
                  var d = document.getElementById("modal-side-menu");
                  if(d){
                    d.className += " side-menu-close";
                  }
                }
                setTimeout(() => this.props.onHide(), 400)              
              }}
              >
                <div className={` modal-content  ${this.props.loadSideMenuFromLeft=="true" ? "from-left" : ""} ${this.props.showAsSideMenu=="false" ? "" : " modal-content-in-side-menu"} ${this.props.contentSuperContainerClassName} `}>
                    <div className={ this.props.contentContainerClassName }>
                        { this.props.showAsSideMenu!="false" &&
                          <a 
                            href="javascript:void(0)"
                            class="close"
                            onClick={e => {
                              var d = document.getElementById("modal-side-menu");
                              if(d){
                                d.className += " side-menu-close";
                              }
                              setTimeout(() => this.props.onHide(), 400)              
                            }}
                          />
                        }
                        { this.props.children }
                    </div>
                </div>
            </div>
        </div>
      </Portal>
    )
  }
}

PortalModal.defaultProps = {
  show: false,
  onHide: () => {},
  id: 'portal-modal',
  contentSuperContainerClassName: '',
  contentContainerClassName: "boxed boxed--lg m-t-30 sm-settings"
}
