import React, { Component } from "react";

const styles = {
  position: "relative",
};

class VideoThumbnail extends Component {
  render() {
    const { photo, margin } = this.props;

    return (
      <div
        style={{ margin, height: photo.height, width: photo.width, ...styles }}
      >
        <img className="full-screen" src={photo.src} alt="Photo" onClick={() => this.props.onClick()} />
        {photo.videoUrl && (
          <div className="top-right">
            <span>
              <img src={photo.icon} alt="Video" width="60%" />
            </span>
          </div>
        )}
      </div>
    );
  }
}

export default VideoThumbnail;
