import React from "react";

import YouTube from "react-youtube";

import General from "../../utils/General";
import PortalModal from "./PortalModal";

export default class VideoModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      videoUrl: props.videoUrl,
      isLoading: false,
      showModal: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  render() {
    const { videoUrl, showModal } = this.state;
    
    const videoId = General.getYoutubeVideoId(videoUrl);

    return (
      <PortalModal
        show={showModal}
        showAsSideMenu="false"
        contentSuperContainerClassName={`bg-dark yt-video-lg`}
        contentContainerClassName=""
        onHide={() => this.props.onHide()}
      >
        <YouTube
          videoId={videoId}
          className="yt-video"
          containerClassName="yt-video-container"
          opts={{
            height: "100%",
            width: "100%",
            playerVars: {
              autoplay: 1,
            },
          }}
        />
      </PortalModal>
    );
  }
}
