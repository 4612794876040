import React from 'react'

import moment from "moment"

import TextField from '@material-ui/core/TextField';

import BookingItem from "../BookingItem"

import AsyncSelect from '../../common/AsyncSelect'

import BookingModal from '../../modal/BookingModal'

import Currency from '../../../utils/Currency'

export default class Booking extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      data: props.data,
      index: props.index,
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  _renderBooking(){
    let { data } = this.state

    let booking = null
    if(data.answer?.booking){
      booking = data.answer.booking
    }

    if(!booking){
      let errorMessage = "Please select a booking date"
      return (
        <a onClick={(e) => {
          e.preventDefault()
          this.setState({ showBookingModal: true })
        }}>
          <input
            class="form-control booking-input"
            type="number"
            placeholder={data.title}
            disabled={false}
            style={{
              height: 50,
              borderColor: data.error ? "#fa6160": "#0000003b"
            }}
          />
          {data.error &&
            <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">
              {errorMessage}
            </p>
          }
        </a>
      )
    }

    let datetime = moment(booking.starts_at).format("dddd, MMMM Do YYYY")
    let subtitle = `${booking.service.name} - ${datetime}`
    return (
      <BookingItem
        booking={booking}
        subtitle={subtitle}
        onSelected={() => this.setState({ showBookingModal: true })}
      />
    )
  }

  render() {
    let { data, index, showBookingModal } = this.state

    return (
      <>
        <div className='fields price'>
          <div class="input-group">
            { this._renderBooking() }
          </div>
        </div>
        <BookingModal
          field={data}
          show={showBookingModal}
          onHide={() => this.setState({ showBookingModal: false })}
          onConfirmed={booking => {
            let answer = { field: data.id, booking }
            data.answer = answer
            this.props.onUpdated(index, answer)
            this.setState({ showBookingModal: false })
          }}
        />
      </>
    )
  }
}
