import React from 'react'

import moment from "moment"

import Currency from '../../utils/Currency'

export default class BookingItem extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      booking: props.booking
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  render() {
    let { booking } = this.state

    let priceLabel = "Free"
    if(booking.price){
      priceLabel = Currency.format(
        booking.price,
        window.CURRENT_WEBSITE_SHOP_CURRENCY || ""
      )
    }

    let startsAtLabel = moment(booking.starts_at).format("HH:mm")
    let endsAtLabel = moment(booking.ends_at).format("HH:mm")

    let duration = `${startsAtLabel} - ${endsAtLabel}`

    let title = this.props.title || `${booking.resource.name} - ${priceLabel}`
    let subtitle = this.props.subtitle || booking.resource.description
    let label = this.props.label || duration

    return (
      <a className="row m-0 booking-item"
        onClick={() => {
          this.props.onSelected(booking)
        }}
      >
        { booking.resource.image &&
          <div className="col-1 p-0 text-center justify-content-center">
            <img src={booking.resource.image.thumbnail}/>
          </div>
        }

        <div className="col">
          <div className="title text-bold">
            {title}
          </div>
          <div className="description">
            {subtitle}
          </div>
        </div>

        <div className="col-2 p-0 text-right justify-content-center">
          {label}
        </div>
      </a>
    )
  }
}
