import React from 'react'


import AuthManager from '../utils/AuthManager'

export default class AddArticle extends React.Component {
  render() {
    if(!AuthManager.currentUser){
      return null
    }
    return (
      <div className="row">
          <div className="col-md-4 mx-auto">
              <div className="alert-addproduct feature feature-3 boxed boxed--lg boxed--border unmarg--bottom">

                <div className="icon-div text-center">
                  <span className="position-relative inline-block">
                    <i className="icon icon-Danger icon--lg color--white"></i>
                  </span>
                </div>
                <h5 className="my-5 text-center color--white">YOU DO NOT HAVE ANY ARTICLES TO DISPLAY</h5>
                <a
                  href={"//dashboard."+window.General.WebsiteNakedDomain+"/articles"}
                  target="_blank"
                  className="btn btn--white w-100"
                >
                  ADD ARTICLES
                </a>
              </div>
          </div>
        </div>
    )
  }
}
