import React from 'react'

import PortalModal from "./PortalModal"

import General from '../../utils/General'
import FetchHelper from '../../utils/FetchHelper'
import Notify from '../../utils/Notify'
import Event from '../../utils/Event'
import Backend from '../../utils/Backend'

export default class SubscriptionModal extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      show: false,
      loading: false,
      website: props.website,
      editable: props.editable,
    }
  }

  componentDidMount(){
    Event.on('SHOW_SUBSCRIPTION_MODAL', () => this.setState({
      show: true
    }));
  }

  componentWillUnmount(){
    Event.off('SHOW_SUBSCRIPTION_MODAL');
  }

  _onPayClicked(){
    let url = window.General.ClientDashboardUrl + "/add-ons?subscribe=true"

    this.setState({ loading: true })

    let editWebsiteTab = window.open();
    return Backend.createLoginHash()
    .then(hash => {
      editWebsiteTab.location.href = url+"&h="+hash.value
      this.setState({ loading: false })
    })
    .catch(error => {
      window.location = url
      this.setState({ loading: false })
    })
  }

  _getPopup(){
    let { website, editable } = this.state
    let subscription = website.subscription
    if(!subscription || !subscription.payment_flow){
      return false
    }
    let paymentFlow = subscription.payment_flow
    let title = ""
    let subtitle = ""
    let cta = ""
    if(editable){
      title = paymentFlow.owner_title || "Your Trial Has Expired"
      subtitle = paymentFlow.owner_subtitle || "Please activate your website to avoid your website from being automatically deleted."
      cta = paymentFlow.owner_cta || "Activate Website"
    }
    else{
      title = paymentFlow.visitor_title || "Website Deactivated"
      subtitle = paymentFlow.visitor_subtitle || "This website has been deactivated due to failed payments. Please contact the site owner to resolve."
      cta = null
    }
    return {
      cta,
      title,
      subtitle
    }
  }

  render() {
    let {
      show,
      website,
      loading,
      editable
    } = this.state

    const popup = this._getPopup()
    return (
      <PortalModal
        show={show}
        showAsSideMenu="false"
        onHide={() => {}}
      >
        <h3>{popup.title}</h3>

        <p>{popup.subtitle}</p>

        { editable &&
          <div className="c-btn-group text-center mt-5">
            <a
              className="btn btn--primary w-100"
              style={{
                color: "white",
                borderColor: "#222",
                backgroundColor: "#222"
              }}
              onClick={() => this._onPayClicked()}
              disabled={loading}
            >
              {popup.cta}
            </a>
          </div>
        }
      </PortalModal>
    )
  }
}
