import React from "react";

import General from "../../../utils/General";

import TextInput from "./TextInput";

export default class BlockQuote extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      text: props.text,
      color: props.color,
      textAlign: props.textAlign,
      className: props.className || "",
      editable: props.editable,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  _renderEditable(text, color, textAlign, className) {
    return (
      <TextInput
        className={"blockquote " + className}
        value={text}
        color={color}
        textAlign={textAlign}
        onUpdated={(text) => this.props.onUpdated(text)}
      />
    );
  }

  render() {
    let { text, color, textAlign, className, editable } = this.state;

    if (editable) {
      return this._renderEditable(text, color, textAlign, className);
    }
    return (
      <blockquote
        style={{ color }}
        className={className + " pre "}
        dangerouslySetInnerHTML={General.sanitizeData(text)}
      />
    );
  }
}
