import React from 'react'

import Label from './fields/Label'
import Text from './fields/Text'
import DateTime from './fields/DateTime'
import TextArea from './fields/TextArea'
import Checkbox from './fields/Checkbox'
import SingleSelect from './fields/SingleSelect'
import MultiSelect from './fields/MultiSelect'
import File from './fields/File'
import Image from './fields/Image'
import Price from './fields/Price'
import Booking from './fields/Booking'

export default class Field extends React.Component {
  fields = {
    label: Label,
    text: Text,
    datetime: DateTime,
    textarea: TextArea,
    boolean: Checkbox,
    single_select: SingleSelect,
    multi_select: MultiSelect,
    file: File,
    image: Image,
    price: Price,
    booking: Booking,
  }

  constructor(props) {
    super(props)

    this.state = {
      data: props.data,
      weight: props.weight,
      startIndex: props.startIndex,
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps)
  }

  _getTag(type){
    if(this._isText(type)){
      return Text
    }

    if(this._isDateOrTime(type)){
      return DateTime
    }

    return this.fields[type]
  }

  _isText(type){
    return type === "text" || type === "email" || type === "phone" || type === "number" ||
      type === "contact_first_name" || type === "contact_last_name" || type === "contact_email" ||
      type === "contact_phone"
  }

  _isDateOrTime(type){
    return type === "date" || type === "time" || type === "datetime"
  }

  render(){
    let {
      data,
      weight,
      startIndex,
    } = this.state

    let type = data.type
    if(type == "booking" && this.props.hideBookingField){
      return null
    }

    let Tag = this._getTag(type)
    return (
      <div
        className={`col form-field ${type}`}
      >
        <Tag
          data={ data }
          weight={ weight }
          index={ startIndex }
          onUpdated={(index, value) => {
            this.props.onUpdated(index, value)
          }}
        />
      </div>
    )
  }
};
