import React from 'react'

import General from '../../../utils/General'

export default class SectionFormValues extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      data: props.data,
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _renderId(object, key){
    if(!object || !object.id){
      return null
    }

    return this._renderInputValue(key, object.id)
  }

  _renderInputValue(key, value){
    let index = this.props.index

    let name = "[" + key.replace(/\./g, "][") + "]"
    name = "content_blocks["+index+"]"+name
    return (
      <input
        key={ name }
        type="text"
        name={ name }
        value={ value }
        hidden
        readOnly
      />
    )
  }

  _renderButton(button, key, value){
    if(!button){
      return null
    }
    // <> = Fragment shorthand for not rendering a wrapping tag!
    return (
      <>
        { this._renderId(button, key+".id")}
        { this._renderInputValue(key+".title", button.title) }
        { this._renderInputValue(key+".url", button.url) }
        { this._renderInputValue(key+".badge", button.badge) }
        { this._renderSettings(button, key) }
      </>
    )
  }

  _renderSettings(object, key){
    if(!object || !object.settings){
      return null
    }

    if(key == ""){
      key = "settings."
    }
    else{
      key += ".settings."
    }

    let settings = object.settings

    return (
      <>
      { this._renderId(settings, key+"id") }

      { this._renderInputValue(key+"primary_color", settings.primary_color) }
      { this._renderInputValue(key+"secondary_color", settings.secondary_color) }
      { this._renderInputValue(key+"tertiary_color", settings.tertiary_color) }

      { this._renderInputValue(key+"overlay", settings.overlay) }

      { this._renderInputValue(key+"margin_top", settings.margin_top) }
      { this._renderInputValue(key+"margin_right", settings.margin_right) }
      { this._renderInputValue(key+"margin_bottom", settings.margin_bottom) }
      { this._renderInputValue(key+"margin_left", settings.margin_left) }

      { this._renderInputValue(key+"padding_top", settings.padding_top) }
      { this._renderInputValue(key+"padding_right", settings.padding_right) }
      { this._renderInputValue(key+"padding_bottom", settings.padding_bottom) }
      { this._renderInputValue(key+"padding_left", settings.padding_left) }
      </>
    )
  }

  _renderImages(images){

    if(!images || images.length == 0){
      return this._renderInputValue("images", null)
    }

    return images.map((image, index) => {
      return this._renderInputValue("images."+index, image.id)
    })
  }

  _renderTextInfos(textInfos){

    if(!textInfos || textInfos.length == 0){
      return this._renderInputValue("text_infos", null)
    }

    return textInfos.map((image, index) => {
      return this._renderInputValue("images."+index, image.id)
    })
  }

  _renderImageInfos(imageInfos){

    if(!imageInfos || imageInfos.length == 0){
      return this._renderInputValue("image_infos", null)
    }

    return imageInfos.map((imageInfo, index) => {
      return this._renderImageInfo(imageInfo, index)
    })
  }

  _renderImageInfo(imageInfo, index){
    let key = "image_infos."+index+"."
    return (
      <>
        { this._renderInputValue(key+"id", imageInfo.id) }
        { this._renderInputValue(key+"image.id", imageInfo.image.id) }
        { this._renderInputValue(key+"title", imageInfo.title) }
        { this._renderInputValue(key+"subtitle", imageInfo.subtitle) }
        { this._renderInputValue(key+"body", imageInfo.body) }

        { this._renderButton(imageInfo.button, key+"button") }
        { this._renderSettings(imageInfo, key) }
      </>
    )
  }

  render(){
    let {
      data,
    } = this.state

    let index = this.props.index

    let {
      settings
    } = data

    return (
      <div className="content_block_values">
        { this._renderId(data, "id") }
        { this._renderId(data.foreground_image, "foreground_image.id") }
        { this._renderId(data.background_image, "background_image.id") }
        { this._renderId(data.form, "form.id") }
        { this._renderId(data.block, "block_id") }

        { this._renderInputValue("anchor", data.anchor) }
        { this._renderInputValue("background_gradient", data.background_gradient) }
        { this._renderInputValue("countdown_timer", data.countdown_timer) }

        { this._renderInputValue("title", data.title) }
        { this._renderInputValue("subtitle", data.subtitle) }
        { this._renderInputValue("body", data.body) }

        { this._renderButton(data.primary_button, "primary_button") }
        { this._renderButton(data.secondary_button, "secondary_button") }
        { this._renderButton(data.video_button, "video_button") }

        { this._renderSettings(data, "") }

        { this._renderImages(data.images) }
        { this._renderTextInfos(data.text_infos) }
        { this._renderImageInfos(data.image_infos) }
      </div>
    )
  }

}
