import React from "react";

import Collapsible from "react-collapsible";

import TextareaAutosize from "react-autosize-textarea";

export default class Toggle extends React.Component {
  constructor(props) {
    super(props);
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  render() {
    let { id, checked, title, onChange } = this.props;

    return (
      <div class="row">
        <div class="col-md-9">
          <h4>{title}</h4>
        </div>
        <div class="col-md-3 text-right">
          <div class="input-checkbox input-checkbox--switch">
            <input
              id={id}
              type="checkbox"
              checked={checked}
              onChange={(e) => onChange(e.target.checked)}
            />
            <label for={id}></label>
          </div>
        </div>
      </div>
    );
  }
}

Toggle.defaultProps = {};
