import React from "react";
import ContentBlockLoader from "./ContentBlockLoader";
import MenuLoader from "./MenuLoader";

export default class WebsiteloadingPlaceholder extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      shopMode: props.shopMode,
      blogMode: props.blogMode,
      packageMode: props.packageMode
    }
  }

  _renderProductLoading(){
    return (
        <div class="main-container">
          <div class="container">

            <div class="row">

              {/* starts, column right, sticky sidebar  */}
              <div class="col-md-8">

                <div class="w-100 shimmer" style={{ height: 300}}/>

                <div class="w-100" style={{ height: 30}}/>

                <div class="w-100 shimmer" style={{ height: 60}}/>

                <div class="w-100" style={{ height: 30}}/>

                <div class="w-100 shimmer" style={{ height: 200}}/>

              </div>

              <div class="col-md-4">

                <div class="w-100 shimmer" style={{ height: 300 }}/>

              </div>

            </div>

          </div>

        </div>
    )
  }

  _renderCheckoutLoading(){
    return (
        <div class="main-container">
          <div class="container">

            <div class="row">

              {/* starts, column right, sticky sidebar  */}
              <div class="col-md-6">

                <div class="w-100 shimmer" style={{ height: 370}}/>

              </div>

              <div class="col-md-6">

                <div class="row shimmer" style={{ height: 50}}/>

                <div class="row w-100" style={{ height: 20}}/>

                <div class="row shimmer" style={{ height: 50}}/>

                <div class="row w-100" style={{ height: 40}}/>

                <div class="row shimmer" style={{ height: 50}}/>

                <div class="row w-100" style={{ height: 40}}/>

                <div class="row shimmer" style={{ height: 50}}/>

                <div class="row w-100" style={{ height: 20}}/>

                <div class="row shimmer" style={{ height: 50}}/>

              </div>

            </div>

          </div>

        </div>
    )
  }

  _renderPackageLoading(){
    return (
        <div className="metr_v702 d-flex flex-column flex-root vh-100">
          <div className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid">

            {/* begin : Login Aside */}
            <div className="login-aside d-flex flex-column flex-row-auto shimmer" style={{zIndex:'-1'}}>
              &nbsp;
            </div>
            {/* end : Login Aside */}

            {/* <!--begin::Content--> */}
            <div className="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 pt-38 pb-38 mx-auto  w-100 h-100 shimmer">
              &nbsp;
            </div>
            {/* end::Content */}

          </div>
        </div>
    )
  }

  _renderArticleLoading(){
    return (
        <>
          <div class="w-100 shimmer" style={{ height: 280, marginTop: -50 }}/>

          <div class="main-container">
            <div class="container">

              <div class="row">

                {/* starts, column right, sticky sidebar  */}
                <div class="col-md-12">

                  <div class="w-100" style={{ height: 30}}/>

                  <div class="w-100 shimmer" style={{ height: 400}}/>

                  <div class="w-100" style={{ height: 30}}/>

                </div>

              </div>

            </div>

          </div>
        </>
    )
  }

  render() {
    let {

    } = this.state

    return null

    return (
        <>
          <MenuLoader/>
          <ContentBlockLoader/>
        </>
    )
  }
}
