import React from 'react'

import Border from '../Border'

import H5 from './editable/H5'
import Img from './editable/Img'
import P from './editable/P'
import Span from './editable/Span'
import BlockQuote from './editable/BlockQuote'
import Section from './editable/Section'

import TextInfoInputItem from '../sectioninputs/TextInfoInputItem'

import General from '../../utils/General'
import Backend from '../../utils/Backend'

export default class Block60 extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      key: 0,
      data: props.data,
      editable: props.editable,
      uuid: "cb_"+General.uuid()
    }

    this.section = React.createRef();

    this.updateContentBlock = General.debounce(() => {
      console.log("3333", this.state.data)
      Backend.updateContentBlock(this.state.data)

      this.setState({
        key: this.state.key + 1
      }, () => General.updateSlides())
    }, 1000, false)
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _update(data, callback){
    let {
      key
    } = this.state

    this.setState({ ...data }, () => {

      if(callback){
        callback()
      }
      this.updateContentBlock()
    })
  }


  render(){
    let { data, editable, uuid, key } = this.state
    return (
      <Section
          ref={this.section}
          data={ data }
          index={ this.props.index }
          className={ " block-60 text-center " + this.props.className }
          editable={ editable }
          onUpdated={data => this._update(data)}
          onDelete={() => this.props.onDelete()}
          onAddContentBlockPressed={contentBlock => this.props.onAddContentBlockPressed(contentBlock)}
          canMoveUp={ this.props.canMoveUp }
          canMoveDown={ this.props.canMoveDown }
          onVisibilityButtonPressed={(index) => this.props.onVisibilityButtonPressed(index)}
          onMoveUpPressed={ () => this.props.onMoveUpPressed() }
          onMoveDownPressed={ () => this.props.onMoveDownPressed() }
      >
          <div class="container">
            Block 60
          </div>
      </Section>
    )
  }
}
