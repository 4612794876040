import React from 'react'

import SVG, { Props as SVGProps } from 'react-inlinesvg';

import Dropzone from './Dropzone'
import Image from './Image'

import ImagePickerModal from '../../modal/ImagePickerModal'

import General from '../../../utils/General'

import Backend from '../../../utils/Backend'

export default class ImageUploader extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      isLoading: false,
      title: props.title,
      type: props.type,
      uuid: General.uuid(),
      pickerType: props.pickerType
    }

    this.dropzone = React.createRef()
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _onUploaded(image){
    this.setState({ isLoading: false })
    this.props.onUploaded(image)
  }

  _renderDropzone(isLoading){
    if(isLoading){
      return <p>Loading</p>
    }
    return (
      <Dropzone
        ref={this.dropzone}
        type="photo"
        onUploaded={image => this._onUploaded(image)}/>
    )
  }

  _renderTitle(title){
    if(!title){
      return null
    }

    let TitleTag = this.props.titleTag

    return <TitleTag>{ title }</TitleTag>
  }

  render(){
    let {
      type,
      title,
      isLoading,
      pickerType,
      showImagePicker
    } = this.state

    let buttonTitle = "Choose From XYZ Image Gallery"
    if(pickerType == "illustration" || type === "illustration"){
      buttonTitle = "Choose From XYZ Illustration Gallery"
    }

    return (
      <div>
        { this._renderTitle(title) }
        <div className="render-preview-image">

          <div className="row image-preview v2">
            <div className="col-md-3 image d-flex align-items-center justify-content-center">
              <Image
                className="image-uploaded"
                src={this.props.src}
                type={this.props.type}
                primaryColor={this.props.primaryColor}
              />
            </div>
            <div className="col-md-9 image-uploader">
              { this._renderDropzone() }
            </div>
          </div>

        </div>
        <div className="text-center w-100">
          <div className="d-flex my-3 w-50 mx-auto">
            <hr className="my-auto flex-grow-1" style={styles.hr}/>
            <div className="px-4" style={styles.separatorText}>OR</div>
            <hr className="my-auto flex-grow-1" style={styles.hr}/>
          </div>
          <a
            className="btn btn--primary w-100"
            style={{
              backgroundColor: "#222",
              borderColor: "#222"
            }}
            onClick={() => this.setState({ showImagePicker: true })}
          >
            <span className="btn__text">{ buttonTitle }</span>
          </a>
          <ImagePickerModal
            show={showImagePicker}
            type={pickerType || type}
            primaryColor={this.props.primaryColor}
            onSelected={image =>  {
               this.dropzone.current.addImage(image)
               this.setState({ showImagePicker: false })
            }}
            onHide={() => this.setState({ showImagePicker: false })}
          />
        </div>
      </div>
    )
  }
}

const styles = {
  hr: {
    borderWidth: 1,
    borderColor: '#222'
  },
  separatorText: {
    color: '#222'
  }
}

ImageUploader.defaultProps = {
  type: "photo",
  title: "Image",
  titleTag: "h3",
  primaryColor: "#6c63ff",
  closeOnUploaded: true
}
