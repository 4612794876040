import React from "react";
import Collapsible from "react-collapsible";

import H2 from "./editable/H2";
import P from "./editable/P";
import Section, { DEFAULT_PRIMARY_BUTTON } from "./editable/Section";

import TextInfos from "../TextInfos";

import Border from "../Border";

import General from "../../utils/General";
import Backend from "../../utils/Backend";

export default class Block9 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
      editable: props.editable,
    };

    this.updateContentBlock = General.debounce(
      () => {
        console.log("3333", this.state.data);
        Backend.updateContentBlock(this.state.data);
      },
      1000,
      false
    );
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  _update(data, callback) {
    this.setState({ data }, () => {
      if (callback) {
        callback();
      }
      this.updateContentBlock();
    });
  }

  _renderModalContent(data) {
    return (
      <Border>
        <Collapsible trigger="Layout">
          <br></br>

          <select
            value={data.variation || "sidebar_left"}
            onChange={(event) => {
              data.variation = event.target.value;

              this._update(data);
            }}
          >
            <option value="sidebar_left">Sidebar Left Text Right</option>
            <option value="sidebar_right">Text Left Sidebar Right</option>
            <option value="no_sidebar">Text Only</option>
          </select>
        </Collapsible>
      </Border>
    );
  }

  _renderSidebar() {
    let { data, editable } = this.state;

    return (
        <TextInfos
          textInfos={data.text_infos}
          color={data.settings.secondary_color}
          editable={editable}
          textAlign="left"
          onUpdated={(textInfos) => {
            data.text_infos = textInfos;
            this._update(data);
          }}
        />
    );
  }

  _renderBody() {
    let { data, editable } = this.state;
    let showTitle = data.title

    return (
      <>

        {showTitle &&
          <H2
            text={data.title}
            color={data.title_color}
            editable={editable}
            textAlign="left"
            onUpdated={(text) => {
              data.title = text;
              this._update(data);
            }}
          />
        }

        <P
          text={data.body}
          color={data.settings.secondary_color}
          editable={editable}
          textAlign="left"
          onUpdated={(text) => {
            data.body = text;
            this._update(data);
          }}
        />
      </>
    );
  }

  renderContent() {
    let { data } = this.state;

    let variation = data.variation || "sidebar_left";

    if (variation == "sidebar_right") {
      return (
        <>
          <div className="col-md-3 sidebar-right">{this._renderSidebar()}</div>
          <div className="col-md-9">{this._renderBody()}</div>
        </>
      );
    } else if (variation == "sidebar_left") {
      return (
        <>
          <div className="col-md-9">{this._renderBody()}</div>
          <div className="col-md-3 sidebar-left">{this._renderSidebar()}</div>
        </>
      );
    } else {
      return (
        <>
          <div className="col no-sidebar">{this._renderBody()}</div>
        </>
      );
    }
  }

  render() {
    let { data, editable } = this.state;

    if (data.settings.msc_enabled) {
      data.settings.tertiary_color = window.CURRENT_PAGE_PRIMARY_COLOR;
    }

    return (
      <Section
        data={data}
        index={this.props.index}
        className={" block-09 switchable bg--primary " + this.props.className}
        editable={editable}
        renderModalContent={() => this._renderModalContent(data)}
        primaryColor={false}
        onUpdated={(data) => {
          data.settings.msc_enabled = false;
          this._update(data);
        }}
        onDelete={() => this.props.onDelete()}
        onAddContentBlockPressed={(contentBlock) =>
          this.props.onAddContentBlockPressed(contentBlock)
        }
        canMoveUp={this.props.canMoveUp}
        canMoveDown={this.props.canMoveDown}
        onVisibilityButtonPressed={(index) => this.props.onVisibilityButtonPressed(index)}
        onMoveUpPressed={() => this.props.onMoveUpPressed()}
        onMoveDownPressed={() => this.props.onMoveDownPressed()}
      >
        <div className="container">
          <div className="row justify-content-between">
            {this.renderContent()}
          </div>
        </div>
      </Section>
    );
  }
}
