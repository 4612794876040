import React from 'react'

import moment from 'moment'

import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';

import BookingItem from '../form/BookingItem'

import Select from '../common/Select';
import AsyncSelect from '../common/AsyncSelect'

import Modal from 'react-bootstrap/Modal'
import PortalModal from './PortalModal'

import Notify from "../../utils/Notify"
import Currency from "../../utils/Currency"

export default class BookingModal extends React.Component {
  constructor(props) {
    super(props)

    let field = props.field
    let services = field.services
    let service = null
    if(field.services.length == 1){
      service = field.services[0]
    }
    let serviceEditable = service == null
    this.state = {
      field,
      service,
      services,
      serviceEditable,
    }

  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps)
  }

  _getServiceOption(service){
    if(!service){
      return null
    }
    return {
      data: service,
      value: service.id,
      label: service.name,
    }
  }

  _getSlotOption(slot){
    if(!slot){
      return null
    }
    return {
      value: moment(slot.starts_at).format("HH:mm"),
      label: moment(slot.starts_at).format("HH:mm"),
      data: slot
    }
  }

  _renderTitle(title, subtitle){
    return (
      <>
        <h4 className="mb-0" style={{marginTop: 40}}>{title}</h4>
        <span className="text-muted">{subtitle}</span>
      </>
    )
  }

  _renderServicePicker(){
    let { field, service, services, serviceEditable } = this.state

    return (
      <>
        {this._renderTitle("1. Select Service", "Select the service you would like to book")}
        <div className="form-group booking-section">

        { services.length > 0 ? (
          <Select
            value={this._getServiceOption(service)}
            className="async-select-paginate"
            classNamePrefix="async-select-paginate"
            name="service"
            onChange={serviceData => {
              this.setState({
                date: null,
                opening: null,
                serviceResource: null,
                service: serviceData.data,
              })
            }}
            options={services.map(option => this._getServiceOption(option))}
            disabled={!serviceEditable}
          />
        ) : (
          <AsyncSelect
            endpoint={window.Api.Services}
            filter={`website_id=${window.CURRENT_WEBSITE.id}&field_id=${field.id}`}
            classNamePrefix="async-select-paginate"
            value={this._getServiceOption(service)}
            placeholder="Please select a Service"
            onSelected={service => {
              this.setState({
                service,
                date: null,
                opening: null,
                serviceResource: null
              })
            }}
            getOptions={options => options.map(option => this._getServiceOption(option))}
            noOptionsMessage={() => "No Services Found"}
            disabled={!serviceEditable}
          />
        )}
        </div>
      </>
    )
  }

  _renderDatePicker(){
    let { field, service, date } = this.state

    if(!service){
      return null
    }
    return (
      <>
        {this._renderTitle("2. Choose Date", "Choose your preferred date for the booking")}
        <div className="form-group booking-section">
          <DayPicker
            mode="single"
            numberOfMonths={window.innerWidth < 768 ? 1 : 2}
            selected={date}
            onSelect={date => {
              this.setState({ date, opening: null, serviceResource: null  })
            }}
          />
        </div>
      </>
    )
  }

  _renderTimePicker(){
    let { field, service, date, opening } = this.state
    if(!service || !date){
      return null
    }

    let filter = `service_id=${service.id}&date=${moment(date).format("YYYY-MM-DD")}`
    filter += `&website_id=${window.CURRENT_WEBSITE.id}&field_id=${field.id}`

    return (
      <>
        {this._renderTitle("3. Pick Time", "Pick the time of your booking")}
        <div className="form-group booking-section">
          <AsyncSelect
            key={filter}
            endpoint={window.Api.Slots}
            filter={filter}
            classNamePrefix="async-select-paginate"
            value={this._getSlotOption(opening)}
            placeholder="Please select a time"
            onSelected={opening => {
              this.setState({ opening, serviceResource: null  })
            }}
            getOptions={options => options.map(o => this._getSlotOption(o))}
          />
        </div>
      </>
    )
  }

  _renderServiceResourcePicker(){
    let { opening, service } = this.state

    if(!opening){
      return null
    }

    return (
      <>
        {this._renderTitle("4. Confirm", "Select an option below")}
        <div className="form-group booking-section">
          { opening.slots.map(booking => {
              return (
                <div className="my-2">
                  <BookingItem
                    service={service}
                    booking={booking}
                    onSelected={() => {
                      this.props.onConfirmed({
                        ...booking,
                        service,
                        usage_no: 1,
                        slot: booking.slot_id,
                        service_resource: booking.service_resource_id
                      })
                    }}
                  />
                </div>
              )
            })
          }
        </div>
      </>
    )
  }


  render() {
    let {
      show,
      error,
      url,
      title,
    } = this.state

    if(!show){
      return null
    }

    return (
      <PortalModal
        show={show}
        onHide={() => this.props.onHide()}
        showAsSideMenu="false"
      >

        <div className="modal-booking">

          <div className="row modal-body">
            <div className="col-md-12">
              { this._renderServicePicker() }

              { this._renderDatePicker() }

              { this._renderTimePicker() }

              { this._renderServiceResourcePicker() }
              <div className="text-muted text-center" style={{marginTop: 150}}>
                It is your responsibility to ensure your are available for any date/time selected. Late cancellations may incur fees / be non refundable.
              </div>
            </div>
          </div>

        </div>

      </PortalModal>
    )
  }
}
