import React from 'react'
import $ from "jquery";

import { Helmet } from "react-helmet";

import RelatedProduct from '../components/Product'

import { getImageUrl, Sizes } from '../components/blocks/editable/Img'
import ShareModal from '../components/modal/ShareModal'

import General from '../utils/General'
import ScriptCache from '../utils/ScriptCache'
import Currency from '../utils/Currency'
import Notify from '../utils/Notify'
import Cart from '../utils/Cart'
import DataParser from "../utils/DataParser";
import Play from "../components/Play";
import PortalModal from "../components/modal/PortalModal";
import YouTube from "react-youtube";

export default class DataDetail extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      loading: true,
      website: props.website,
      index: DataParser.decodeUrl(window.location.pathname.split('/')[3], 'index'),
      fileUrl: DataParser.decodeUrl(window.location.pathname.split('/')[3], 'fileUrl'),
      originUrl: DataParser.decodeUrl(window.location.pathname.split('/')[3], 'redirect'),
      anchor: DataParser.decodeUrl(window.location.pathname.split('/')[3], 'anchor'),
      windowWidth: window.innerWidth,
    }

    this.quantityInput = React.createRef()
  }

  _handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
    setTimeout(() => {
      General.updateSlides();
    }, 200);
  };

  componentDidMount() {
    this._setup();
    window.addEventListener("resize", this._handleResize);
  }

  componentWillUnmount() {
    window.addEventListener("resize", this._handleResize);
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _setup(){
    let {
      index,
      anchor,
      fileUrl,
      originUrl
    } = this.state

    fetch(fileUrl).then(response => {
      return response.arrayBuffer()
    }).then((ab) => {
      let fileData = new Uint8Array(ab)
      let [headers, movieData] = DataParser.formatData(fileData, fileUrl, originUrl, anchor)
      let dataDetail = movieData[index]
      let similarData = DataParser.getSimilar(movieData, index)
      this.setState({ dataDetail, similarData })
    })

    this.setState({
      loading: false
    }, () => this._loadJs())
  }

  _loadJs(){
    ScriptCache.loadDefaults()
    setTimeout(() => {
      General.updateAll()
    }, 500)
  }

  _renderMeta(){
    let {
      website,
      dataDetail
    } = this.state

    if(dataDetail == null){
       return null
    }

    let favIconUrl = website.favicon ? website.favicon.original : "/favicon.png"

    return (
      <Helmet>
        <title>{`${dataDetail.name} | ${website.name}`}</title>
        <meta name="description" content={ dataDetail.description || website.descrption } />
        {/*<meta name="keywords" content={ product.tags.join(",") || website.keywords } />*/}
        <link rel="icon" href={ favIconUrl }/>
        <meta property="og:title" content={ dataDetail.name }/>
        <meta property="og:image" content={ dataDetail.image }/>
        <meta property="og:description" content={ dataDetail.description }/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content={ window.location.href }/>
        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:url" content={ window.location.href }/>
        <meta property="twitter:title" content={ dataDetail.name }/>
        <meta property="twitter:description" content={ dataDetail.description }/>
        <meta property="twitter:image" content={ dataDetail.image }/>
      </Helmet>
    )
  }

  _renderVideoModal(videoUrl) {
    let videoId = General.getYoutubeVideoId(videoUrl);

    return (
      <PortalModal
        show={this.state.showVideoModal}
        showAsSideMenu="false"
        contentSuperContainerClassName={`bg-dark yt-video-md`}
        contentContainerClassName=""
        onHide={() => this.setState({ showVideoModal: false })}
      >
        <YouTube
          videoId={videoId}
          className="yt-video"
          containerClassName="yt-video-container"
          opts={{
            height: "100%",
            width: "100%",
            playerVars: {
              autoplay: 1,
            },
          }}
        />
      </PortalModal>
    );
  }

  _renderSliderData(){
    let {
      dataDetail,
      similarData
    } = this.state

    return(
      <>
        {
          similarData && similarData.map(item => {
            return (
              <>
                <li
                  className="col-6 col-md-3 col-lg-2"
                  onClick={() => {
                    if(item.link) {
                      window.open(item.link, '_self')
                    }
                  }}
                >
                  <a
                    href="javascript:void(0)"
                  >
                    <img
                      key={item.image}
                      src={item.image}
                      alt=""
                      loading="lazy"
                      className='shimmer'
                    />
                    <span>
                      {item.name}
                    </span>
                  </a>
                </li>
              </>
            )
          })
        }
      </>
    )

  }

  render(){
    let {
      anchor,
      originUrl,
      dataDetail,
      similarData,
      windowWidth
    } = this.state

    if(!dataDetail) return null

    return (
      <div class="main-container page data-detail">
        { this._renderMeta() }
        {/* starts, main product section  */}
        <section class="switchable sticky-sidebars page-product page-data-detail pt-0 pb-4 pt-md-5">
          <div class="container">
            <ol className="breadcrumbs">
              <li>
                <a href={`${originUrl}#${anchor}`}>Home</a>
              </li>
              {
                dataDetail.category &&
                <li>
                  <a href={`${originUrl}?category=${dataDetail.category}#${anchor}`}>{dataDetail.category}</a>
                </li>
              }
              <li>{dataDetail.name}</li>
            </ol>
            <div class="row mt-xl-5 justify-content-around">

              {/* starts, column right, sticky sidebar  */}
              <div class="col-md-7 sticky-sidebar-rightcol">
                <div class="sidebar__inner">
                  <h2 className='fs-md-30'>{dataDetail.name}</h2>
                  <div class="text-block d-flex align-items-center">
                    <p>{dataDetail.description}</p>
                  </div>
                  <div className="text-block align-items-center mt-3">
                    {
                      dataDetail.director &&
                      <div className="d-flex">
                        <span className="h5 m-0 pr-2">Director:</span>
                        <span className="p m-0">{dataDetail.director}</span>
                      </div>
                    }
                    {
                      dataDetail.cast &&
                      <div className="d-flex mt-2">
                        <span className="h5 m-0 pr-2">Cast:</span>
                        <span className="p m-0">{dataDetail.cast}</span>
                      </div>
                    }
                    {
                      dataDetail.genre &&
                      <div className="d-flex mt-2">
                        <span className="h5 m-0 pr-2">Genre:</span>
                        <span className="p m-0">{dataDetail.genre}</span>
                      </div>
                    }
                    {
                      dataDetail.rating &&
                      <div className="d-flex mt-2">
                        <span className="h5 m-0 pr-2">Rating:</span>
                        <span className="p m-0">{dataDetail.rating}</span>
                      </div>
                    }
                    {
                      dataDetail.duration &&
                      <div className="d-flex mt-2">
                        <span className="h5 m-0 pr-2">Duration:</span>
                        <span className="p m-0">{dataDetail.duration} mins</span>
                      </div>
                    }
                  </div>
                  {
                    dataDetail.video &&
                    <div>
                      <a
                        href="javascript:void(0);"
                        className="btn btn--primary type--uppercase btn--icon"
                        onClick={() => this.setState({showVideoModal: true})}
                      >
                      <span className="btn__text">
                        <i className="icon icon--lg icon-Play-Music"></i>
                        TRAILER
                      </span>
                      </a>
                    </div>
                  }
                </div>
              </div>
              {/* end, column right, sticky sidebar  */}

              {/* starts, column left  */}
              <div class="col-md-5">

                {/* starts, slider  */}
                <img
                  key={dataDetail.image}
                  src={dataDetail.image}
                  alt=""
                  loading="lazy"
                  className='detail-poster shimmer ml-auto mr-auto'
                />
                {/* end, slider  */}

                {/* starts, Product Description  */}
                {/* end, Product Description  */}

              </div>
              {/* end, column left  */}

            </div>
          </div>
          <section className="switchable pt-5">
            <div className="container">
              <div className="row justify-content-between">
                <div className="col-12 p-md-0">
                  <h2>Recommended</h2>

                  {/* tablets + desktop : min screen width should be above 767px  */}
                  {windowWidth > 767 && (
                    <>
                      {/* if images are below 7, setting slider data values to false */}
                      <div
                        className={`tiles recommended ${similarData.length > 6 ? "slider slider--columns" : "no-slider"}`}
                        data-arrows={similarData.length > 6 ? "true" : "false"}
                        data-paging={similarData.length > 6 ? "true" : "false"}
                        data-autoplay={
                          similarData.length > 6 ? "true" : "false"
                        }
                        data-draggable={
                          similarData.length > 6 ? "true" : "false"
                        }
                      >
                        <ul className="slides">
                          {this._renderSliderData()}
                        </ul>
                      </div>
                    </>
                  )}

                  {/* mobiles : max screen width should be below 768px  */}
                  {windowWidth < 768 && (
                    <>
                      {/* if images are below 3, setting slider data values to false */}
                      <div
                        className={`tiles recommended ${similarData.length > 2 ? "slider slider--columns" : "no-slider"}`}
                        data-arrows={similarData.length > 2 ? "true" : "false"}
                        data-paging={similarData.length > 2 ? "true" : "false"}
                        data-autoplay={
                          similarData.length > 2 ? "true" : "false"
                        }
                        data-draggable={
                          similarData.length > 2 ? "true" : "false"
                        }
                      >
                        <ul className="slides">
                          {this._renderSliderData()}
                        </ul>
                      </div>
                    </>
                  )}

                </div>
              </div>
            </div>
          </section>
        </section>
        {/* end, main product section  */}
        {this._renderVideoModal(dataDetail.video)}
      </div>
    )
  }
}
