import React from "react";

import H3 from "./editable/H3";
import H5 from "./editable/H5";
import Span from "./editable/Span";
import P from "./editable/P";
import Section from "./editable/Section";
import Img, { Sizes } from "./editable/Img";

import Button from "../Button";

import General from "../../utils/General";
import Backend from "../../utils/Backend";

import Titles from './editable/Titles';

export default class Block18 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
      editable: props.editable,
    };

    this.updateContentBlock = General.debounce(
      () => {
        console.log("3333", this.state.data);
        Backend.updateContentBlock(this.state.data);
      },
      1000,
      false
    );
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  _update(data, callback) {
    this.setState({ data }, () => {
      if (callback) {
        callback();
      }
      this.updateContentBlock();
    });
  }

  _renderImageInfoButton(data, imageInfo, imageInfos, index, editable) {
    let { button } = imageInfo;
    if (!button) {
      return null;
    }

    return (
      <Button
        data={button}
        editable={editable}
        color={button.settings.primary_color}
        type="link"
        style={{ paddingLeft: 0 }}
        onUpdated={(button) => {
          imageInfo.button = button;
          imageInfos[index] = imageInfo;
          data.image_infos = imageInfos;
          this._update(data);
        }}
      />
    );
  }

  _renderImageInfos(data, editable) {
    let imageInfos = data.image_infos;
    return imageInfos.map((imageInfo, index) => {
      return (
        <div
          key={imageInfo.id}
          className={ "col-md item" }
          style={{
            cursor:
              !this.state.editable && imageInfo.button?.url ? "pointer" : "",
          }}
          onClick={() => {
            if (this.state.editable || !imageInfo.button?.url) {
              return;
            }

            if (imageInfo.button?.open_in_new_tab) {
              window.open(imageInfo.button?.url);
            } else {
              window.location.href = imageInfo.button?.url;
            }
          }}
        >
          <article className="feature feature-1">
            <Img
              img={imageInfo.image}
              block={18}
              priority={this.props.index}
              className="bg-img-rounded"
              alt={""}
              editable={editable}
              onUpdated={(image) => {
                imageInfo.image = image;
                imageInfos[index] = imageInfo;
                data.image_infos = imageInfos;
                this._update(data);
              }}
            />
            <div className="feature__body boxed boxed--border b-18">
              <Span
                text={imageInfo.title}
                color={data.settings.secondary_color}
                editable={editable}
                textAlign="left"
                onUpdated={(text) => {
                  imageInfo.title = text;
                  imageInfos[index] = imageInfo;
                  data.image_infos = imageInfos;
                  this._update(data);
                }}
              />
              <H5
                text={imageInfo.subtitle}
                color={data.settings.secondary_color}
                editable={editable}
                textAlign="left"
                onUpdated={(text) => {
                  imageInfo.subtitle = text;
                  imageInfos[index] = imageInfo;
                  data.image_infos = imageInfos;
                  this._update(data);
                }}
              />
              {this._renderImageInfoButton(
                data,
                imageInfo,
                imageInfos,
                index,
                editable
              )}
            </div>
          </article>
        </div>
      );
    });
  }
  render() {
    let { data, editable } = this.state;
    let blockClassName = " block-18 bg--secondary  ";
    if (window.IS_CARD_STYLE_WEBSITE) {
      blockClassName += " deep-cardify";
    }
    return (
      <Section
        data={data}
        index={this.props.index}
        className={ blockClassName +this.props.className }
        editable={editable}
        onUpdated={(data) => this._update(data)}
        onDelete={() => this.props.onDelete()}
        primaryColor={false}
        secondaryColor={false}
        onAddContentBlockPressed={(contentBlock) =>
          this.props.onAddContentBlockPressed(contentBlock)
        }
        canMoveUp={this.props.canMoveUp}
        canMoveDown={this.props.canMoveDown}
        onVisibilityButtonPressed={(index) => this.props.onVisibilityButtonPressed(index)}
        onMoveUpPressed={() => this.props.onMoveUpPressed()}
        onMoveDownPressed={() => this.props.onMoveDownPressed()}
      >
        <div className="container">

          <Titles
            data={ data }
            editable={ editable }
            onUpdated={data => this._update(data)}
            rowCssClasses={"dc-mb-1"}
            alignment={"text-left"}
            headingNumber={3}
          />

          <div className="row">{this._renderImageInfos(data, editable)}</div>
        </div>
      </Section>
    );
  }
}
