import React from 'react'

import { ChromePicker } from 'react-color';

const SIZE = 30
export default class ColorPicker extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      title: props.title,
      className: props.className,
      color: props.color,
      displayColorPicker: false
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _renderPrimaryColorSwatch(){
    let primarySwatchStyle = window.CURRENT_PAGE_PRIMARY_COLOR === "transparent" ? styles.transparent : {}

    return (
      <>
        <a
          className="primary-color-swatch float-right d-inline-flex align-items-center"
          onClick={() => {
            this.setState({ color: null })
            this.props.onColorSelected(window.CURRENT_PAGE_PRIMARY_COLOR)
          }}
        >
          <label className="mr-2">Main Site Color</label>
          <div
            className="color"
            style={{
              cursor: 'pointer',
              position: 'relative',
              outline: 'none',
              borderRadius: 4,
              marginBotton: 6,
              marginRight: 6,
              backgroundColor: window.CURRENT_PAGE_PRIMARY_COLOR,
              border: '1px solid #d3d3d3',
              height: SIZE,
              width: SIZE,
              ...primarySwatchStyle
            }}
          />
        </a>
      </>
    )
  }

  _renderSwatches(){

    let colors = this.props.mscPicker ? window.MSC_COLORS : window.MSC_COMPLIMENTARY_COLOURS

    return colors.map(value => {

      let style = {
        height: SIZE,
        width: SIZE,
        backgroundColor: value,
      }

      if(value === 'transparent'){
        style = {
            ...style,
            ...styles.transparent
        }
      }

      return (
          <a
            className="color"
            onClick={() => {
              this.setState({ color: value })
              this.props.onColorSelected(value)
            }}
          >
            <div
              style={{
                cursor: 'pointer',
                position: 'relative',
                outline: 'none',
                float: 'left',
                borderRadius: 4,
                marginRight: 6,
                marginBotton: 6,
                border: '1px solid #d3d3d3',
                ...style,
              }}
            />
          </a>
      )
    })
  }

  _renderColorInput(){
    let {
      title,
      color
    } = this.state

    let displayColor = color
    if(displayColor){
       displayColor = displayColor.replace("#", "")
    }

    if(displayColor == "transparent"){
      displayColor = "clear"
    }

    return (
      <div
        className={ `color-box-value ${this.props.colorBoxClassName}`}
        style={{
          height: SIZE,
          minWidth: SIZE * 6.2,
          cursor: 'pointer',
          position: 'relative',
          outline: 'none',
          float: 'left',
          borderRadius: 4,
          marginRight: 0,
          marginLeft: 0,
          marginBotton: 6,
          boxShadow: 'rgb(240, 240, 240) 0px 0px 0px 1px inset',
          boxSizing: 'content-box',
          backgroundColor: 'white',
          ...this.props.colorBoxStyle
        }}
      >
        <a
          onClick={() => {
            this.setState({ displayColorPicker: true })
          }}
        >
          <div
            style={{
              ...styles.hash,
              color: this.state.color
            }}
          >
            #
          </div>
          <div
            style={{
              ...styles.colorText,
              ...this.props.colorTextStyle
            }}
          >
            { displayColor }
          </div>
        </a>

        { this._renderColorPickerModal()}
      </div>
    )
  }

  _renderColorPickerModal(){
    let {
      color,
      displayColorPicker
    } = this.state

    if(!displayColorPicker){
      return null
    }

    return (
      <div style={ styles.popover }>
        <div style={ styles.cover }
          onClick={() => {
             this.setState({ displayColorPicker: false })
          } }
        />
        <ChromePicker
          color={color || "#FFF"}
          onChange={color => {
            let hex = color.hex
            if(color.rgb.a !== 1){
              hex = `${color.hex}${decimalToHex(color.rgb.a)}`
            }
            this.setState({ color: hex })
            this.props.onColorSelected(hex)
          }}
        />
      </div>
    )
  }

  render(){
    let {
      title,className
    } = this.state

    return (
      <div className={` color-picker sm-color-picker ${className} `} style={{ marginBottom: 10}}>
        <div className="align-items-center" style={{ marginBottom: 16}}>
          { title }
        </div>

        { this._renderSwatches() }
        { this._renderColorInput() }
        { this._renderPrimaryColorSwatch() }

        <div style={{clear: "both"}}></div>
      </div>
    )
  }
}

const styles = {
  colorText: {
    height: SIZE,
    minWidth: SIZE * 3,
    float: 'left',
    verticalAlign: 'middle',
    lineHeight: SIZE+"px",
    fontSize: 16,
    fontWeight: 400,
    textTransform: 'uppercase',
    paddingLeft: 15,
    paddingRight: 15
  },
  colorTextStyle: {},
  hash: {
    height: SIZE,
    minWidth: SIZE,
    float: 'left',
    textAlign: 'center',
    verticalAlign: 'middle',
    lineHeight: SIZE+"px",
    fontSize: 16,
    fontWeight: 400,
    background: 'rgb(230, 230, 230)',
    color: 'rgb(152, 161, 164)',
    borderBottomLeftRadius: 4,
    borderTopLeftRadius: 4,
  },
  cover: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  },
  popover: {
    position: 'absolute',
    zIndex: '2',
    top: 32,
    left: 0
  },
  transparent: {
    backgroundImage: 'linear-gradient(45deg, #e5e5e5 25%, transparent 25%), linear-gradient(135deg, #e5e5e5 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #e5e5e5 75%), linear-gradient(135deg, transparent 75%, #e5e5e5 75%)',
    backgroundSize: '10px 10px',
    backgroundPosition: '0 0, 5px 0, 5px -5px, 0px 5px',
    backgroundColor: '#f5f5f5'
  }
}

const decimalToHex = (alpha: number) => alpha === 0 ? '00' : Math.round(255 * alpha).toString(16)

ColorPicker.defaultProps = {
  colorBoxClassName: "",
  colorBoxStyle: {},
  mscPicker: false,
}
