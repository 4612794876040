import React from 'react';
import Dropzone from 'react-dropzone-uploader'

import ContentLoader from "react-content-loader";

import 'react-dropzone-uploader/dist/styles.css'

import AuthManager from '../../../utils/AuthManager'
import Backend from '../../../utils/Backend'

const HEIGHT = 100
export default class CustomDropzone extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      loading: false
    }
  }

  _getUploadParams({ file, meta }){
    const body = new FormData()
    body.append('file', file)
    body.append('type', 'photo')

    let headers = {}
    if(AuthManager.accessToken){
      // headers["Content-Type"] = "Bearer " + AuthManager.accessToken
      headers["Authorization"] = "Bearer " + AuthManager.accessToken
    }

    let url = window.Api.Images

    return {
      file,
      body,
      headers,
      url
    }
  }

  // called every time a file's `status` changes
  _handleChangeStatus({ meta, file, xhr }, status){
    let complete = status === 'done' || status === "exception_upload"

    this.setState({ loading: !complete })
    if(status === "done"){
      let image = JSON.parse(xhr.responseText)
      this.props.onUploaded(image)
    }
    if (status === "rejected_file_type" || status === "error_upload") {
      alert("You can only upload image files - JPG, JPEG, PNG.");
      this.setState({ loading: false });
    }
  }

  addImage(data){
    this.setState({ loading: true, showImagePicker: false })
    Backend.addImage(data)
    .then(image => {
      this.setState({ loading: false })
      this.props.onUploaded(image)
    })
    .catch(error => {
      alert(error.message)
      this.setState({ loading: false })
    })
  }

  render(){
    let {
      loading,
      showImagePicker
    } = this.state

    if(loading){
      return (
        <ContentLoader speed={0.5} style={{ width: '100%', height: HEIGHT }}>
          <rect x="0" y="0" rx="10" ry="10" width="100%" height="100%" />
        </ContentLoader>
      )
    }
    return (
      <Dropzone
        getUploadParams={values => this._getUploadParams(values)}
        onChangeStatus={(values, status) => this._handleChangeStatus(values, status)}
        accept="image/*"
        multiple={false}
        maxFiles={1}
        styles={{
          dropzone: { width: 400, height: HEIGHT },
        }}
        SubmitButtonComponent={null}
        PreviewComponent={null}
        inputContent="click to upload or drag image here"
      />
    )
  }
}

CustomDropzone.defaultProps = {
  type: 'photo'
}
