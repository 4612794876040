export const IconsMind = [
  { name: "Aa", icon: "\e601" },
  { name: "Add-Bag", icon: "\e602" },
  { name: "Add-Basket", icon: "\e603" },
  { name: "Add-Cart", icon: "\e604" },
  { name: "Add-File", icon: "\e605" },
  { name: "Add-SpaceAfterParagraph", icon: "\e606" },
  { name: "Add-SpaceBeforeParagraph", icon: "\e607" },
  { name: "Add-User", icon: "\e608" },
  { name: "Add-UserStar", icon: "\e609" },
  { name: "Add-Window", icon: "\e60a" },
  { name: "Add", icon: "\e60b" },
  { name: "Address-Book", icon: "\e60c" },
  { name: "Address-Book2", icon: "\e60d" },
  { name: "Administrator", icon: "\e60e" },
  { name: "Aerobics-2", icon: "\e60f" },
  { name: "Aerobics-3", icon: "\e610" },
  { name: "Aerobics", icon: "\e611" },
  { name: "Affiliate", icon: "\e612" },
  { name: "Aim", icon: "\e613" },
  { name: "Air-Balloon", icon: "\e614" },
  { name: "Airbrush", icon: "\e615" },
  { name: "Airship", icon: "\e616" },
  { name: "Alarm-Clock", icon: "\e617" },
  { name: "Alarm-Clock2", icon: "\e618" },
  { name: "Alarm", icon: "\e619" },
  { name: "Alien-2", icon: "\e61a" },
  { name: "Alien", icon: "\e61b" },
  { name: "Aligator", icon: "\e61c" },
  { name: "Align-Center", icon: "\e61d" },
  { name: "Align-JustifyAll", icon: "\e61e" },
  { name: "Align-JustifyCenter", icon: "\e61f" },
  { name: "Align-JustifyLeft", icon: "\e620" },
  { name: "Align-JustifyRight", icon: "\e621" },
  { name: "Align-Left", icon: "\e622" },
  { name: "Align-Right", icon: "\e623" },
  { name: "Alpha", icon: "\e624" },
  { name: "Ambulance", icon: "\e625" },
  { name: "AMX", icon: "\e626" },
  { name: "Anchor-2", icon: "\e627" },
  { name: "Anchor", icon: "\e628" },
  { name: "Android-Store", icon: "\e629" },
  { name: "Android", icon: "\e62a" },
  { name: "Angel-Smiley", icon: "\e62b" },
  { name: "Angel", icon: "\e62c" },
  { name: "Angry", icon: "\e62d" },
  { name: "Apple-Bite", icon: "\e62e" },
  { name: "Apple-Store", icon: "\e62f" },
  { name: "Apple", icon: "\e630" },
  { name: "Approved-Window", icon: "\e631" },
  { name: "Aquarius-2", icon: "\e632" },
  { name: "Aquarius", icon: "\e633" },
  { name: "Archery-2", icon: "\e634" },
  { name: "Archery", icon: "\e635" },
  { name: "Argentina", icon: "\e636" },
  { name: "Aries-2", icon: "\e637" },
  { name: "Aries", icon: "\e638" },
  { name: "Army-Key", icon: "\e639" },
  { name: "Arrow-Around", icon: "\e63a" },
  { name: "Arrow-Back3", icon: "\e63b" },
  { name: "Arrow-Back", icon: "\e63c" },
  { name: "Arrow-Back2", icon: "\e63d" },
  { name: "Arrow-Barrier", icon: "\e63e" },
  { name: "Arrow-Circle", icon: "\e63f" },
  { name: "Arrow-Cross", icon: "\e640" },
  { name: "Arrow-Down", icon: "\e641" },
  { name: "Arrow-Down2", icon: "\e642" },
  { name: "Arrow-Down3", icon: "\e643" },
  { name: "Arrow-DowninCircle", icon: "\e644" },
  { name: "Arrow-Fork", icon: "\e645" },
  { name: "Arrow-Forward", icon: "\e646" },
  { name: "Arrow-Forward2", icon: "\e647" },
  { name: "Arrow-From", icon: "\e648" },
  { name: "Arrow-Inside", icon: "\e649" },
  { name: "Arrow-Inside45", icon: "\e64a" },
  { name: "Arrow-InsideGap", icon: "\e64b" },
  { name: "Arrow-InsideGap45", icon: "\e64c" },
  { name: "Arrow-Into", icon: "\e64d" },
  { name: "Arrow-Join", icon: "\e64e" },
  { name: "Arrow-Junction", icon: "\e64f" },
  { name: "Arrow-Left", icon: "\e650" },
  { name: "Arrow-Left2", icon: "\e651" },
  { name: "Arrow-LeftinCircle", icon: "\e652" },
  { name: "Arrow-Loop", icon: "\e653" },
  { name: "Arrow-Merge", icon: "\e654" },
  { name: "Arrow-Mix", icon: "\e655" },
  { name: "Arrow-Next", icon: "\e656" },
  { name: "Arrow-OutLeft", icon: "\e657" },
  { name: "Arrow-OutRight", icon: "\e658" },
  { name: "Arrow-Outside", icon: "\e659" },
  { name: "Arrow-Outside45", icon: "\e65a" },
  { name: "Arrow-OutsideGap", icon: "\e65b" },
  { name: "Arrow-OutsideGap45", icon: "\e65c" },
  { name: "Arrow-Over", icon: "\e65d" },
  { name: "Arrow-Refresh", icon: "\e65e" },
  { name: "Arrow-Refresh2", icon: "\e65f" },
  { name: "Arrow-Right", icon: "\e660" },
  { name: "Arrow-Right2", icon: "\e661" },
  { name: "Arrow-RightinCircle", icon: "\e662" },
  { name: "Arrow-Shuffle", icon: "\e663" },
  { name: "Arrow-Squiggly", icon: "\e664" },
  { name: "Arrow-Through", icon: "\e665" },
  { name: "Arrow-To", icon: "\e666" },
  { name: "Arrow-TurnLeft", icon: "\e667" },
  { name: "Arrow-TurnRight", icon: "\e668" },
  { name: "Arrow-Up", icon: "\e669" },
  { name: "Arrow-Up2", icon: "\e66a" },
  { name: "Arrow-Up3", icon: "\e66b" },
  { name: "Arrow-UpinCircle", icon: "\e66c" },
  { name: "Arrow-XLeft", icon: "\e66d" },
  { name: "Arrow-XRight", icon: "\e66e" },
  { name: "Ask", icon: "\e66f" },
  { name: "Assistant", icon: "\e670" },
  { name: "Astronaut", icon: "\e671" },
  { name: "At-Sign", icon: "\e672" },
  { name: "ATM", icon: "\e673" },
  { name: "Atom", icon: "\e674" },
  { name: "Audio", icon: "\e675" },
  { name: "Auto-Flash", icon: "\e676" },
  { name: "Autumn", icon: "\e677" },
  { name: "Baby-Clothes", icon: "\e678" },
  { name: "Baby-Clothes2", icon: "\e679" },
  { name: "Baby-Cry", icon: "\e67a" },
  { name: "Baby", icon: "\e67b" },
  { name: "Back2", icon: "\e67c" },
  { name: "Back-Media", icon: "\e67d" },
  { name: "Back-Music", icon: "\e67e" },
  { name: "Back", icon: "\e67f" },
  { name: "Background", icon: "\e680" },
  { name: "Bacteria", icon: "\e681" },
  { name: "Bag-Coins", icon: "\e682" },
  { name: "Bag-Items", icon: "\e683" },
  { name: "Bag-Quantity", icon: "\e684" },
  { name: "Bag", icon: "\e685" },
  { name: "Bakelite", icon: "\e686" },
  { name: "Ballet-Shoes", icon: "\e687" },
  { name: "Balloon", icon: "\e688" },
  { name: "Banana", icon: "\e689" },
  { name: "Band-Aid", icon: "\e68a" },
  { name: "Bank", icon: "\e68b" },
  { name: "Bar-Chart", icon: "\e68c" },
  { name: "Bar-Chart2", icon: "\e68d" },
  { name: "Bar-Chart3", icon: "\e68e" },
  { name: "Bar-Chart4", icon: "\e68f" },
  { name: "Bar-Chart5", icon: "\e690" },
  { name: "Bar-Code", icon: "\e691" },
  { name: "Barricade-2", icon: "\e692" },
  { name: "Barricade", icon: "\e693" },
  { name: "Baseball", icon: "\e694" },
  { name: "Basket-Ball", icon: "\e695" },
  { name: "Basket-Coins", icon: "\e696" },
  { name: "Basket-Items", icon: "\e697" },
  { name: "Basket-Quantity", icon: "\e698" },
  { name: "Bat-2", icon: "\e699" },
  { name: "Bat", icon: "\e69a" },
  { name: "Bathrobe", icon: "\e69b" },
  { name: "Batman-Mask", icon: "\e69c" },
  { name: "Battery-0", icon: "\e69d" },
  { name: "Battery-25", icon: "\e69e" },
  { name: "Battery-50", icon: "\e69f" },
  { name: "Battery-75", icon: "\e6a0" },
  { name: "Battery-100", icon: "\e6a1" },
  { name: "Battery-Charge", icon: "\e6a2" },
  { name: "Bear", icon: "\e6a3" },
  { name: "Beard-2", icon: "\e6a4" },
  { name: "Beard-3", icon: "\e6a5" },
  { name: "Beard", icon: "\e6a6" },
  { name: "Bebo", icon: "\e6a7" },
  { name: "Bee", icon: "\e6a8" },
  { name: "Beer-Glass", icon: "\e6a9" },
  { name: "Beer", icon: "\e6aa" },
  { name: "Bell-2", icon: "\e6ab" },
  { name: "Bell", icon: "\e6ac" },
  { name: "Belt-2", icon: "\e6ad" },
  { name: "Belt-3", icon: "\e6ae" },
  { name: "Belt", icon: "\e6af" },
  { name: "Berlin-Tower", icon: "\e6b0" },
  { name: "Beta", icon: "\e6b1" },
  { name: "Betvibes", icon: "\e6b2" },
  { name: "Bicycle-2", icon: "\e6b3" },
  { name: "Bicycle-3", icon: "\e6b4" },
  { name: "Bicycle", icon: "\e6b5" },
  { name: "Big-Bang", icon: "\e6b6" },
  { name: "Big-Data", icon: "\e6b7" },
  { name: "Bike-Helmet", icon: "\e6b8" },
  { name: "Bikini", icon: "\e6b9" },
  { name: "Bilk-Bottle2", icon: "\e6ba" },
  { name: "Billing", icon: "\e6bb" },
  { name: "Bing", icon: "\e6bc" },
  { name: "Binocular", icon: "\e6bd" },
  { name: "Bio-Hazard", icon: "\e6be" },
  { name: "Biotech", icon: "\e6bf" },
  { name: "Bird-DeliveringLetter", icon: "\e6c0" },
  { name: "Bird", icon: "\e6c1" },
  { name: "Birthday-Cake", icon: "\e6c2" },
  { name: "Bisexual", icon: "\e6c3" },
  { name: "Bishop", icon: "\e6c4" },
  { name: "Bitcoin", icon: "\e6c5" },
  { name: "Black-Cat", icon: "\e6c6" },
  { name: "Blackboard", icon: "\e6c7" },
  { name: "Blinklist", icon: "\e6c8" },
  { name: "Block-Cloud", icon: "\e6c9" },
  { name: "Block-Window", icon: "\e6ca" },
  { name: "Blogger", icon: "\e6cb" },
  { name: "Blood", icon: "\e6cc" },
  { name: "Blouse", icon: "\e6cd" },
  { name: "Blueprint", icon: "\e6ce" },
  { name: "Board", icon: "\e6cf" },
  { name: "Bodybuilding", icon: "\e6d0" },
  { name: "Bold-Text", icon: "\e6d1" },
  { name: "Bone", icon: "\e6d2" },
  { name: "Bones", icon: "\e6d3" },
  { name: "Book", icon: "\e6d4" },
  { name: "Bookmark", icon: "\e6d5" },
  { name: "Books-2", icon: "\e6d6" },
  { name: "Books", icon: "\e6d7" },
  { name: "Boom", icon: "\e6d8" },
  { name: "Boot-2", icon: "\e6d9" },
  { name: "Boot", icon: "\e6da" },
  { name: "Bottom-ToTop", icon: "\e6db" },
  { name: "Bow-2", icon: "\e6dc" },
  { name: "Bow-3", icon: "\e6dd" },
  { name: "Bow-4", icon: "\e6de" },
  { name: "Bow-5", icon: "\e6df" },
  { name: "Bow-6", icon: "\e6e0" },
  { name: "Bow", icon: "\e6e1" },
  { name: "Bowling-2", icon: "\e6e2" },
  { name: "Bowling", icon: "\e6e3" },
  { name: "Box2", icon: "\e6e4" },
  { name: "Box-Close", icon: "\e6e5" },
  { name: "Box-Full", icon: "\e6e6" },
  { name: "Box-Open", icon: "\e6e7" },
  { name: "Box-withFolders", icon: "\e6e8" },
  { name: "Box", icon: "\e6e9" },
  { name: "Boy", icon: "\e6ea" },
  { name: "Bra", icon: "\e6eb" },
  { name: "Brain-2", icon: "\e6ec" },
  { name: "Brain-3", icon: "\e6ed" },
  { name: "Brain", icon: "\e6ee" },
  { name: "Brazil", icon: "\e6ef" },
  { name: "Bread-2", icon: "\e6f0" },
  { name: "Bread", icon: "\e6f1" },
  { name: "Bridge", icon: "\e6f2" },
  { name: "Brightkite", icon: "\e6f3" },
  { name: "Broke-Link2", icon: "\e6f4" },
  { name: "Broken-Link", icon: "\e6f5" },
  { name: "Broom", icon: "\e6f6" },
  { name: "Brush", icon: "\e6f7" },
  { name: "Bucket", icon: "\e6f8" },
  { name: "Bug", icon: "\e6f9" },
  { name: "Building", icon: "\e6fa" },
  { name: "Bulleted-List", icon: "\e6fb" },
  { name: "Bus-2", icon: "\e6fc" },
  { name: "Bus", icon: "\e6fd" },
  { name: "Business-Man", icon: "\e6fe" },
  { name: "Business-ManWoman", icon: "\e6ff" },
  { name: "Business-Mens", icon: "\e700" },
  { name: "Business-Woman", icon: "\e701" },
  { name: "Butterfly", icon: "\e702" },
  { name: "Button", icon: "\e703" },
  { name: "Cable-Car", icon: "\e704" },
  { name: "Cake", icon: "\e705" },
  { name: "Calculator-2", icon: "\e706" },
  { name: "Calculator-3", icon: "\e707" },
  { name: "Calculator", icon: "\e708" },
  { name: "Calendar-2", icon: "\e709" },
  { name: "Calendar-3", icon: "\e70a" },
  { name: "Calendar-4", icon: "\e70b" },
  { name: "Calendar-Clock", icon: "\e70c" },
  { name: "Calendar", icon: "\e70d" },
  { name: "Camel", icon: "\e70e" },
  { name: "Camera-2", icon: "\e70f" },
  { name: "Camera-3", icon: "\e710" },
  { name: "Camera-4", icon: "\e711" },
  { name: "Camera-5", icon: "\e712" },
  { name: "Camera-Back", icon: "\e713" },
  { name: "Camera", icon: "\e714" },
  { name: "Can-2", icon: "\e715" },
  { name: "Can", icon: "\e716" },
  { name: "Canada", icon: "\e717" },
  { name: "Cancer-2", icon: "\e718" },
  { name: "Cancer-3", icon: "\e719" },
  { name: "Cancer", icon: "\e71a" },
  { name: "Candle", icon: "\e71b" },
  { name: "Candy-Cane", icon: "\e71c" },
  { name: "Candy", icon: "\e71d" },
  { name: "Cannon", icon: "\e71e" },
  { name: "Cap-2", icon: "\e71f" },
  { name: "Cap-3", icon: "\e720" },
  { name: "Cap-Smiley", icon: "\e721" },
  { name: "Cap", icon: "\e722" },
  { name: "Capricorn-2", icon: "\e723" },
  { name: "Capricorn", icon: "\e724" },
  { name: "Car-2", icon: "\e725" },
  { name: "Car-3", icon: "\e726" },
  { name: "Car-Coins", icon: "\e727" },
  { name: "Car-Items", icon: "\e728" },
  { name: "Car-Wheel", icon: "\e729" },
  { name: "Car", icon: "\e72a" },
  { name: "Cardigan", icon: "\e72b" },
  { name: "Cardiovascular", icon: "\e72c" },
  { name: "Cart-Quantity", icon: "\e72d" },
  { name: "Casette-Tape", icon: "\e72e" },
  { name: "Cash-Register", icon: "\e72f" },
  { name: "Cash-register2", icon: "\e730" },
  { name: "Castle", icon: "\e731" },
  { name: "Cat", icon: "\e732" },
  { name: "Cathedral", icon: "\e733" },
  { name: "Cauldron", icon: "\e734" },
  { name: "CD-2", icon: "\e735" },
  { name: "CD-Cover", icon: "\e736" },
  { name: "CD", icon: "\e737" },
  { name: "Cello", icon: "\e738" },
  { name: "Celsius", icon: "\e739" },
  { name: "Chacked-Flag", icon: "\e73a" },
  { name: "Chair", icon: "\e73b" },
  { name: "Charger", icon: "\e73c" },
  { name: "Check-2", icon: "\e73d" },
  { name: "Check", icon: "\e73e" },
  { name: "Checked-User", icon: "\e73f" },
  { name: "Checkmate", icon: "\e740" },
  { name: "Checkout-Bag", icon: "\e741" },
  { name: "Checkout-Basket", icon: "\e742" },
  { name: "Checkout", icon: "\e743" },
  { name: "Cheese", icon: "\e744" },
  { name: "Cheetah", icon: "\e745" },
  { name: "Chef-Hat", icon: "\e746" },
  { name: "Chef-Hat2", icon: "\e747" },
  { name: "Chef", icon: "\e748" },
  { name: "Chemical-2", icon: "\e749" },
  { name: "Chemical-3", icon: "\e74a" },
  { name: "Chemical-4", icon: "\e74b" },
  { name: "Chemical-5", icon: "\e74c" },
  { name: "Chemical", icon: "\e74d" },
  { name: "Chess-Board", icon: "\e74e" },
  { name: "Chess", icon: "\e74f" },
  { name: "Chicken", icon: "\e750" },
  { name: "Chile", icon: "\e751" },
  { name: "Chimney", icon: "\e752" },
  { name: "China", icon: "\e753" },
  { name: "Chinese-Temple", icon: "\e754" },
  { name: "Chip", icon: "\e755" },
  { name: "Chopsticks-2", icon: "\e756" },
  { name: "Chopsticks", icon: "\e757" },
  { name: "Christmas-Ball", icon: "\e758" },
  { name: "Christmas-Bell", icon: "\e759" },
  { name: "Christmas-Candle", icon: "\e75a" },
  { name: "Christmas-Hat", icon: "\e75b" },
  { name: "Christmas-Sleigh", icon: "\e75c" },
  { name: "Christmas-Snowman", icon: "\e75d" },
  { name: "Christmas-Sock", icon: "\e75e" },
  { name: "Christmas-Tree", icon: "\e75f" },
  { name: "Christmas", icon: "\e760" },
  { name: "Chrome", icon: "\e761" },
  { name: "Chrysler-Building", icon: "\e762" },
  { name: "Cinema", icon: "\e763" },
  { name: "Circular-Point", icon: "\e764" },
  { name: "City-Hall", icon: "\e765" },
  { name: "Clamp", icon: "\e766" },
  { name: "Clapperboard-Close", icon: "\e767" },
  { name: "Clapperboard-Open", icon: "\e768" },
  { name: "Claps", icon: "\e769" },
  { name: "Clef", icon: "\e76a" },
  { name: "Clinic", icon: "\e76b" },
  { name: "Clock-2", icon: "\e76c" },
  { name: "Clock-3", icon: "\e76d" },
  { name: "Clock-4", icon: "\e76e" },
  { name: "Clock-Back", icon: "\e76f" },
  { name: "Clock-Forward", icon: "\e770" },
  { name: "Clock", icon: "\e771" },
  { name: "Close-Window", icon: "\e772" },
  { name: "Close", icon: "\e773" },
  { name: "Clothing-Store", icon: "\e774" },
  { name: "Cloud--", icon: "\e775" },
  { name: "Cloud-", icon: "\e776" },
  { name: "Cloud-Camera", icon: "\e777" },
  { name: "Cloud-Computer", icon: "\e778" },
  { name: "Cloud-Email", icon: "\e779" },
  { name: "Cloud-Hail", icon: "\e77a" },
  { name: "Cloud-Laptop", icon: "\e77b" },
  { name: "Cloud-Lock", icon: "\e77c" },
  { name: "Cloud-Moon", icon: "\e77d" },
  { name: "Cloud-Music", icon: "\e77e" },
  { name: "Cloud-Picture", icon: "\e77f" },
  { name: "Cloud-Rain", icon: "\e780" },
  { name: "Cloud-Remove", icon: "\e781" },
  { name: "Cloud-Secure", icon: "\e782" },
  { name: "Cloud-Settings", icon: "\e783" },
  { name: "Cloud-Smartphone", icon: "\e784" },
  { name: "Cloud-Snow", icon: "\e785" },
  { name: "Cloud-Sun", icon: "\e786" },
  { name: "Cloud-Tablet", icon: "\e787" },
  { name: "Cloud-Video", icon: "\e788" },
  { name: "Cloud-Weather", icon: "\e789" },
  { name: "Cloud", icon: "\e78a" },
  { name: "Clouds-Weather", icon: "\e78b" },
  { name: "Clouds", icon: "\e78c" },
  { name: "Clown", icon: "\e78d" },
  { name: "CMYK", icon: "\e78e" },
  { name: "Coat", icon: "\e78f" },
  { name: "Cocktail", icon: "\e790" },
  { name: "Coconut", icon: "\e791" },
  { name: "Code-Window", icon: "\e792" },
  { name: "Coding", icon: "\e793" },
  { name: "Coffee-2", icon: "\e794" },
  { name: "Coffee-Bean", icon: "\e795" },
  { name: "Coffee-Machine", icon: "\e796" },
  { name: "Coffee-toGo", icon: "\e797" },
  { name: "Coffee", icon: "\e798" },
  { name: "Coffin", icon: "\e799" },
  { name: "Coin", icon: "\e79a" },
  { name: "Coins-2", icon: "\e79b" },
  { name: "Coins-3", icon: "\e79c" },
  { name: "Coins", icon: "\e79d" },
  { name: "Colombia", icon: "\e79e" },
  { name: "Colosseum", icon: "\e79f" },
  { name: "Column-2", icon: "\e7a0" },
  { name: "Column-3", icon: "\e7a1" },
  { name: "Column", icon: "\e7a2" },
  { name: "Comb-2", icon: "\e7a3" },
  { name: "Comb", icon: "\e7a4" },
  { name: "Communication-Tower", icon: "\e7a5" },
  { name: "Communication-Tower2", icon: "\e7a6" },
  { name: "Compass-2", icon: "\e7a7" },
  { name: "Compass-3", icon: "\e7a8" },
  { name: "Compass-4", icon: "\e7a9" },
  { name: "Compass-Rose", icon: "\e7aa" },
  { name: "Compass", icon: "\e7ab" },
  { name: "Computer-2", icon: "\e7ac" },
  { name: "Computer-3", icon: "\e7ad" },
  { name: "Computer-Secure", icon: "\e7ae" },
  { name: "Computer", icon: "\e7af" },
  { name: "Conference", icon: "\e7b0" },
  { name: "Confused", icon: "\e7b1" },
  { name: "Conservation", icon: "\e7b2" },
  { name: "Consulting", icon: "\e7b3" },
  { name: "Contrast", icon: "\e7b4" },
  { name: "Control-2", icon: "\e7b5" },
  { name: "Control", icon: "\e7b6" },
  { name: "Cookie-Man", icon: "\e7b7" },
  { name: "Cookies", icon: "\e7b8" },
  { name: "Cool-Guy", icon: "\e7b9" },
  { name: "Cool", icon: "\e7ba" },
  { name: "Copyright", icon: "\e7bb" },
  { name: "Costume", icon: "\e7bc" },
  { name: "Couple-Sign", icon: "\e7bd" },
  { name: "Cow", icon: "\e7be" },
  { name: "CPU", icon: "\e7bf" },
  { name: "Crane", icon: "\e7c0" },
  { name: "Cranium", icon: "\e7c1" },
  { name: "Credit-Card", icon: "\e7c2" },
  { name: "Credit-Card2", icon: "\e7c3" },
  { name: "Credit-Card3", icon: "\e7c4" },
  { name: "Cricket", icon: "\e7c5" },
  { name: "Criminal", icon: "\e7c6" },
  { name: "Croissant", icon: "\e7c7" },
  { name: "Crop-2", icon: "\e7c8" },
  { name: "Crop-3", icon: "\e7c9" },
  { name: "Crown-2", icon: "\e7ca" },
  { name: "Crown", icon: "\e7cb" },
  { name: "Crying", icon: "\e7cc" },
  { name: "Cube-Molecule", icon: "\e7cd" },
  { name: "Cube-Molecule2", icon: "\e7ce" },
  { name: "Cupcake", icon: "\e7cf" },
  { name: "Cursor-Click", icon: "\e7d0" },
  { name: "Cursor-Click2", icon: "\e7d1" },
  { name: "Cursor-Move", icon: "\e7d2" },
  { name: "Cursor-Move2", icon: "\e7d3" },
  { name: "Cursor-Select", icon: "\e7d4" },
  { name: "Cursor", icon: "\e7d5" },
  { name: "D-Eyeglasses", icon: "\e7d6" },
  { name: "D-Eyeglasses2", icon: "\e7d7" },
  { name: "Dam", icon: "\e7d8" },
  { name: "Danemark", icon: "\e7d9" },
  { name: "Danger-2", icon: "\e7da" },
  { name: "Danger", icon: "\e7db" },
  { name: "Dashboard", icon: "\e7dc" },
  { name: "Data-Backup", icon: "\e7dd" },
  { name: "Data-Block", icon: "\e7de" },
  { name: "Data-Center", icon: "\e7df" },
  { name: "Data-Clock", icon: "\e7e0" },
  { name: "Data-Cloud", icon: "\e7e1" },
  { name: "Data-Compress", icon: "\e7e2" },
  { name: "Data-Copy", icon: "\e7e3" },
  { name: "Data-Download", icon: "\e7e4" },
  { name: "Data-Financial", icon: "\e7e5" },
  { name: "Data-Key", icon: "\e7e6" },
  { name: "Data-Lock", icon: "\e7e7" },
  { name: "Data-Network", icon: "\e7e8" },
  { name: "Data-Password", icon: "\e7e9" },
  { name: "Data-Power", icon: "\e7ea" },
  { name: "Data-Refresh", icon: "\e7eb" },
  { name: "Data-Save", icon: "\e7ec" },
  { name: "Data-Search", icon: "\e7ed" },
  { name: "Data-Security", icon: "\e7ee" },
  { name: "Data-Settings", icon: "\e7ef" },
  { name: "Data-Sharing", icon: "\e7f0" },
  { name: "Data-Shield", icon: "\e7f1" },
  { name: "Data-Signal", icon: "\e7f2" },
  { name: "Data-Storage", icon: "\e7f3" },
  { name: "Data-Stream", icon: "\e7f4" },
  { name: "Data-Transfer", icon: "\e7f5" },
  { name: "Data-Unlock", icon: "\e7f6" },
  { name: "Data-Upload", icon: "\e7f7" },
  { name: "Data-Yes", icon: "\e7f8" },
  { name: "Data", icon: "\e7f9" },
  { name: "David-Star", icon: "\e7fa" },
  { name: "Daylight", icon: "\e7fb" },
  { name: "Death", icon: "\e7fc" },
  { name: "Debian", icon: "\e7fd" },
  { name: "Dec", icon: "\e7fe" },
  { name: "Decrase-Inedit", icon: "\e7ff" },
  { name: "Deer-2", icon: "\e800" },
  { name: "Deer", icon: "\e801" },
  { name: "Delete-File", icon: "\e802" },
  { name: "Delete-Window", icon: "\e803" },
  { name: "Delicious", icon: "\e804" },
  { name: "Depression", icon: "\e805" },
  { name: "Deviantart", icon: "\e806" },
  { name: "Device-SyncwithCloud", icon: "\e807" },
  { name: "Diamond", icon: "\e808" },
  { name: "Dice-2", icon: "\e809" },
  { name: "Dice", icon: "\e80a" },
  { name: "Digg", icon: "\e80b" },
  { name: "Digital-Drawing", icon: "\e80c" },
  { name: "Diigo", icon: "\e80d" },
  { name: "Dinosaur", icon: "\e80e" },
  { name: "Diploma-2", icon: "\e80f" },
  { name: "Diploma", icon: "\e810" },
  { name: "Direction-East", icon: "\e811" },
  { name: "Direction-North", icon: "\e812" },
  { name: "Direction-South", icon: "\e813" },
  { name: "Direction-West", icon: "\e814" },
  { name: "Director", icon: "\e815" },
  { name: "Disk", icon: "\e816" },
  { name: "Dj", icon: "\e817" },
  { name: "DNA-2", icon: "\e818" },
  { name: "DNA-Helix", icon: "\e819" },
  { name: "DNA", icon: "\e81a" },
  { name: "Doctor", icon: "\e81b" },
  { name: "Dog", icon: "\e81c" },
  { name: "Dollar-Sign", icon: "\e81d" },
  { name: "Dollar-Sign2", icon: "\e81e" },
  { name: "Dollar", icon: "\e81f" },
  { name: "Dolphin", icon: "\e820" },
  { name: "Domino", icon: "\e821" },
  { name: "Door-Hanger", icon: "\e822" },
  { name: "Door", icon: "\e823" },
  { name: "Doplr", icon: "\e824" },
  { name: "Double-Circle", icon: "\e825" },
  { name: "Double-Tap", icon: "\e826" },
  { name: "Doughnut", icon: "\e827" },
  { name: "Dove", icon: "\e828" },
  { name: "Down-2", icon: "\e829" },
  { name: "Down-3", icon: "\e82a" },
  { name: "Down-4", icon: "\e82b" },
  { name: "Down", icon: "\e82c" },
  { name: "Download-2", icon: "\e82d" },
  { name: "Download-fromCloud", icon: "\e82e" },
  { name: "Download-Window", icon: "\e82f" },
  { name: "Download", icon: "\e830" },
  { name: "Downward", icon: "\e831" },
  { name: "Drag-Down", icon: "\e832" },
  { name: "Drag-Left", icon: "\e833" },
  { name: "Drag-Right", icon: "\e834" },
  { name: "Drag-Up", icon: "\e835" },
  { name: "Drag", icon: "\e836" },
  { name: "Dress", icon: "\e837" },
  { name: "Drill-2", icon: "\e838" },
  { name: "Drill", icon: "\e839" },
  { name: "Drop", icon: "\e83a" },
  { name: "Dropbox", icon: "\e83b" },
  { name: "Drum", icon: "\e83c" },
  { name: "Dry", icon: "\e83d" },
  { name: "Duck", icon: "\e83e" },
  { name: "Dumbbell", icon: "\e83f" },
  { name: "Duplicate-Layer", icon: "\e840" },
  { name: "Duplicate-Window", icon: "\e841" },
  { name: "DVD", icon: "\e842" },
  { name: "Eagle", icon: "\e843" },
  { name: "Ear", icon: "\e844" },
  { name: "Earphones-2", icon: "\e845" },
  { name: "Earphones", icon: "\e846" },
  { name: "Eci-Icon", icon: "\e847" },
  { name: "Edit-Map", icon: "\e848" },
  { name: "Edit", icon: "\e849" },
  { name: "Eggs", icon: "\e84a" },
  { name: "Egypt", icon: "\e84b" },
  { name: "Eifel-Tower", icon: "\e84c" },
  { name: "eject-2", icon: "\e84d" },
  { name: "Eject", icon: "\e84e" },
  { name: "El-Castillo", icon: "\e84f" },
  { name: "Elbow", icon: "\e850" },
  { name: "Electric-Guitar", icon: "\e851" },
  { name: "Electricity", icon: "\e852" },
  { name: "Elephant", icon: "\e853" },
  { name: "Email", icon: "\e854" },
  { name: "Embassy", icon: "\e855" },
  { name: "Empire-StateBuilding", icon: "\e856" },
  { name: "Empty-Box", icon: "\e857" },
  { name: "End2", icon: "\e858" },
  { name: "End-2", icon: "\e859" },
  { name: "End", icon: "\e85a" },
  { name: "Endways", icon: "\e85b" },
  { name: "Engineering", icon: "\e85c" },
  { name: "Envelope-2", icon: "\e85d" },
  { name: "Envelope", icon: "\e85e" },
  { name: "Environmental-2", icon: "\e85f" },
  { name: "Environmental-3", icon: "\e860" },
  { name: "Environmental", icon: "\e861" },
  { name: "Equalizer", icon: "\e862" },
  { name: "Eraser-2", icon: "\e863" },
  { name: "Eraser-3", icon: "\e864" },
  { name: "Eraser", icon: "\e865" },
  { name: "Error-404Window", icon: "\e866" },
  { name: "Euro-Sign", icon: "\e867" },
  { name: "Euro-Sign2", icon: "\e868" },
  { name: "Euro", icon: "\e869" },
  { name: "Evernote", icon: "\e86a" },
  { name: "Evil", icon: "\e86b" },
  { name: "Explode", icon: "\e86c" },
  { name: "Eye-2", icon: "\e86d" },
  { name: "Eye-Blind", icon: "\e86e" },
  { name: "Eye-Invisible", icon: "\e86f" },
  { name: "Eye-Scan", icon: "\e870" },
  { name: "Eye-Visible", icon: "\e871" },
  { name: "Eye", icon: "\e872" },
  { name: "Eyebrow-2", icon: "\e873" },
  { name: "Eyebrow-3", icon: "\e874" },
  { name: "Eyebrow", icon: "\e875" },
  { name: "Eyeglasses-Smiley", icon: "\e876" },
  { name: "Eyeglasses-Smiley2", icon: "\e877" },
  { name: "Face-Style", icon: "\e878" },
  { name: "Face-Style2", icon: "\e879" },
  { name: "Face-Style3", icon: "\e87a" },
  { name: "Face-Style4", icon: "\e87b" },
  { name: "Face-Style5", icon: "\e87c" },
  { name: "Face-Style6", icon: "\e87d" },
  { name: "Facebook-2", icon: "\e87e" },
  { name: "Facebook", icon: "\e87f" },
  { name: "Factory-2", icon: "\e880" },
  { name: "Factory", icon: "\e881" },
  { name: "Fahrenheit", icon: "\e882" },
  { name: "Family-Sign", icon: "\e883" },
  { name: "Fan", icon: "\e884" },
  { name: "Farmer", icon: "\e885" },
  { name: "Fashion", icon: "\e886" },
  { name: "Favorite-Window", icon: "\e887" },
  { name: "Fax", icon: "\e888" },
  { name: "Feather", icon: "\e889" },
  { name: "Feedburner", icon: "\e88a" },
  { name: "Female-2", icon: "\e88b" },
  { name: "Female-Sign", icon: "\e88c" },
  { name: "Female", icon: "\e88d" },
  { name: "File-Block", icon: "\e88e" },
  { name: "File-Bookmark", icon: "\e88f" },
  { name: "File-Chart", icon: "\e890" },
  { name: "File-Clipboard", icon: "\e891" },
  { name: "File-ClipboardFileText", icon: "\e892" },
  { name: "File-ClipboardTextImage", icon: "\e893" },
  { name: "File-Cloud", icon: "\e894" },
  { name: "File-Copy", icon: "\e895" },
  { name: "File-Copy2", icon: "\e896" },
  { name: "File-CSV", icon: "\e897" },
  { name: "File-Download", icon: "\e898" },
  { name: "File-Edit", icon: "\e899" },
  { name: "File-Excel", icon: "\e89a" },
  { name: "File-Favorite", icon: "\e89b" },
  { name: "File-Fire", icon: "\e89c" },
  { name: "File-Graph", icon: "\e89d" },
  { name: "File-Hide", icon: "\e89e" },
  { name: "File-Horizontal", icon: "\e89f" },
  { name: "File-HorizontalText", icon: "\e8a0" },
  { name: "File-HTML", icon: "\e8a1" },
  { name: "File-JPG", icon: "\e8a2" },
  { name: "File-Link", icon: "\e8a3" },
  { name: "File-Loading", icon: "\e8a4" },
  { name: "File-Lock", icon: "\e8a5" },
  { name: "File-Love", icon: "\e8a6" },
  { name: "File-Music", icon: "\e8a7" },
  { name: "File-Network", icon: "\e8a8" },
  { name: "File-Pictures", icon: "\e8a9" },
  { name: "File-Pie", icon: "\e8aa" },
  { name: "File-Presentation", icon: "\e8ab" },
  { name: "File-Refresh", icon: "\e8ac" },
  { name: "File-Search", icon: "\e8ad" },
  { name: "File-Settings", icon: "\e8ae" },
  { name: "File-Share", icon: "\e8af" },
  { name: "File-TextImage", icon: "\e8b0" },
  { name: "File-Trash", icon: "\e8b1" },
  { name: "File-TXT", icon: "\e8b2" },
  { name: "File-Upload", icon: "\e8b3" },
  { name: "File-Video", icon: "\e8b4" },
  { name: "File-Word", icon: "\e8b5" },
  { name: "File-Zip", icon: "\e8b6" },
  { name: "File", icon: "\e8b7" },
  { name: "Files", icon: "\e8b8" },
  { name: "Film-Board", icon: "\e8b9" },
  { name: "Film-Cartridge", icon: "\e8ba" },
  { name: "Film-Strip", icon: "\e8bb" },
  { name: "Film-Video", icon: "\e8bc" },
  { name: "Film", icon: "\e8bd" },
  { name: "Filter-2", icon: "\e8be" },
  { name: "Filter", icon: "\e8bf" },
  { name: "Financial", icon: "\e8c0" },
  { name: "Find-User", icon: "\e8c1" },
  { name: "Finger-DragFourSides", icon: "\e8c2" },
  { name: "Finger-DragTwoSides", icon: "\e8c3" },
  { name: "Finger-Print", icon: "\e8c4" },
  { name: "Finger", icon: "\e8c5" },
  { name: "Fingerprint-2", icon: "\e8c6" },
  { name: "Fingerprint", icon: "\e8c7" },
  { name: "Fire-Flame", icon: "\e8c8" },
  { name: "Fire-Flame2", icon: "\e8c9" },
  { name: "Fire-Hydrant", icon: "\e8ca" },
  { name: "Fire-Staion", icon: "\e8cb" },
  { name: "Firefox", icon: "\e8cc" },
  { name: "Firewall", icon: "\e8cd" },
  { name: "First-Aid", icon: "\e8ce" },
  { name: "First", icon: "\e8cf" },
  { name: "Fish-Food", icon: "\e8d0" },
  { name: "Fish", icon: "\e8d1" },
  { name: "Fit-To", icon: "\e8d2" },
  { name: "Fit-To2", icon: "\e8d3" },
  { name: "Five-Fingers", icon: "\e8d4" },
  { name: "Five-FingersDrag", icon: "\e8d5" },
  { name: "Five-FingersDrag2", icon: "\e8d6" },
  { name: "Five-FingersTouch", icon: "\e8d7" },
  { name: "Flag-2", icon: "\e8d8" },
  { name: "Flag-3", icon: "\e8d9" },
  { name: "Flag-4", icon: "\e8da" },
  { name: "Flag-5", icon: "\e8db" },
  { name: "Flag-6", icon: "\e8dc" },
  { name: "Flag", icon: "\e8dd" },
  { name: "Flamingo", icon: "\e8de" },
  { name: "Flash-2", icon: "\e8df" },
  { name: "Flash-Video", icon: "\e8e0" },
  { name: "Flash", icon: "\e8e1" },
  { name: "Flashlight", icon: "\e8e2" },
  { name: "Flask-2", icon: "\e8e3" },
  { name: "Flask", icon: "\e8e4" },
  { name: "Flick", icon: "\e8e5" },
  { name: "Flickr", icon: "\e8e6" },
  { name: "Flowerpot", icon: "\e8e7" },
  { name: "Fluorescent", icon: "\e8e8" },
  { name: "Fog-Day", icon: "\e8e9" },
  { name: "Fog-Night", icon: "\e8ea" },
  { name: "Folder-Add", icon: "\e8eb" },
  { name: "Folder-Archive", icon: "\e8ec" },
  { name: "Folder-Binder", icon: "\e8ed" },
  { name: "Folder-Binder2", icon: "\e8ee" },
  { name: "Folder-Block", icon: "\e8ef" },
  { name: "Folder-Bookmark", icon: "\e8f0" },
  { name: "Folder-Close", icon: "\e8f1" },
  { name: "Folder-Cloud", icon: "\e8f2" },
  { name: "Folder-Delete", icon: "\e8f3" },
  { name: "Folder-Download", icon: "\e8f4" },
  { name: "Folder-Edit", icon: "\e8f5" },
  { name: "Folder-Favorite", icon: "\e8f6" },
  { name: "Folder-Fire", icon: "\e8f7" },
  { name: "Folder-Hide", icon: "\e8f8" },
  { name: "Folder-Link", icon: "\e8f9" },
  { name: "Folder-Loading", icon: "\e8fa" },
  { name: "Folder-Lock", icon: "\e8fb" },
  { name: "Folder-Love", icon: "\e8fc" },
  { name: "Folder-Music", icon: "\e8fd" },
  { name: "Folder-Network", icon: "\e8fe" },
  { name: "Folder-Open", icon: "\e8ff" },
  { name: "Folder-Open2", icon: "\e900" },
  { name: "Folder-Organizing", icon: "\e901" },
  { name: "Folder-Pictures", icon: "\e902" },
  { name: "Folder-Refresh", icon: "\e903" },
  { name: "Folder-Remove-", icon: "\e904" },
  { name: "Folder-Search", icon: "\e905" },
  { name: "Folder-Settings", icon: "\e906" },
  { name: "Folder-Share", icon: "\e907" },
  { name: "Folder-Trash", icon: "\e908" },
  { name: "Folder-Upload", icon: "\e909" },
  { name: "Folder-Video", icon: "\e90a" },
  { name: "Folder-WithDocument", icon: "\e90b" },
  { name: "Folder-Zip", icon: "\e90c" },
  { name: "Folder", icon: "\e90d" },
  { name: "Folders", icon: "\e90e" },
  { name: "Font-Color", icon: "\e90f" },
  { name: "Font-Name", icon: "\e910" },
  { name: "Font-Size", icon: "\e911" },
  { name: "Font-Style", icon: "\e912" },
  { name: "Font-StyleSubscript", icon: "\e913" },
  { name: "Font-StyleSuperscript", icon: "\e914" },
  { name: "Font-Window", icon: "\e915" },
  { name: "Foot-2", icon: "\e916" },
  { name: "Foot", icon: "\e917" },
  { name: "Football-2", icon: "\e918" },
  { name: "Football", icon: "\e919" },
  { name: "Footprint-2", icon: "\e91a" },
  { name: "Footprint-3", icon: "\e91b" },
  { name: "Footprint", icon: "\e91c" },
  { name: "Forest", icon: "\e91d" },
  { name: "Fork", icon: "\e91e" },
  { name: "Formspring", icon: "\e91f" },
  { name: "Formula", icon: "\e920" },
  { name: "Forsquare", icon: "\e921" },
  { name: "Forward", icon: "\e922" },
  { name: "Fountain-Pen", icon: "\e923" },
  { name: "Four-Fingers", icon: "\e924" },
  { name: "Four-FingersDrag", icon: "\e925" },
  { name: "Four-FingersDrag2", icon: "\e926" },
  { name: "Four-FingersTouch", icon: "\e927" },
  { name: "Fox", icon: "\e928" },
  { name: "Frankenstein", icon: "\e929" },
  { name: "French-Fries", icon: "\e92a" },
  { name: "Friendfeed", icon: "\e92b" },
  { name: "Friendster", icon: "\e92c" },
  { name: "Frog", icon: "\e92d" },
  { name: "Fruits", icon: "\e92e" },
  { name: "Fuel", icon: "\e92f" },
  { name: "Full-Bag", icon: "\e930" },
  { name: "Full-Basket", icon: "\e931" },
  { name: "Full-Cart", icon: "\e932" },
  { name: "Full-Moon", icon: "\e933" },
  { name: "Full-Screen", icon: "\e934" },
  { name: "Full-Screen2", icon: "\e935" },
  { name: "Full-View", icon: "\e936" },
  { name: "Full-View2", icon: "\e937" },
  { name: "Full-ViewWindow", icon: "\e938" },
  { name: "Function", icon: "\e939" },
  { name: "Funky", icon: "\e93a" },
  { name: "Funny-Bicycle", icon: "\e93b" },
  { name: "Furl", icon: "\e93c" },
  { name: "Gamepad-2", icon: "\e93d" },
  { name: "Gamepad", icon: "\e93e" },
  { name: "Gas-Pump", icon: "\e93f" },
  { name: "Gaugage-2", icon: "\e940" },
  { name: "Gaugage", icon: "\e941" },
  { name: "Gay", icon: "\e942" },
  { name: "Gear-2", icon: "\e943" },
  { name: "Gear", icon: "\e944" },
  { name: "Gears-2", icon: "\e945" },
  { name: "Gears", icon: "\e946" },
  { name: "Geek-2", icon: "\e947" },
  { name: "Geek", icon: "\e948" },
  { name: "Gemini-2", icon: "\e949" },
  { name: "Gemini", icon: "\e94a" },
  { name: "Genius", icon: "\e94b" },
  { name: "Gentleman", icon: "\e94c" },
  { name: "Geo--", icon: "\e94d" },
  { name: "Geo-", icon: "\e94e" },
  { name: "Geo-Close", icon: "\e94f" },
  { name: "Geo-Love", icon: "\e950" },
  { name: "Geo-Number", icon: "\e951" },
  { name: "Geo-Star", icon: "\e952" },
  { name: "Geo", icon: "\e953" },
  { name: "Geo2--", icon: "\e954" },
  { name: "Geo2-", icon: "\e955" },
  { name: "Geo2-Close", icon: "\e956" },
  { name: "Geo2-Love", icon: "\e957" },
  { name: "Geo2-Number", icon: "\e958" },
  { name: "Geo2-Star", icon: "\e959" },
  { name: "Geo2", icon: "\e95a" },
  { name: "Geo3--", icon: "\e95b" },
  { name: "Geo3-", icon: "\e95c" },
  { name: "Geo3-Close", icon: "\e95d" },
  { name: "Geo3-Love", icon: "\e95e" },
  { name: "Geo3-Number", icon: "\e95f" },
  { name: "Geo3-Star", icon: "\e960" },
  { name: "Geo3", icon: "\e961" },
  { name: "Gey", icon: "\e962" },
  { name: "Gift-Box", icon: "\e963" },
  { name: "Giraffe", icon: "\e964" },
  { name: "Girl", icon: "\e965" },
  { name: "Glass-Water", icon: "\e966" },
  { name: "Glasses-2", icon: "\e967" },
  { name: "Glasses-3", icon: "\e968" },
  { name: "Glasses", icon: "\e969" },
  { name: "Global-Position", icon: "\e96a" },
  { name: "Globe-2", icon: "\e96b" },
  { name: "Globe", icon: "\e96c" },
  { name: "Gloves", icon: "\e96d" },
  { name: "Go-Bottom", icon: "\e96e" },
  { name: "Go-Top", icon: "\e96f" },
  { name: "Goggles", icon: "\e970" },
  { name: "Golf-2", icon: "\e971" },
  { name: "Golf", icon: "\e972" },
  { name: "Google-Buzz", icon: "\e973" },
  { name: "Google-Drive", icon: "\e974" },
  { name: "Google-Play", icon: "\e975" },
  { name: "Google-Plus", icon: "\e976" },
  { name: "Google", icon: "\e977" },
  { name: "Gopro", icon: "\e978" },
  { name: "Gorilla", icon: "\e979" },
  { name: "Gowalla", icon: "\e97a" },
  { name: "Grave", icon: "\e97b" },
  { name: "Graveyard", icon: "\e97c" },
  { name: "Greece", icon: "\e97d" },
  { name: "Green-Energy", icon: "\e97e" },
  { name: "Green-House", icon: "\e97f" },
  { name: "Guitar", icon: "\e980" },
  { name: "Gun-2", icon: "\e981" },
  { name: "Gun-3", icon: "\e982" },
  { name: "Gun", icon: "\e983" },
  { name: "Gymnastics", icon: "\e984" },
  { name: "Hair-2", icon: "\e985" },
  { name: "Hair-3", icon: "\e986" },
  { name: "Hair-4", icon: "\e987" },
  { name: "Hair", icon: "\e988" },
  { name: "Half-Moon", icon: "\e989" },
  { name: "Halloween-HalfMoon", icon: "\e98a" },
  { name: "Halloween-Moon", icon: "\e98b" },
  { name: "Hamburger", icon: "\e98c" },
  { name: "Hammer", icon: "\e98d" },
  { name: "Hand-Touch", icon: "\e98e" },
  { name: "Hand-Touch2", icon: "\e98f" },
  { name: "Hand-TouchSmartphone", icon: "\e990" },
  { name: "Hand", icon: "\e991" },
  { name: "Hands", icon: "\e992" },
  { name: "Handshake", icon: "\e993" },
  { name: "Hanger", icon: "\e994" },
  { name: "Happy", icon: "\e995" },
  { name: "Hat-2", icon: "\e996" },
  { name: "Hat", icon: "\e997" },
  { name: "Haunted-House", icon: "\e998" },
  { name: "HD-Video", icon: "\e999" },
  { name: "HD", icon: "\e99a" },
  { name: "HDD", icon: "\e99b" },
  { name: "Headphone", icon: "\e99c" },
  { name: "Headphones", icon: "\e99d" },
  { name: "Headset", icon: "\e99e" },
  { name: "Heart-2", icon: "\e99f" },
  { name: "Heart", icon: "\e9a0" },
  { name: "Heels-2", icon: "\e9a1" },
  { name: "Heels", icon: "\e9a2" },
  { name: "Height-Window", icon: "\e9a3" },
  { name: "Helicopter-2", icon: "\e9a4" },
  { name: "Helicopter", icon: "\e9a5" },
  { name: "Helix-2", icon: "\e9a6" },
  { name: "Hello", icon: "\e9a7" },
  { name: "Helmet-2", icon: "\e9a8" },
  { name: "Helmet-3", icon: "\e9a9" },
  { name: "Helmet", icon: "\e9aa" },
  { name: "Hipo", icon: "\e9ab" },
  { name: "Hipster-Glasses", icon: "\e9ac" },
  { name: "Hipster-Glasses2", icon: "\e9ad" },
  { name: "Hipster-Glasses3", icon: "\e9ae" },
  { name: "Hipster-Headphones", icon: "\e9af" },
  { name: "Hipster-Men", icon: "\e9b0" },
  { name: "Hipster-Men2", icon: "\e9b1" },
  { name: "Hipster-Men3", icon: "\e9b2" },
  { name: "Hipster-Sunglasses", icon: "\e9b3" },
  { name: "Hipster-Sunglasses2", icon: "\e9b4" },
  { name: "Hipster-Sunglasses3", icon: "\e9b5" },
  { name: "Hokey", icon: "\e9b6" },
  { name: "Holly", icon: "\e9b7" },
  { name: "Home-2", icon: "\e9b8" },
  { name: "Home-3", icon: "\e9b9" },
  { name: "Home-4", icon: "\e9ba" },
  { name: "Home-5", icon: "\e9bb" },
  { name: "Home-Window", icon: "\e9bc" },
  { name: "Home", icon: "\e9bd" },
  { name: "Homosexual", icon: "\e9be" },
  { name: "Honey", icon: "\e9bf" },
  { name: "Hong-Kong", icon: "\e9c0" },
  { name: "Hoodie", icon: "\e9c1" },
  { name: "Horror", icon: "\e9c2" },
  { name: "Horse", icon: "\e9c3" },
  { name: "Hospital-2", icon: "\e9c4" },
  { name: "Hospital", icon: "\e9c5" },
  { name: "Host", icon: "\e9c6" },
  { name: "Hot-Dog", icon: "\e9c7" },
  { name: "Hotel", icon: "\e9c8" },
  { name: "Hour", icon: "\e9c9" },
  { name: "Hub", icon: "\e9ca" },
  { name: "Humor", icon: "\e9cb" },
  { name: "Hurt", icon: "\e9cc" },
  { name: "Ice-Cream", icon: "\e9cd" },
  { name: "ICQ", icon: "\e9ce" },
  { name: "ID-2", icon: "\e9cf" },
  { name: "ID-3", icon: "\e9d0" },
  { name: "ID-Card", icon: "\e9d1" },
  { name: "Idea-2", icon: "\e9d2" },
  { name: "Idea-3", icon: "\e9d3" },
  { name: "Idea-4", icon: "\e9d4" },
  { name: "Idea-5", icon: "\e9d5" },
  { name: "Idea", icon: "\e9d6" },
  { name: "Identification-Badge", icon: "\e9d7" },
  { name: "ImDB", icon: "\e9d8" },
  { name: "Inbox-Empty", icon: "\e9d9" },
  { name: "Inbox-Forward", icon: "\e9da" },
  { name: "Inbox-Full", icon: "\e9db" },
  { name: "Inbox-Into", icon: "\e9dc" },
  { name: "Inbox-Out", icon: "\e9dd" },
  { name: "Inbox-Reply", icon: "\e9de" },
  { name: "Inbox", icon: "\e9df" },
  { name: "Increase-Inedit", icon: "\e9e0" },
  { name: "Indent-FirstLine", icon: "\e9e1" },
  { name: "Indent-LeftMargin", icon: "\e9e2" },
  { name: "Indent-RightMargin", icon: "\e9e3" },
  { name: "India", icon: "\e9e4" },
  { name: "Info-Window", icon: "\e9e5" },
  { name: "Information", icon: "\e9e6" },
  { name: "Inifity", icon: "\e9e7" },
  { name: "Instagram", icon: "\e9e8" },
  { name: "Internet-2", icon: "\e9e9" },
  { name: "Internet-Explorer", icon: "\e9ea" },
  { name: "Internet-Smiley", icon: "\e9eb" },
  { name: "Internet", icon: "\e9ec" },
  { name: "iOS-Apple", icon: "\e9ed" },
  { name: "Israel", icon: "\e9ee" },
  { name: "Italic-Text", icon: "\e9ef" },
  { name: "Jacket-2", icon: "\e9f0" },
  { name: "Jacket", icon: "\e9f1" },
  { name: "Jamaica", icon: "\e9f2" },
  { name: "Japan", icon: "\e9f3" },
  { name: "Japanese-Gate", icon: "\e9f4" },
  { name: "Jeans", icon: "\e9f5" },
  { name: "Jeep-2", icon: "\e9f6" },
  { name: "Jeep", icon: "\e9f7" },
  { name: "Jet", icon: "\e9f8" },
  { name: "Joystick", icon: "\e9f9" },
  { name: "Juice", icon: "\e9fa" },
  { name: "Jump-Rope", icon: "\e9fb" },
  { name: "Kangoroo", icon: "\e9fc" },
  { name: "Kenya", icon: "\e9fd" },
  { name: "Key-2", icon: "\e9fe" },
  { name: "Key-3", icon: "\e9ff" },
  { name: "Key-Lock", icon: "\ea00" },
  { name: "Key", icon: "\ea01" },
  { name: "Keyboard", icon: "\ea02" },
  { name: "Keyboard3", icon: "\ea03" },
  { name: "Keypad", icon: "\ea04" },
  { name: "King-2", icon: "\ea05" },
  { name: "King", icon: "\ea06" },
  { name: "Kiss", icon: "\ea07" },
  { name: "Knee", icon: "\ea08" },
  { name: "Knife-2", icon: "\ea09" },
  { name: "Knife", icon: "\ea0a" },
  { name: "Knight", icon: "\ea0b" },
  { name: "Koala", icon: "\ea0c" },
  { name: "Korea", icon: "\ea0d" },
  { name: "Lamp", icon: "\ea0e" },
  { name: "Landscape-2", icon: "\ea0f" },
  { name: "Landscape", icon: "\ea10" },
  { name: "Lantern", icon: "\ea11" },
  { name: "Laptop-2", icon: "\ea12" },
  { name: "Laptop-3", icon: "\ea13" },
  { name: "Laptop-Phone", icon: "\ea14" },
  { name: "Laptop-Secure", icon: "\ea15" },
  { name: "Laptop-Tablet", icon: "\ea16" },
  { name: "Laptop", icon: "\ea17" },
  { name: "Laser", icon: "\ea18" },
  { name: "Last-FM", icon: "\ea19" },
  { name: "Last", icon: "\ea1a" },
  { name: "Laughing", icon: "\ea1b" },
  { name: "Layer-1635", icon: "\ea1c" },
  { name: "Layer-1646", icon: "\ea1d" },
  { name: "Layer-Backward", icon: "\ea1e" },
  { name: "Layer-Forward", icon: "\ea1f" },
  { name: "Leafs-2", icon: "\ea20" },
  { name: "Leafs", icon: "\ea21" },
  { name: "Leaning-Tower", icon: "\ea22" },
  { name: "Left--Right", icon: "\ea23" },
  { name: "Left--Right3", icon: "\ea24" },
  { name: "Left-2", icon: "\ea25" },
  { name: "Left-3", icon: "\ea26" },
  { name: "Left-4", icon: "\ea27" },
  { name: "Left-ToRight", icon: "\ea28" },
  { name: "Left", icon: "\ea29" },
  { name: "Leg-2", icon: "\ea2a" },
  { name: "Leg", icon: "\ea2b" },
  { name: "Lego", icon: "\ea2c" },
  { name: "Lemon", icon: "\ea2d" },
  { name: "Len-2", icon: "\ea2e" },
  { name: "Len-3", icon: "\ea2f" },
  { name: "Len", icon: "\ea30" },
  { name: "Leo-2", icon: "\ea31" },
  { name: "Leo", icon: "\ea32" },
  { name: "Leopard", icon: "\ea33" },
  { name: "Lesbian", icon: "\ea34" },
  { name: "Lesbians", icon: "\ea35" },
  { name: "Letter-Close", icon: "\ea36" },
  { name: "Letter-Open", icon: "\ea37" },
  { name: "Letter-Sent", icon: "\ea38" },
  { name: "Libra-2", icon: "\ea39" },
  { name: "Libra", icon: "\ea3a" },
  { name: "Library-2", icon: "\ea3b" },
  { name: "Library", icon: "\ea3c" },
  { name: "Life-Jacket", icon: "\ea3d" },
  { name: "Life-Safer", icon: "\ea3e" },
  { name: "Light-Bulb", icon: "\ea3f" },
  { name: "Light-Bulb2", icon: "\ea40" },
  { name: "Light-BulbLeaf", icon: "\ea41" },
  { name: "Lighthouse", icon: "\ea42" },
  { name: "Like-2", icon: "\ea43" },
  { name: "Like", icon: "\ea44" },
  { name: "Line-Chart", icon: "\ea45" },
  { name: "Line-Chart2", icon: "\ea46" },
  { name: "Line-Chart3", icon: "\ea47" },
  { name: "Line-Chart4", icon: "\ea48" },
  { name: "Line-Spacing", icon: "\ea49" },
  { name: "Line-SpacingText", icon: "\ea4a" },
  { name: "Link-2", icon: "\ea4b" },
  { name: "Link", icon: "\ea4c" },
  { name: "Linkedin-2", icon: "\ea4d" },
  { name: "Linkedin", icon: "\ea4e" },
  { name: "Linux", icon: "\ea4f" },
  { name: "Lion", icon: "\ea50" },
  { name: "Livejournal", icon: "\ea51" },
  { name: "Loading-2", icon: "\ea52" },
  { name: "Loading-3", icon: "\ea53" },
  { name: "Loading-Window", icon: "\ea54" },
  { name: "Loading", icon: "\ea55" },
  { name: "Location-2", icon: "\ea56" },
  { name: "Location", icon: "\ea57" },
  { name: "Lock-2", icon: "\ea58" },
  { name: "Lock-3", icon: "\ea59" },
  { name: "Lock-User", icon: "\ea5a" },
  { name: "Lock-Window", icon: "\ea5b" },
  { name: "Lock", icon: "\ea5c" },
  { name: "Lollipop-2", icon: "\ea5d" },
  { name: "Lollipop-3", icon: "\ea5e" },
  { name: "Lollipop", icon: "\ea5f" },
  { name: "Loop", icon: "\ea60" },
  { name: "Loud", icon: "\ea61" },
  { name: "Loudspeaker", icon: "\ea62" },
  { name: "Love-2", icon: "\ea63" },
  { name: "Love-User", icon: "\ea64" },
  { name: "Love-Window", icon: "\ea65" },
  { name: "Love", icon: "\ea66" },
  { name: "Lowercase-Text", icon: "\ea67" },
  { name: "Luggafe-Front", icon: "\ea68" },
  { name: "Luggage-2", icon: "\ea69" },
  { name: "Macro", icon: "\ea6a" },
  { name: "Magic-Wand", icon: "\ea6b" },
  { name: "Magnet", icon: "\ea6c" },
  { name: "Magnifi-Glass-", icon: "\ea6d" },
  { name: "Magnifi-Glass", icon: "\ea6e" },
  { name: "Magnifi-Glass2", icon: "\ea6f" },
  { name: "Mail-2", icon: "\ea70" },
  { name: "Mail-3", icon: "\ea71" },
  { name: "Mail-Add", icon: "\ea72" },
  { name: "Mail-Attachement", icon: "\ea73" },
  { name: "Mail-Block", icon: "\ea74" },
  { name: "Mail-Delete", icon: "\ea75" },
  { name: "Mail-Favorite", icon: "\ea76" },
  { name: "Mail-Forward", icon: "\ea77" },
  { name: "Mail-Gallery", icon: "\ea78" },
  { name: "Mail-Inbox", icon: "\ea79" },
  { name: "Mail-Link", icon: "\ea7a" },
  { name: "Mail-Lock", icon: "\ea7b" },
  { name: "Mail-Love", icon: "\ea7c" },
  { name: "Mail-Money", icon: "\ea7d" },
  { name: "Mail-Open", icon: "\ea7e" },
  { name: "Mail-Outbox", icon: "\ea7f" },
  { name: "Mail-Password", icon: "\ea80" },
  { name: "Mail-Photo", icon: "\ea81" },
  { name: "Mail-Read", icon: "\ea82" },
  { name: "Mail-Removex", icon: "\ea83" },
  { name: "Mail-Reply", icon: "\ea84" },
  { name: "Mail-ReplyAll", icon: "\ea85" },
  { name: "Mail-Search", icon: "\ea86" },
  { name: "Mail-Send", icon: "\ea87" },
  { name: "Mail-Settings", icon: "\ea88" },
  { name: "Mail-Unread", icon: "\ea89" },
  { name: "Mail-Video", icon: "\ea8a" },
  { name: "Mail-withAtSign", icon: "\ea8b" },
  { name: "Mail-WithCursors", icon: "\ea8c" },
  { name: "Mail", icon: "\ea8d" },
  { name: "Mailbox-Empty", icon: "\ea8e" },
  { name: "Mailbox-Full", icon: "\ea8f" },
  { name: "Male-2", icon: "\ea90" },
  { name: "Male-Sign", icon: "\ea91" },
  { name: "Male", icon: "\ea92" },
  { name: "MaleFemale", icon: "\ea93" },
  { name: "Man-Sign", icon: "\ea94" },
  { name: "Management", icon: "\ea95" },
  { name: "Mans-Underwear", icon: "\ea96" },
  { name: "Mans-Underwear2", icon: "\ea97" },
  { name: "Map-Marker", icon: "\ea98" },
  { name: "Map-Marker2", icon: "\ea99" },
  { name: "Map-Marker3", icon: "\ea9a" },
  { name: "Map", icon: "\ea9b" },
  { name: "Map2", icon: "\ea9c" },
  { name: "Marker-2", icon: "\ea9d" },
  { name: "Marker-3", icon: "\ea9e" },
  { name: "Marker", icon: "\ea9f" },
  { name: "Martini-Glass", icon: "\eaa0" },
  { name: "Mask", icon: "\eaa1" },
  { name: "Master-Card", icon: "\eaa2" },
  { name: "Maximize-Window", icon: "\eaa3" },
  { name: "Maximize", icon: "\eaa4" },
  { name: "Medal-2", icon: "\eaa5" },
  { name: "Medal-3", icon: "\eaa6" },
  { name: "Medal", icon: "\eaa7" },
  { name: "Medical-Sign", icon: "\eaa8" },
  { name: "Medicine-2", icon: "\eaa9" },
  { name: "Medicine-3", icon: "\eaaa" },
  { name: "Medicine", icon: "\eaab" },
  { name: "Megaphone", icon: "\eaac" },
  { name: "Memory-Card", icon: "\eaad" },
  { name: "Memory-Card2", icon: "\eaae" },
  { name: "Memory-Card3", icon: "\eaaf" },
  { name: "Men", icon: "\eab0" },
  { name: "Menorah", icon: "\eab1" },
  { name: "Mens", icon: "\eab2" },
  { name: "Metacafe", icon: "\eab3" },
  { name: "Mexico", icon: "\eab4" },
  { name: "Mic", icon: "\eab5" },
  { name: "Microphone-2", icon: "\eab6" },
  { name: "Microphone-3", icon: "\eab7" },
  { name: "Microphone-4", icon: "\eab8" },
  { name: "Microphone-5", icon: "\eab9" },
  { name: "Microphone-6", icon: "\eaba" },
  { name: "Microphone-7", icon: "\eabb" },
  { name: "Microphone", icon: "\eabc" },
  { name: "Microscope", icon: "\eabd" },
  { name: "Milk-Bottle", icon: "\eabe" },
  { name: "Mine", icon: "\eabf" },
  { name: "Minimize-Maximize-Close-Window", icon: "\eac0" },
  { name: "Minimize-Window", icon: "\eac1" },
  { name: "Minimize", icon: "\eac2" },
  { name: "Mirror", icon: "\eac3" },
  { name: "Mixer", icon: "\eac4" },
  { name: "Mixx", icon: "\eac5" },
  { name: "Money-2", icon: "\eac6" },
  { name: "Money-Bag", icon: "\eac7" },
  { name: "Money-Smiley", icon: "\eac8" },
  { name: "Money", icon: "\eac9" },
  { name: "Monitor-2", icon: "\eaca" },
  { name: "Monitor-3", icon: "\eacb" },
  { name: "Monitor-4", icon: "\eacc" },
  { name: "Monitor-5", icon: "\eacd" },
  { name: "Monitor-Analytics", icon: "\eace" },
  { name: "Monitor-Laptop", icon: "\eacf" },
  { name: "Monitor-phone", icon: "\ead0" },
  { name: "Monitor-Tablet", icon: "\ead1" },
  { name: "Monitor-Vertical", icon: "\ead2" },
  { name: "Monitor", icon: "\ead3" },
  { name: "Monitoring", icon: "\ead4" },
  { name: "Monkey", icon: "\ead5" },
  { name: "Monster", icon: "\ead6" },
  { name: "Morocco", icon: "\ead7" },
  { name: "Motorcycle", icon: "\ead8" },
  { name: "Mouse-2", icon: "\ead9" },
  { name: "Mouse-3", icon: "\eada" },
  { name: "Mouse-4", icon: "\eadb" },
  { name: "Mouse-Pointer", icon: "\eadc" },
  { name: "Mouse", icon: "\eadd" },
  { name: "Moustache-Smiley", icon: "\eade" },
  { name: "Movie-Ticket", icon: "\eadf" },
  { name: "Movie", icon: "\eae0" },
  { name: "Mp3-File", icon: "\eae1" },
  { name: "Museum", icon: "\eae2" },
  { name: "Mushroom", icon: "\eae3" },
  { name: "Music-Note", icon: "\eae4" },
  { name: "Music-Note2", icon: "\eae5" },
  { name: "Music-Note3", icon: "\eae6" },
  { name: "Music-Note4", icon: "\eae7" },
  { name: "Music-Player", icon: "\eae8" },
  { name: "Mustache-2", icon: "\eae9" },
  { name: "Mustache-3", icon: "\eaea" },
  { name: "Mustache-4", icon: "\eaeb" },
  { name: "Mustache-5", icon: "\eaec" },
  { name: "Mustache-6", icon: "\eaed" },
  { name: "Mustache-7", icon: "\eaee" },
  { name: "Mustache-8", icon: "\eaef" },
  { name: "Mustache", icon: "\eaf0" },
  { name: "Mute", icon: "\eaf1" },
  { name: "Myspace", icon: "\eaf2" },
  { name: "Navigat-Start", icon: "\eaf3" },
  { name: "Navigate-End", icon: "\eaf4" },
  { name: "Navigation-LeftWindow", icon: "\eaf5" },
  { name: "Navigation-RightWindow", icon: "\eaf6" },
  { name: "Nepal", icon: "\eaf7" },
  { name: "Netscape", icon: "\eaf8" },
  { name: "Network-Window", icon: "\eaf9" },
  { name: "Network", icon: "\eafa" },
  { name: "Neutron", icon: "\eafb" },
  { name: "New-Mail", icon: "\eafc" },
  { name: "New-Tab", icon: "\eafd" },
  { name: "Newspaper-2", icon: "\eafe" },
  { name: "Newspaper", icon: "\eaff" },
  { name: "Newsvine", icon: "\eb00" },
  { name: "Next2", icon: "\eb01" },
  { name: "Next-3", icon: "\eb02" },
  { name: "Next-Music", icon: "\eb03" },
  { name: "Next", icon: "\eb04" },
  { name: "No-Battery", icon: "\eb05" },
  { name: "No-Drop", icon: "\eb06" },
  { name: "No-Flash", icon: "\eb07" },
  { name: "No-Smoking", icon: "\eb08" },
  { name: "Noose", icon: "\eb09" },
  { name: "Normal-Text", icon: "\eb0a" },
  { name: "Note", icon: "\eb0b" },
  { name: "Notepad-2", icon: "\eb0c" },
  { name: "Notepad", icon: "\eb0d" },
  { name: "Nuclear", icon: "\eb0e" },
  { name: "Numbering-List", icon: "\eb0f" },
  { name: "Nurse", icon: "\eb10" },
  { name: "Office-Lamp", icon: "\eb11" },
  { name: "Office", icon: "\eb12" },
  { name: "Oil", icon: "\eb13" },
  { name: "Old-Camera", icon: "\eb14" },
  { name: "Old-Cassette", icon: "\eb15" },
  { name: "Old-Clock", icon: "\eb16" },
  { name: "Old-Radio", icon: "\eb17" },
  { name: "Old-Sticky", icon: "\eb18" },
  { name: "Old-Sticky2", icon: "\eb19" },
  { name: "Old-Telephone", icon: "\eb1a" },
  { name: "Old-TV", icon: "\eb1b" },
  { name: "On-Air", icon: "\eb1c" },
  { name: "On-Off-2", icon: "\eb1d" },
  { name: "On-Off-3", icon: "\eb1e" },
  { name: "On-off", icon: "\eb1f" },
  { name: "One-Finger", icon: "\eb20" },
  { name: "One-FingerTouch", icon: "\eb21" },
  { name: "One-Window", icon: "\eb22" },
  { name: "Open-Banana", icon: "\eb23" },
  { name: "Open-Book", icon: "\eb24" },
  { name: "Opera-House", icon: "\eb25" },
  { name: "Opera", icon: "\eb26" },
  { name: "Optimization", icon: "\eb27" },
  { name: "Orientation-2", icon: "\eb28" },
  { name: "Orientation-3", icon: "\eb29" },
  { name: "Orientation", icon: "\eb2a" },
  { name: "Orkut", icon: "\eb2b" },
  { name: "Ornament", icon: "\eb2c" },
  { name: "Over-Time", icon: "\eb2d" },
  { name: "Over-Time2", icon: "\eb2e" },
  { name: "Owl", icon: "\eb2f" },
  { name: "Pac-Man", icon: "\eb30" },
  { name: "Paint-Brush", icon: "\eb31" },
  { name: "Paint-Bucket", icon: "\eb32" },
  { name: "Paintbrush", icon: "\eb33" },
  { name: "Palette", icon: "\eb34" },
  { name: "Palm-Tree", icon: "\eb35" },
  { name: "Panda", icon: "\eb36" },
  { name: "Panorama", icon: "\eb37" },
  { name: "Pantheon", icon: "\eb38" },
  { name: "Pantone", icon: "\eb39" },
  { name: "Pants", icon: "\eb3a" },
  { name: "Paper-Plane", icon: "\eb3b" },
  { name: "Paper", icon: "\eb3c" },
  { name: "Parasailing", icon: "\eb3d" },
  { name: "Parrot", icon: "\eb3e" },
  { name: "Password-2shopping", icon: "\eb3f" },
  { name: "Password-Field", icon: "\eb40" },
  { name: "Password-shopping", icon: "\eb41" },
  { name: "Password", icon: "\eb42" },
  { name: "pause-2", icon: "\eb43" },
  { name: "Pause", icon: "\eb44" },
  { name: "Paw", icon: "\eb45" },
  { name: "Pawn", icon: "\eb46" },
  { name: "Paypal", icon: "\eb47" },
  { name: "Pen-2", icon: "\eb48" },
  { name: "Pen-3", icon: "\eb49" },
  { name: "Pen-4", icon: "\eb4a" },
  { name: "Pen-5", icon: "\eb4b" },
  { name: "Pen-6", icon: "\eb4c" },
  { name: "Pen", icon: "\eb4d" },
  { name: "Pencil-Ruler", icon: "\eb4e" },
  { name: "Pencil", icon: "\eb4f" },
  { name: "Penguin", icon: "\eb50" },
  { name: "Pentagon", icon: "\eb51" },
  { name: "People-onCloud", icon: "\eb52" },
  { name: "Pepper-withFire", icon: "\eb53" },
  { name: "Pepper", icon: "\eb54" },
  { name: "Petrol", icon: "\eb55" },
  { name: "Petronas-Tower", icon: "\eb56" },
  { name: "Philipines", icon: "\eb57" },
  { name: "Phone-2", icon: "\eb58" },
  { name: "Phone-3", icon: "\eb59" },
  { name: "Phone-3G", icon: "\eb5a" },
  { name: "Phone-4G", icon: "\eb5b" },
  { name: "Phone-Simcard", icon: "\eb5c" },
  { name: "Phone-SMS", icon: "\eb5d" },
  { name: "Phone-Wifi", icon: "\eb5e" },
  { name: "Phone", icon: "\eb5f" },
  { name: "Photo-2", icon: "\eb60" },
  { name: "Photo-3", icon: "\eb61" },
  { name: "Photo-Album", icon: "\eb62" },
  { name: "Photo-Album2", icon: "\eb63" },
  { name: "Photo-Album3", icon: "\eb64" },
  { name: "Photo", icon: "\eb65" },
  { name: "Photos", icon: "\eb66" },
  { name: "Physics", icon: "\eb67" },
  { name: "Pi", icon: "\eb68" },
  { name: "Piano", icon: "\eb69" },
  { name: "Picasa", icon: "\eb6a" },
  { name: "Pie-Chart", icon: "\eb6b" },
  { name: "Pie-Chart2", icon: "\eb6c" },
  { name: "Pie-Chart3", icon: "\eb6d" },
  { name: "Pilates-2", icon: "\eb6e" },
  { name: "Pilates-3", icon: "\eb6f" },
  { name: "Pilates", icon: "\eb70" },
  { name: "Pilot", icon: "\eb71" },
  { name: "Pinch", icon: "\eb72" },
  { name: "Ping-Pong", icon: "\eb73" },
  { name: "Pinterest", icon: "\eb74" },
  { name: "Pipe", icon: "\eb75" },
  { name: "Pipette", icon: "\eb76" },
  { name: "Piramids", icon: "\eb77" },
  { name: "Pisces-2", icon: "\eb78" },
  { name: "Pisces", icon: "\eb79" },
  { name: "Pizza-Slice", icon: "\eb7a" },
  { name: "Pizza", icon: "\eb7b" },
  { name: "Plane-2", icon: "\eb7c" },
  { name: "Plane", icon: "\eb7d" },
  { name: "Plant", icon: "\eb7e" },
  { name: "Plasmid", icon: "\eb7f" },
  { name: "Plaster", icon: "\eb80" },
  { name: "Plastic-CupPhone", icon: "\eb81" },
  { name: "Plastic-CupPhone2", icon: "\eb82" },
  { name: "Plate", icon: "\eb83" },
  { name: "Plates", icon: "\eb84" },
  { name: "Plaxo", icon: "\eb85" },
  { name: "Play-Music", icon: "\eb86" },
  { name: "Plug-In", icon: "\eb87" },
  { name: "Plug-In2", icon: "\eb88" },
  { name: "Plurk", icon: "\eb89" },
  { name: "Pointer", icon: "\eb8a" },
  { name: "Poland", icon: "\eb8b" },
  { name: "Police-Man", icon: "\eb8c" },
  { name: "Police-Station", icon: "\eb8d" },
  { name: "Police-Woman", icon: "\eb8e" },
  { name: "Police", icon: "\eb8f" },
  { name: "Polo-Shirt", icon: "\eb90" },
  { name: "Portrait", icon: "\eb91" },
  { name: "Portugal", icon: "\eb92" },
  { name: "Post-Mail", icon: "\eb93" },
  { name: "Post-Mail2", icon: "\eb94" },
  { name: "Post-Office", icon: "\eb95" },
  { name: "Post-Sign", icon: "\eb96" },
  { name: "Post-Sign2ways", icon: "\eb97" },
  { name: "Posterous", icon: "\eb98" },
  { name: "Pound-Sign", icon: "\eb99" },
  { name: "Pound-Sign2", icon: "\eb9a" },
  { name: "Pound", icon: "\eb9b" },
  { name: "Power-2", icon: "\eb9c" },
  { name: "Power-3", icon: "\eb9d" },
  { name: "Power-Cable", icon: "\eb9e" },
  { name: "Power-Station", icon: "\eb9f" },
  { name: "Power", icon: "\eba0" },
  { name: "Prater", icon: "\eba1" },
  { name: "Present", icon: "\eba2" },
  { name: "Presents", icon: "\eba3" },
  { name: "Press", icon: "\eba4" },
  { name: "Preview", icon: "\eba5" },
  { name: "Previous", icon: "\eba6" },
  { name: "Pricing", icon: "\eba7" },
  { name: "Printer", icon: "\eba8" },
  { name: "Professor", icon: "\eba9" },
  { name: "Profile", icon: "\ebaa" },
  { name: "Project", icon: "\ebab" },
  { name: "Projector-2", icon: "\ebac" },
  { name: "Projector", icon: "\ebad" },
  { name: "Pulse", icon: "\ebae" },
  { name: "Pumpkin", icon: "\ebaf" },
  { name: "Punk", icon: "\ebb0" },
  { name: "Punker", icon: "\ebb1" },
  { name: "Puzzle", icon: "\ebb2" },
  { name: "QIK", icon: "\ebb3" },
  { name: "QR-Code", icon: "\ebb4" },
  { name: "Queen-2", icon: "\ebb5" },
  { name: "Queen", icon: "\ebb6" },
  { name: "Quill-2", icon: "\ebb7" },
  { name: "Quill-3", icon: "\ebb8" },
  { name: "Quill", icon: "\ebb9" },
  { name: "Quotes-2", icon: "\ebba" },
  { name: "Quotes", icon: "\ebbb" },
  { name: "Radio", icon: "\ebbc" },
  { name: "Radioactive", icon: "\ebbd" },
  { name: "Rafting", icon: "\ebbe" },
  { name: "Rain-Drop", icon: "\ebbf" },
  { name: "Rainbow-2", icon: "\ebc0" },
  { name: "Rainbow", icon: "\ebc1" },
  { name: "Ram", icon: "\ebc2" },
  { name: "Razzor-Blade", icon: "\ebc3" },
  { name: "Receipt-2", icon: "\ebc4" },
  { name: "Receipt-3", icon: "\ebc5" },
  { name: "Receipt-4", icon: "\ebc6" },
  { name: "Receipt", icon: "\ebc7" },
  { name: "Record2", icon: "\ebc8" },
  { name: "Record-3", icon: "\ebc9" },
  { name: "Record-Music", icon: "\ebca" },
  { name: "Record", icon: "\ebcb" },
  { name: "Recycling-2", icon: "\ebcc" },
  { name: "Recycling", icon: "\ebcd" },
  { name: "Reddit", icon: "\ebce" },
  { name: "Redhat", icon: "\ebcf" },
  { name: "Redirect", icon: "\ebd0" },
  { name: "Redo", icon: "\ebd1" },
  { name: "Reel", icon: "\ebd2" },
  { name: "Refinery", icon: "\ebd3" },
  { name: "Refresh-Window", icon: "\ebd4" },
  { name: "Refresh", icon: "\ebd5" },
  { name: "Reload-2", icon: "\ebd6" },
  { name: "Reload-3", icon: "\ebd7" },
  { name: "Reload", icon: "\ebd8" },
  { name: "Remote-Controll", icon: "\ebd9" },
  { name: "Remote-Controll2", icon: "\ebda" },
  { name: "Remove-Bag", icon: "\ebdb" },
  { name: "Remove-Basket", icon: "\ebdc" },
  { name: "Remove-Cart", icon: "\ebdd" },
  { name: "Remove-File", icon: "\ebde" },
  { name: "Remove-User", icon: "\ebdf" },
  { name: "Remove-Window", icon: "\ebe0" },
  { name: "Remove", icon: "\ebe1" },
  { name: "Rename", icon: "\ebe2" },
  { name: "Repair", icon: "\ebe3" },
  { name: "Repeat-2", icon: "\ebe4" },
  { name: "Repeat-3", icon: "\ebe5" },
  { name: "Repeat-4", icon: "\ebe6" },
  { name: "Repeat-5", icon: "\ebe7" },
  { name: "Repeat-6", icon: "\ebe8" },
  { name: "Repeat-7", icon: "\ebe9" },
  { name: "Repeat", icon: "\ebea" },
  { name: "Reset", icon: "\ebeb" },
  { name: "Resize", icon: "\ebec" },
  { name: "Restore-Window", icon: "\ebed" },
  { name: "Retouching", icon: "\ebee" },
  { name: "Retro-Camera", icon: "\ebef" },
  { name: "Retro", icon: "\ebf0" },
  { name: "Retweet", icon: "\ebf1" },
  { name: "Reverbnation", icon: "\ebf2" },
  { name: "Rewind", icon: "\ebf3" },
  { name: "RGB", icon: "\ebf4" },
  { name: "Ribbon-2", icon: "\ebf5" },
  { name: "Ribbon-3", icon: "\ebf6" },
  { name: "Ribbon", icon: "\ebf7" },
  { name: "Right-2", icon: "\ebf8" },
  { name: "Right-3", icon: "\ebf9" },
  { name: "Right-4", icon: "\ebfa" },
  { name: "Right-ToLeft", icon: "\ebfb" },
  { name: "Right", icon: "\ebfc" },
  { name: "Road-2", icon: "\ebfd" },
  { name: "Road-3", icon: "\ebfe" },
  { name: "Road", icon: "\ebff" },
  { name: "Robot-2", icon: "\ec00" },
  { name: "Robot", icon: "\ec01" },
  { name: "Rock-andRoll", icon: "\ec02" },
  { name: "Rocket", icon: "\ec03" },
  { name: "Roller", icon: "\ec04" },
  { name: "Roof", icon: "\ec05" },
  { name: "Rook", icon: "\ec06" },
  { name: "Rotate-Gesture", icon: "\ec07" },
  { name: "Rotate-Gesture2", icon: "\ec08" },
  { name: "Rotate-Gesture3", icon: "\ec09" },
  { name: "Rotation-390", icon: "\ec0a" },
  { name: "Rotation", icon: "\ec0b" },
  { name: "Router-2", icon: "\ec0c" },
  { name: "Router", icon: "\ec0d" },
  { name: "RSS", icon: "\ec0e" },
  { name: "Ruler-2", icon: "\ec0f" },
  { name: "Ruler", icon: "\ec10" },
  { name: "Running-Shoes", icon: "\ec11" },
  { name: "Running", icon: "\ec12" },
  { name: "Safari", icon: "\ec13" },
  { name: "Safe-Box", icon: "\ec14" },
  { name: "Safe-Box2", icon: "\ec15" },
  { name: "Safety-PinClose", icon: "\ec16" },
  { name: "Safety-PinOpen", icon: "\ec17" },
  { name: "Sagittarus-2", icon: "\ec18" },
  { name: "Sagittarus", icon: "\ec19" },
  { name: "Sailing-Ship", icon: "\ec1a" },
  { name: "Sand-watch", icon: "\ec1b" },
  { name: "Sand-watch2", icon: "\ec1c" },
  { name: "Santa-Claus", icon: "\ec1d" },
  { name: "Santa-Claus2", icon: "\ec1e" },
  { name: "Santa-onSled", icon: "\ec1f" },
  { name: "Satelite-2", icon: "\ec20" },
  { name: "Satelite", icon: "\ec21" },
  { name: "Save-Window", icon: "\ec22" },
  { name: "Save", icon: "\ec23" },
  { name: "Saw", icon: "\ec24" },
  { name: "Saxophone", icon: "\ec25" },
  { name: "Scale", icon: "\ec26" },
  { name: "Scarf", icon: "\ec27" },
  { name: "Scissor", icon: "\ec28" },
  { name: "Scooter-Front", icon: "\ec29" },
  { name: "Scooter", icon: "\ec2a" },
  { name: "Scorpio-2", icon: "\ec2b" },
  { name: "Scorpio", icon: "\ec2c" },
  { name: "Scotland", icon: "\ec2d" },
  { name: "Screwdriver", icon: "\ec2e" },
  { name: "Scroll-Fast", icon: "\ec2f" },
  { name: "Scroll", icon: "\ec30" },
  { name: "Scroller-2", icon: "\ec31" },
  { name: "Scroller", icon: "\ec32" },
  { name: "Sea-Dog", icon: "\ec33" },
  { name: "Search-onCloud", icon: "\ec34" },
  { name: "Search-People", icon: "\ec35" },
  { name: "secound", icon: "\ec36" },
  { name: "secound2", icon: "\ec37" },
  { name: "Security-Block", icon: "\ec38" },
  { name: "Security-Bug", icon: "\ec39" },
  { name: "Security-Camera", icon: "\ec3a" },
  { name: "Security-Check", icon: "\ec3b" },
  { name: "Security-Settings", icon: "\ec3c" },
  { name: "Security-Smiley", icon: "\ec3d" },
  { name: "Securiy-Remove", icon: "\ec3e" },
  { name: "Seed", icon: "\ec3f" },
  { name: "Selfie", icon: "\ec40" },
  { name: "Serbia", icon: "\ec41" },
  { name: "Server-2", icon: "\ec42" },
  { name: "Server", icon: "\ec43" },
  { name: "Servers", icon: "\ec44" },
  { name: "Settings-Window", icon: "\ec45" },
  { name: "Sewing-Machine", icon: "\ec46" },
  { name: "Sexual", icon: "\ec47" },
  { name: "Share-onCloud", icon: "\ec48" },
  { name: "Share-Window", icon: "\ec49" },
  { name: "Share", icon: "\ec4a" },
  { name: "Sharethis", icon: "\ec4b" },
  { name: "Shark", icon: "\ec4c" },
  { name: "Sheep", icon: "\ec4d" },
  { name: "Sheriff-Badge", icon: "\ec4e" },
  { name: "Shield", icon: "\ec4f" },
  { name: "Ship-2", icon: "\ec50" },
  { name: "Ship", icon: "\ec51" },
  { name: "Shirt", icon: "\ec52" },
  { name: "Shoes-2", icon: "\ec53" },
  { name: "Shoes-3", icon: "\ec54" },
  { name: "Shoes", icon: "\ec55" },
  { name: "Shop-2", icon: "\ec56" },
  { name: "Shop-3", icon: "\ec57" },
  { name: "Shop-4", icon: "\ec58" },
  { name: "Shop", icon: "\ec59" },
  { name: "Shopping-Bag", icon: "\ec5a" },
  { name: "Shopping-Basket", icon: "\ec5b" },
  { name: "Shopping-Cart", icon: "\ec5c" },
  { name: "Short-Pants", icon: "\ec5d" },
  { name: "Shoutwire", icon: "\ec5e" },
  { name: "Shovel", icon: "\ec5f" },
  { name: "Shuffle-2", icon: "\ec60" },
  { name: "Shuffle-3", icon: "\ec61" },
  { name: "Shuffle-4", icon: "\ec62" },
  { name: "Shuffle", icon: "\ec63" },
  { name: "Shutter", icon: "\ec64" },
  { name: "Sidebar-Window", icon: "\ec65" },
  { name: "Signal", icon: "\ec66" },
  { name: "Singapore", icon: "\ec67" },
  { name: "Skate-Shoes", icon: "\ec68" },
  { name: "Skateboard-2", icon: "\ec69" },
  { name: "Skateboard", icon: "\ec6a" },
  { name: "Skeleton", icon: "\ec6b" },
  { name: "Ski", icon: "\ec6c" },
  { name: "Skirt", icon: "\ec6d" },
  { name: "Skrill", icon: "\ec6e" },
  { name: "Skull", icon: "\ec6f" },
  { name: "Skydiving", icon: "\ec70" },
  { name: "Skype", icon: "\ec71" },
  { name: "Sled-withGifts", icon: "\ec72" },
  { name: "Sled", icon: "\ec73" },
  { name: "Sleeping", icon: "\ec74" },
  { name: "Sleet", icon: "\ec75" },
  { name: "Slippers", icon: "\ec76" },
  { name: "Smart", icon: "\ec77" },
  { name: "Smartphone-2", icon: "\ec78" },
  { name: "Smartphone-3", icon: "\ec79" },
  { name: "Smartphone-4", icon: "\ec7a" },
  { name: "Smartphone-Secure", icon: "\ec7b" },
  { name: "Smartphone", icon: "\ec7c" },
  { name: "Smile", icon: "\ec7d" },
  { name: "Smoking-Area", icon: "\ec7e" },
  { name: "Smoking-Pipe", icon: "\ec7f" },
  { name: "Snake", icon: "\ec80" },
  { name: "Snorkel", icon: "\ec81" },
  { name: "Snow-2", icon: "\ec82" },
  { name: "Snow-Dome", icon: "\ec83" },
  { name: "Snow-Storm", icon: "\ec84" },
  { name: "Snow", icon: "\ec85" },
  { name: "Snowflake-2", icon: "\ec86" },
  { name: "Snowflake-3", icon: "\ec87" },
  { name: "Snowflake-4", icon: "\ec88" },
  { name: "Snowflake", icon: "\ec89" },
  { name: "Snowman", icon: "\ec8a" },
  { name: "Soccer-Ball", icon: "\ec8b" },
  { name: "Soccer-Shoes", icon: "\ec8c" },
  { name: "Socks", icon: "\ec8d" },
  { name: "Solar", icon: "\ec8e" },
  { name: "Sound-Wave", icon: "\ec8f" },
  { name: "Sound", icon: "\ec90" },
  { name: "Soundcloud", icon: "\ec91" },
  { name: "Soup", icon: "\ec92" },
  { name: "South-Africa", icon: "\ec93" },
  { name: "Space-Needle", icon: "\ec94" },
  { name: "Spain", icon: "\ec95" },
  { name: "Spam-Mail", icon: "\ec96" },
  { name: "Speach-Bubble", icon: "\ec97" },
  { name: "Speach-Bubble2", icon: "\ec98" },
  { name: "Speach-Bubble3", icon: "\ec99" },
  { name: "Speach-Bubble4", icon: "\ec9a" },
  { name: "Speach-Bubble5", icon: "\ec9b" },
  { name: "Speach-Bubble6", icon: "\ec9c" },
  { name: "Speach-Bubble7", icon: "\ec9d" },
  { name: "Speach-Bubble8", icon: "\ec9e" },
  { name: "Speach-Bubble9", icon: "\ec9f" },
  { name: "Speach-Bubble10", icon: "\eca0" },
  { name: "Speach-Bubble11", icon: "\eca1" },
  { name: "Speach-Bubble12", icon: "\eca2" },
  { name: "Speach-Bubble13", icon: "\eca3" },
  { name: "Speach-BubbleAsking", icon: "\eca4" },
  { name: "Speach-BubbleComic", icon: "\eca5" },
  { name: "Speach-BubbleComic2", icon: "\eca6" },
  { name: "Speach-BubbleComic3", icon: "\eca7" },
  { name: "Speach-BubbleComic4", icon: "\eca8" },
  { name: "Speach-BubbleDialog", icon: "\eca9" },
  { name: "Speach-Bubbles", icon: "\ecaa" },
  { name: "Speak-2", icon: "\ecab" },
  { name: "Speak", icon: "\ecac" },
  { name: "Speaker-2", icon: "\ecad" },
  { name: "Speaker", icon: "\ecae" },
  { name: "Spell-Check", icon: "\ecaf" },
  { name: "Spell-CheckABC", icon: "\ecb0" },
  { name: "Spermium", icon: "\ecb1" },
  { name: "Spider", icon: "\ecb2" },
  { name: "Spiderweb", icon: "\ecb3" },
  { name: "Split-FourSquareWindow", icon: "\ecb4" },
  { name: "Split-Horizontal", icon: "\ecb5" },
  { name: "Split-Horizontal2Window", icon: "\ecb6" },
  { name: "Split-Vertical", icon: "\ecb7" },
  { name: "Split-Vertical2", icon: "\ecb8" },
  { name: "Split-Window", icon: "\ecb9" },
  { name: "Spoder", icon: "\ecba" },
  { name: "Spoon", icon: "\ecbb" },
  { name: "Sport-Mode", icon: "\ecbc" },
  { name: "Sports-Clothings1", icon: "\ecbd" },
  { name: "Sports-Clothings2", icon: "\ecbe" },
  { name: "Sports-Shirt", icon: "\ecbf" },
  { name: "Spot", icon: "\ecc0" },
  { name: "Spray", icon: "\ecc1" },
  { name: "Spread", icon: "\ecc2" },
  { name: "Spring", icon: "\ecc3" },
  { name: "Spurl", icon: "\ecc4" },
  { name: "Spy", icon: "\ecc5" },
  { name: "Squirrel", icon: "\ecc6" },
  { name: "SSL", icon: "\ecc7" },
  { name: "St-BasilsCathedral", icon: "\ecc8" },
  { name: "St-PaulsCathedral", icon: "\ecc9" },
  { name: "Stamp-2", icon: "\ecca" },
  { name: "Stamp", icon: "\eccb" },
  { name: "Stapler", icon: "\eccc" },
  { name: "Star-Track", icon: "\eccd" },
  { name: "Star", icon: "\ecce" },
  { name: "Starfish", icon: "\eccf" },
  { name: "Start2", icon: "\ecd0" },
  { name: "Start-3", icon: "\ecd1" },
  { name: "Start-ways", icon: "\ecd2" },
  { name: "Start", icon: "\ecd3" },
  { name: "Statistic", icon: "\ecd4" },
  { name: "Stethoscope", icon: "\ecd5" },
  { name: "stop--2", icon: "\ecd6" },
  { name: "Stop-Music", icon: "\ecd7" },
  { name: "Stop", icon: "\ecd8" },
  { name: "Stopwatch-2", icon: "\ecd9" },
  { name: "Stopwatch", icon: "\ecda" },
  { name: "Storm", icon: "\ecdb" },
  { name: "Street-View", icon: "\ecdc" },
  { name: "Street-View2", icon: "\ecdd" },
  { name: "Strikethrough-Text", icon: "\ecde" },
  { name: "Stroller", icon: "\ecdf" },
  { name: "Structure", icon: "\ece0" },
  { name: "Student-Female", icon: "\ece1" },
  { name: "Student-Hat", icon: "\ece2" },
  { name: "Student-Hat2", icon: "\ece3" },
  { name: "Student-Male", icon: "\ece4" },
  { name: "Student-MaleFemale", icon: "\ece5" },
  { name: "Students", icon: "\ece6" },
  { name: "Studio-Flash", icon: "\ece7" },
  { name: "Studio-Lightbox", icon: "\ece8" },
  { name: "Stumbleupon", icon: "\ece9" },
  { name: "Suit", icon: "\ecea" },
  { name: "Suitcase", icon: "\eceb" },
  { name: "Sum-2", icon: "\ecec" },
  { name: "Sum", icon: "\eced" },
  { name: "Summer", icon: "\ecee" },
  { name: "Sun-CloudyRain", icon: "\ecef" },
  { name: "Sun", icon: "\ecf0" },
  { name: "Sunglasses-2", icon: "\ecf1" },
  { name: "Sunglasses-3", icon: "\ecf2" },
  { name: "Sunglasses-Smiley", icon: "\ecf3" },
  { name: "Sunglasses-Smiley2", icon: "\ecf4" },
  { name: "Sunglasses-W", icon: "\ecf5" },
  { name: "Sunglasses-W2", icon: "\ecf6" },
  { name: "Sunglasses-W3", icon: "\ecf7" },
  { name: "Sunglasses", icon: "\ecf8" },
  { name: "Sunrise", icon: "\ecf9" },
  { name: "Sunset", icon: "\ecfa" },
  { name: "Superman", icon: "\ecfb" },
  { name: "Support", icon: "\ecfc" },
  { name: "Surprise", icon: "\ecfd" },
  { name: "Sushi", icon: "\ecfe" },
  { name: "Sweden", icon: "\ecff" },
  { name: "Swimming-Short", icon: "\ed00" },
  { name: "Swimming", icon: "\ed01" },
  { name: "Swimmwear", icon: "\ed02" },
  { name: "Switch", icon: "\ed03" },
  { name: "Switzerland", icon: "\ed04" },
  { name: "Sync-Cloud", icon: "\ed05" },
  { name: "Sync", icon: "\ed06" },
  { name: "Synchronize-2", icon: "\ed07" },
  { name: "Synchronize", icon: "\ed08" },
  { name: "T-Shirt", icon: "\ed09" },
  { name: "Tablet-2", icon: "\ed0a" },
  { name: "Tablet-3", icon: "\ed0b" },
  { name: "Tablet-Orientation", icon: "\ed0c" },
  { name: "Tablet-Phone", icon: "\ed0d" },
  { name: "Tablet-Secure", icon: "\ed0e" },
  { name: "Tablet-Vertical", icon: "\ed0f" },
  { name: "Tablet", icon: "\ed10" },
  { name: "Tactic", icon: "\ed11" },
  { name: "Tag-2", icon: "\ed12" },
  { name: "Tag-3", icon: "\ed13" },
  { name: "Tag-4", icon: "\ed14" },
  { name: "Tag-5", icon: "\ed15" },
  { name: "Tag", icon: "\ed16" },
  { name: "Taj-Mahal", icon: "\ed17" },
  { name: "Talk-Man", icon: "\ed18" },
  { name: "Tap", icon: "\ed19" },
  { name: "Target-Market", icon: "\ed1a" },
  { name: "Target", icon: "\ed1b" },
  { name: "Taurus-2", icon: "\ed1c" },
  { name: "Taurus", icon: "\ed1d" },
  { name: "Taxi-2", icon: "\ed1e" },
  { name: "Taxi-Sign", icon: "\ed1f" },
  { name: "Taxi", icon: "\ed20" },
  { name: "Teacher", icon: "\ed21" },
  { name: "Teapot", icon: "\ed22" },
  { name: "Technorati", icon: "\ed23" },
  { name: "Teddy-Bear", icon: "\ed24" },
  { name: "Tee-Mug", icon: "\ed25" },
  { name: "Telephone-2", icon: "\ed26" },
  { name: "Telephone", icon: "\ed27" },
  { name: "Telescope", icon: "\ed28" },
  { name: "Temperature-2", icon: "\ed29" },
  { name: "Temperature-3", icon: "\ed2a" },
  { name: "Temperature", icon: "\ed2b" },
  { name: "Temple", icon: "\ed2c" },
  { name: "Tennis-Ball", icon: "\ed2d" },
  { name: "Tennis", icon: "\ed2e" },
  { name: "Tent", icon: "\ed2f" },
  { name: "Test-Tube", icon: "\ed30" },
  { name: "Test-Tube2", icon: "\ed31" },
  { name: "Testimonal", icon: "\ed32" },
  { name: "Text-Box", icon: "\ed33" },
  { name: "Text-Effect", icon: "\ed34" },
  { name: "Text-HighlightColor", icon: "\ed35" },
  { name: "Text-Paragraph", icon: "\ed36" },
  { name: "Thailand", icon: "\ed37" },
  { name: "The-WhiteHouse", icon: "\ed38" },
  { name: "This-SideUp", icon: "\ed39" },
  { name: "Thread", icon: "\ed3a" },
  { name: "Three-ArrowFork", icon: "\ed3b" },
  { name: "Three-Fingers", icon: "\ed3c" },
  { name: "Three-FingersDrag", icon: "\ed3d" },
  { name: "Three-FingersDrag2", icon: "\ed3e" },
  { name: "Three-FingersTouch", icon: "\ed3f" },
  { name: "Thumb", icon: "\ed40" },
  { name: "Thumbs-DownSmiley", icon: "\ed41" },
  { name: "Thumbs-UpSmiley", icon: "\ed42" },
  { name: "Thunder", icon: "\ed43" },
  { name: "Thunderstorm", icon: "\ed44" },
  { name: "Ticket", icon: "\ed45" },
  { name: "Tie-2", icon: "\ed46" },
  { name: "Tie-3", icon: "\ed47" },
  { name: "Tie-4", icon: "\ed48" },
  { name: "Tie", icon: "\ed49" },
  { name: "Tiger", icon: "\ed4a" },
  { name: "Time-Backup", icon: "\ed4b" },
  { name: "Time-Bomb", icon: "\ed4c" },
  { name: "Time-Clock", icon: "\ed4d" },
  { name: "Time-Fire", icon: "\ed4e" },
  { name: "Time-Machine", icon: "\ed4f" },
  { name: "Time-Window", icon: "\ed50" },
  { name: "Timer-2", icon: "\ed51" },
  { name: "Timer", icon: "\ed52" },
  { name: "To-Bottom", icon: "\ed53" },
  { name: "To-Bottom2", icon: "\ed54" },
  { name: "To-Left", icon: "\ed55" },
  { name: "To-Right", icon: "\ed56" },
  { name: "To-Top", icon: "\ed57" },
  { name: "To-Top2", icon: "\ed58" },
  { name: "Token-", icon: "\ed59" },
  { name: "Tomato", icon: "\ed5a" },
  { name: "Tongue", icon: "\ed5b" },
  { name: "Tooth-2", icon: "\ed5c" },
  { name: "Tooth", icon: "\ed5d" },
  { name: "Top-ToBottom", icon: "\ed5e" },
  { name: "Touch-Window", icon: "\ed5f" },
  { name: "Tourch", icon: "\ed60" },
  { name: "Tower-2", icon: "\ed61" },
  { name: "Tower-Bridge", icon: "\ed62" },
  { name: "Tower", icon: "\ed63" },
  { name: "Trace", icon: "\ed64" },
  { name: "Tractor", icon: "\ed65" },
  { name: "traffic-Light", icon: "\ed66" },
  { name: "Traffic-Light2", icon: "\ed67" },
  { name: "Train-2", icon: "\ed68" },
  { name: "Train", icon: "\ed69" },
  { name: "Tram", icon: "\ed6a" },
  { name: "Transform-2", icon: "\ed6b" },
  { name: "Transform-3", icon: "\ed6c" },
  { name: "Transform-4", icon: "\ed6d" },
  { name: "Transform", icon: "\ed6e" },
  { name: "Trash-withMen", icon: "\ed6f" },
  { name: "Tree-2", icon: "\ed70" },
  { name: "Tree-3", icon: "\ed71" },
  { name: "Tree-4", icon: "\ed72" },
  { name: "Tree-5", icon: "\ed73" },
  { name: "Tree", icon: "\ed74" },
  { name: "Trekking", icon: "\ed75" },
  { name: "Triangle-ArrowDown", icon: "\ed76" },
  { name: "Triangle-ArrowLeft", icon: "\ed77" },
  { name: "Triangle-ArrowRight", icon: "\ed78" },
  { name: "Triangle-ArrowUp", icon: "\ed79" },
  { name: "Tripod-2", icon: "\ed7a" },
  { name: "Tripod-andVideo", icon: "\ed7b" },
  { name: "Tripod-withCamera", icon: "\ed7c" },
  { name: "Tripod-withGopro", icon: "\ed7d" },
  { name: "Trophy-2", icon: "\ed7e" },
  { name: "Trophy", icon: "\ed7f" },
  { name: "Truck", icon: "\ed80" },
  { name: "Trumpet", icon: "\ed81" },
  { name: "Tumblr", icon: "\ed82" },
  { name: "Turkey", icon: "\ed83" },
  { name: "Turn-Down", icon: "\ed84" },
  { name: "Turn-Down2", icon: "\ed85" },
  { name: "Turn-DownFromLeft", icon: "\ed86" },
  { name: "Turn-DownFromRight", icon: "\ed87" },
  { name: "Turn-Left", icon: "\ed88" },
  { name: "Turn-Left3", icon: "\ed89" },
  { name: "Turn-Right", icon: "\ed8a" },
  { name: "Turn-Right3", icon: "\ed8b" },
  { name: "Turn-Up", icon: "\ed8c" },
  { name: "Turn-Up2", icon: "\ed8d" },
  { name: "Turtle", icon: "\ed8e" },
  { name: "Tuxedo", icon: "\ed8f" },
  { name: "TV", icon: "\ed90" },
  { name: "Twister", icon: "\ed91" },
  { name: "Twitter-2", icon: "\ed92" },
  { name: "Twitter", icon: "\ed93" },
  { name: "Two-Fingers", icon: "\ed94" },
  { name: "Two-FingersDrag", icon: "\ed95" },
  { name: "Two-FingersDrag2", icon: "\ed96" },
  { name: "Two-FingersScroll", icon: "\ed97" },
  { name: "Two-FingersTouch", icon: "\ed98" },
  { name: "Two-Windows", icon: "\ed99" },
  { name: "Type-Pass", icon: "\ed9a" },
  { name: "Ukraine", icon: "\ed9b" },
  { name: "Umbrela", icon: "\ed9c" },
  { name: "Umbrella-2", icon: "\ed9d" },
  { name: "Umbrella-3", icon: "\ed9e" },
  { name: "Under-LineText", icon: "\ed9f" },
  { name: "Undo", icon: "\eda0" },
  { name: "United-Kingdom", icon: "\eda1" },
  { name: "United-States", icon: "\eda2" },
  { name: "University-2", icon: "\eda3" },
  { name: "University", icon: "\eda4" },
  { name: "Unlike-2", icon: "\eda5" },
  { name: "Unlike", icon: "\eda6" },
  { name: "Unlock-2", icon: "\eda7" },
  { name: "Unlock-3", icon: "\eda8" },
  { name: "Unlock", icon: "\eda9" },
  { name: "Up--Down", icon: "\edaa" },
  { name: "Up--Down3", icon: "\edab" },
  { name: "Up-2", icon: "\edac" },
  { name: "Up-3", icon: "\edad" },
  { name: "Up-4", icon: "\edae" },
  { name: "Up", icon: "\edaf" },
  { name: "Upgrade", icon: "\edb0" },
  { name: "Upload-2", icon: "\edb1" },
  { name: "Upload-toCloud", icon: "\edb2" },
  { name: "Upload-Window", icon: "\edb3" },
  { name: "Upload", icon: "\edb4" },
  { name: "Uppercase-Text", icon: "\edb5" },
  { name: "Upward", icon: "\edb6" },
  { name: "URL-Window", icon: "\edb7" },
  { name: "Usb-2", icon: "\edb8" },
  { name: "Usb-Cable", icon: "\edb9" },
  { name: "Usb", icon: "\edba" },
  { name: "User", icon: "\edbb" },
  { name: "Ustream", icon: "\edbc" },
  { name: "Vase", icon: "\edbd" },
  { name: "Vector-2", icon: "\edbe" },
  { name: "Vector-3", icon: "\edbf" },
  { name: "Vector-4", icon: "\edc0" },
  { name: "Vector-5", icon: "\edc1" },
  { name: "Vector", icon: "\edc2" },
  { name: "Venn-Diagram", icon: "\edc3" },
  { name: "Vest-2", icon: "\edc4" },
  { name: "Vest", icon: "\edc5" },
  { name: "Viddler", icon: "\edc6" },
  { name: "Video-2", icon: "\edc7" },
  { name: "Video-3", icon: "\edc8" },
  { name: "Video-4", icon: "\edc9" },
  { name: "Video-5", icon: "\edca" },
  { name: "Video-6", icon: "\edcb" },
  { name: "Video-GameController", icon: "\edcc" },
  { name: "Video-Len", icon: "\edcd" },
  { name: "Video-Len2", icon: "\edce" },
  { name: "Video-Photographer", icon: "\edcf" },
  { name: "Video-Tripod", icon: "\edd0" },
  { name: "Video", icon: "\edd1" },
  { name: "Vietnam", icon: "\edd2" },
  { name: "View-Height", icon: "\edd3" },
  { name: "View-Width", icon: "\edd4" },
  { name: "Vimeo", icon: "\edd5" },
  { name: "Virgo-2", icon: "\edd6" },
  { name: "Virgo", icon: "\edd7" },
  { name: "Virus-2", icon: "\edd8" },
  { name: "Virus-3", icon: "\edd9" },
  { name: "Virus", icon: "\edda" },
  { name: "Visa", icon: "\eddb" },
  { name: "Voice", icon: "\eddc" },
  { name: "Voicemail", icon: "\eddd" },
  { name: "Volleyball", icon: "\edde" },
  { name: "Volume-Down", icon: "\eddf" },
  { name: "Volume-Up", icon: "\ede0" },
  { name: "VPN", icon: "\ede1" },
  { name: "Wacom-Tablet", icon: "\ede2" },
  { name: "Waiter", icon: "\ede3" },
  { name: "Walkie-Talkie", icon: "\ede4" },
  { name: "Wallet-2", icon: "\ede5" },
  { name: "Wallet-3", icon: "\ede6" },
  { name: "Wallet", icon: "\ede7" },
  { name: "Warehouse", icon: "\ede8" },
  { name: "Warning-Window", icon: "\ede9" },
  { name: "Watch-2", icon: "\edea" },
  { name: "Watch-3", icon: "\edeb" },
  { name: "Watch", icon: "\edec" },
  { name: "Wave-2", icon: "\eded" },
  { name: "Wave", icon: "\edee" },
  { name: "Webcam", icon: "\edef" },
  { name: "weight-Lift", icon: "\edf0" },
  { name: "Wheelbarrow", icon: "\edf1" },
  { name: "Wheelchair", icon: "\edf2" },
  { name: "Width-Window", icon: "\edf3" },
  { name: "Wifi-2", icon: "\edf4" },
  { name: "Wifi-Keyboard", icon: "\edf5" },
  { name: "Wifi", icon: "\edf6" },
  { name: "Wind-Turbine", icon: "\edf7" },
  { name: "Windmill", icon: "\edf8" },
  { name: "Window-2", icon: "\edf9" },
  { name: "Window", icon: "\edfa" },
  { name: "Windows-2", icon: "\edfb" },
  { name: "Windows-Microsoft", icon: "\edfc" },
  { name: "Windows", icon: "\edfd" },
  { name: "Windsock", icon: "\edfe" },
  { name: "Windy", icon: "\edff" },
  { name: "Wine-Bottle", icon: "\ee00" },
  { name: "Wine-Glass", icon: "\ee01" },
  { name: "Wink", icon: "\ee02" },
  { name: "Winter-2", icon: "\ee03" },
  { name: "Winter", icon: "\ee04" },
  { name: "Wireless", icon: "\ee05" },
  { name: "Witch-Hat", icon: "\ee06" },
  { name: "Witch", icon: "\ee07" },
  { name: "Wizard", icon: "\ee08" },
  { name: "Wolf", icon: "\ee09" },
  { name: "Woman-Sign", icon: "\ee0a" },
  { name: "WomanMan", icon: "\ee0b" },
  { name: "Womans-Underwear", icon: "\ee0c" },
  { name: "Womans-Underwear2", icon: "\ee0d" },
  { name: "Women", icon: "\ee0e" },
  { name: "Wonder-Woman", icon: "\ee0f" },
  { name: "Wordpress", icon: "\ee10" },
  { name: "Worker-Clothes", icon: "\ee11" },
  { name: "Worker", icon: "\ee12" },
  { name: "Wrap-Text", icon: "\ee13" },
  { name: "Wreath", icon: "\ee14" },
  { name: "Wrench", icon: "\ee15" },
  { name: "X-Box", icon: "\ee16" },
  { name: "X-ray", icon: "\ee17" },
  { name: "Xanga", icon: "\ee18" },
  { name: "Xing", icon: "\ee19" },
  { name: "Yacht", icon: "\ee1a" },
  { name: "Yahoo-Buzz", icon: "\ee1b" },
  { name: "Yahoo", icon: "\ee1c" },
  { name: "Yelp", icon: "\ee1d" },
  { name: "Yes", icon: "\ee1e" },
  { name: "Ying-Yang", icon: "\ee1f" },
  { name: "Youtube", icon: "\ee20" },
  { name: "Z-A", icon: "\ee21" },
  { name: "Zebra", icon: "\ee22" },
  { name: "Zombie", icon: "\ee23" },
  { name: "Zoom-Gesture", icon: "\ee24" },
  { name: "Zootool", icon: "\ee25" },
]
