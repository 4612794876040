import React from "react";
import {Link} from "react-router-dom"
import moment from "moment";


export default class ServiceItem extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      form: props.form,
      service: props.service
    }
  }

  render() {
    let {
      form,
      service
    } = this.state

    return (
      <div class={`col-12`}>
        <div class="item"
          style={{
            backgroundImage: `url(${service.image?.original})`,
          }}
          data-overlay="7"
        >
          <div class="container">
            <div className='row align-items-center'>
              <div className='col-md-4 col-lg-7 col-left'>
                <h4>{service.name}</h4>
                <div className='includes ellipsis-1'>{service.description}</div>
              </div>
              <div className='col-md-3 col-lg-auto ml-auto'>
                <div className='price'>€XX per hour</div>
              </div>
              <div className='col-md-2 col-lg-auto'>
                <div className='available'>XX Spaces left</div>
              </div>
              <div className='col-md-auto position-static'>
                <a
                  class="btn btn--primary btn cs text-white"
                  href={`/book?form_id=${form.id}&service_id=${service.id}`}
                >
                  Book Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
