import React from "react";

import Collapsible from "react-collapsible";
import Border from "../Border";
import Button from "../Button";
import Play from "../Play";

import Dropdown from "../Dropdown";

import H1 from "./editable/H1";
import Img, { Sizes } from "./editable/Img";
import P from "./editable/P";
import Span from "./editable/Span";
import Section from "./editable/Section";

import ImageUploader from "./editable/ImageUploader";

import {
  DEFAULT_PRIMARY_BUTTON,
  DEFAULT_SECONDARY_BUTTON,
} from "./editable/Section";

import TextInfoInputItem from "../sectioninputs/TextInfoInputItem";

import General from "../../utils/General";
import Backend from "../../utils/Backend";

let BANNER_HEIGHTS = [
  {
    value: "full",
    label: "Full 100% Height",
  },
  {
    value: "half",
    label: "Half (50%) Height",
  },
];

export default class Block50 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      key: 0,
      data: props.data,
      editable: props.editable,
      uuid: "cb_" + General.uuid(),
    };

    this.section = React.createRef();

    this.updateContentBlock = General.debounce(
      () => {
        console.log("3333", this.state.data);
        Backend.updateContentBlock(this.state.data);

        this.setState(
          {
            key: this.state.key + 1,
          },
          () => General.updateSlides()
        );
      },
      1000,
      false
    );
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  _update(data, callback) {
    let { key } = this.state;

    data.settings.msc_enabled = false;
    this.setState({ ...data }, () => {
      if (callback) {
        callback();
      }
      this.updateContentBlock();
    });
  }

  _renderToggle(
    key,
    title,
    defaultOnValue,
    defaultOffValue = null,
    callback = null,
    onChange = null
  ) {
    let { data } = this.state;

    let checked = data[key] != null && data[key] != false;
    if (key == "overlay") {
      checked = data.settings.overlay > 0;
    }

    let labelId = "tg_" + data.id + "__" + key + "__cb_pb";
    return (
      <div class="row">
        <div class="col-md-9">
          <h4>{title}</h4>
        </div>
        <div class="col-md-3 text-right">
          <div class="input-checkbox input-checkbox--switch">
            <input
              id={labelId}
              type="checkbox"
              checked={checked}
              onChange={(e) => {
                if (onChange) {
                  onChange(e);
                  return;
                }
                data[key] = e.target.checked ? defaultOnValue : defaultOffValue;
                this._update(data, callback);
              }}
            />
            <label for={labelId}></label>
          </div>
        </div>
      </div>
    );
  }

  _getModalContent(data) {
    let { products, categories } = this.state;
    return (
      <>
        <Border>
          <Collapsible trigger="General Options">
            <br />
            {this._renderToggle(
              "primary_button",
              "Primary Button",
              DEFAULT_PRIMARY_BUTTON
            )}
            {this._renderToggle(
              "secondary_button",
              "Secondary Button",
              DEFAULT_SECONDARY_BUTTON
            )}
            {this._renderToggle("video_button", "Large Video Button", {
              url: "https://www.youtube.com/watch?v=DGQwd1_dpuc",
              settings: {},
            })}
            {this._renderToggle("auxiliary_button", "Small Video Button", {
              title: "Watch Quick Video",
              url: "https://www.youtube.com/watch?v=DGQwd1_dpuc",
              settings: { primary_color: "#FFFFFF", msc_enabled: true },
            })}
            {this._renderToggle("link_button", "Text CTA Button", {
              title: "GET IN TOUCH",
              url: "#",
              settings: { msc_enabled: true },
            })}
          </Collapsible>
        </Border>

        <Border>
          <Collapsible trigger="Illustration Image">
            <br />
            <ImageUploader
              title={""}
              titleTag="span"
              type={data.foreground_image.type}
              pickerType={"illustration"}
              src={
                data.foreground_image ? data.foreground_image.original : null
              }
              primaryColor={data.settings.primary_color}
              onUploaded={(image) => {
                data.foreground_image = image;
                this._update(data);
              }}
            />
          </Collapsible>
        </Border>

        <Border>
          <Collapsible trigger="Layout">
            <br />
            <span>Choose Position Of Illustration</span>
            <div className="input-select">
              <select
                value={data.variation || "left"}
                onChange={(e) => {
                  data.variation = e.target.value;
                  this._update(data);
                }}
              >
                <option value="left">Image Left, Text Right</option>
                <option value="right">Text Left, Image Right</option>
              </select>
            </div>
            <br />
            <span>Banner Height</span>
            <Dropdown
              options={BANNER_HEIGHTS}
              value={BANNER_HEIGHTS.find((mode) => mode.value == data.height)}
              onChange={(option) => {
                data.height = option.value;
                this._update(data, () => General.updateImageBackgrounds());
              }}
              placeholder="Banner Height"
            />
          </Collapsible>
        </Border>
      </>
    );
  }

  render() {
    let { data, editable, uuid, key } = this.state;

    let className = "";
    if (data.height === "full") {
      className += " height-100";
    }

    if (data.height === "half") {
      // todo should be height of content + padding
      className += " min-height-50 height-half";
    }

    if (data.variation === "left") {
      className += " switchable";
    }

    data.settings.primary_color = data.settings.msc_enabled
      ? window.CURRENT_PAGE_PRIMARY_COLOR
      : data.settings.primary_color;

    return (
      <Section
        ref={this.section}
        data={data}
        index={this.props.index}
        className={
          " block-50 d-flex align-items-center text-center " +
          className +
          " " +
          this.props.className
        }
        editable={editable}
        onUpdated={(data) => this._update(data)}
        onDelete={() => this.props.onDelete()}
        primaryColorTitle="Illustration Main Colour"
        secondaryColor={false}
        quaternaryColor={false}
        renderModalContent={() => this._getModalContent(data)}
        onAddContentBlockPressed={(contentBlock) =>
          this.props.onAddContentBlockPressed(contentBlock)
        }
        canMoveUp={this.props.canMoveUp}
        canMoveDown={this.props.canMoveDown}
        onVisibilityButtonPressed={(index) => this.props.onVisibilityButtonPressed(index)}
        onMoveUpPressed={() => this.props.onMoveUpPressed()}
        onMoveDownPressed={() => this.props.onMoveDownPressed()}
      >
        <div class="container">
          <div class="row justify-content-between">
            {/* starts : column one */}
            <div class="c1 col-lg-5 col-md-7 d-flex m-order-1 align-items-center justify-content-center">
              <div className="b-content">
                {data.title && (
                  <H1
                    text={data.title}
                    color={data.title_color}
                    editable={editable}
                    onUpdated={(text) => {
                      data.title = text;
                      this._update(data);
                    }}
                  />
                )}
                {data.subtitle && (
                  <P
                    text={data.subtitle}
                    color={data.subtitle_color}
                    editable={editable}
                    onUpdated={(text) => {
                      data.subtitle = text;
                      this._update(data);
                    }}
                  />
                )}

                {data.video_button && (
                  <Play
                    overlay={data.video_button.settings.overlay}
                    icon={data.video_button.icon}
                    iconImage={data.video_button.icon_image}
                    videoUrl={data.video_button.url}
                    editable={editable}
                    onUpdated={(videoUrl, icon, iconImage, overlay) => {
                      data.video_button.url = videoUrl;
                      data.video_button.icon = icon;
                      data.video_button.icon_image = iconImage;
                      data.video_button.settings.overlay = overlay;
                      this._update(data);
                    }}
                  />
                )}

                <div className="c-btn-group">
                  <Button
                    data={data.secondary_button}
                    type="secondary"
                    editable={editable}
                    renderModal={(content) => this.props.renderModal(content)}
                    onUpdated={(button) => {
                      data.secondary_button = button;
                      this._update(data);
                    }}
                  />

                  <Button
                    data={data.primary_button}
                    editable={editable}
                    renderModal={(content) => this.props.renderModal(content)}
                    onUpdated={(button) => {
                      data.primary_button = button;
                      this._update(data);
                    }}
                  />
                </div>

                {data.link_button && (
                  <span className="block type--fine-print">
                    <Button
                      data={data.link_button}
                      editable={editable}
                      type="text_underline"
                      onUpdated={(button) => {
                        data.link_button = button;
                        this._update(data);
                      }}
                    />
                  </span>
                )}

                {data.auxiliary_button && (
                  <div className="inside-btn-inline">
                    <Button
                      data={data.auxiliary_button}
                      type="video"
                      editable={editable}
                      onUpdated={(button) => {
                        data.auxiliary_button = button;
                        this._update(data);
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
            {/* end : column one */}

            {/* starts : column two */}
            <div class="c2 col-lg-7 col-md-5 col-12 d-flex align-items-center justify-content-center">
              <Img
                alt="Image"
                img={data.foreground_image}
                size={Sizes.MEDIUM}
                editable={false}
                primaryColor={data.settings.primary_color}
                type={data.foreground_image.type}
                pickerType={"illustration"}
                onUpdated={(image) => {
                  data.foreground_image = image;
                  this._update(data);
                }}
              />
            </div>
            {/* end : column two */}
          </div>
        </div>
      </Section>
    );
  }
}
