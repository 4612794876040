import React from 'react'

import H from "./H"

// convenienve wrapper for H
export default class H1 extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      text: props.text,
      color: props.color,
      className: props.className,
      textAlign: props.textAlign,
      editable: props.editable
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  render(){
    let {
      text,
      color,
      className,
      textAlign,
      editable
    } = this.state

    return (
      <H
        level={ 3 }
        className={className + ' pre '}
        text={ text }
        color={ color }
        editable={ editable }
        textAlign={ textAlign }
        onUpdated={text => this.props.onUpdated(text)}
      />
    )
  }
}
