import React from 'react'

import moment from "moment";

import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

export default class DateTime extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      data: props.data,
      index: props.index,
      value: props.data.answer?.value || null
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  _renderDateInput(data, index){
    return (
      <DatePicker
        className='field-date-time'
        error={data.error}
        helperText={data.error ? "This field is required." : ""}
        fullWidth
        inputVariant="outlined"
        label={data.title}
        emptyLabel={''}
        disableToolbar
        inputProps={{id: `form-input-${data.id}`}}
        value={this.state.value}
        format={'YYYY-MM-DD'}
        onChange={date => {
          date = date.format('YYYY-MM-DD')
          let value = {field: data.id, value: date}
          data.error = false
          data.answer = value
          this.setState({value: date, data})
          this.props.onUpdated(index, value)
        }}
      />
    )
  }

  _renderTimeInput(data, index){
    return (
      <TimePicker
        error={data.error}
        helperText={data.error ? "This field is required." : ""}
        fullWidth
        inputVariant="outlined"
        label={data.title}
        emptyLabel={''}
        disableToolbar
        inputProps={{id: `form-input-${data.id}`}}
        value={this.state.value}
        format={'hh:mm'}
        onChange={time => {
          time = time.format('hh:mm')
          let value = {field: data.id, value: time}
          data.error = false
          data.answer = value
          this.setState({value: time, data})
          this.props.onUpdated(index, value)
        }}
      />
    )
  }

  _renderDateTimeInput(data, index){
    return (
      <DateTimePicker
        error={data.error}
        helperText={data.error ? "This field is required." : ""}
        fullWidth
        inputVariant="outlined"
        label={data.title}
        emptyLabel={''}
        disableToolbar
        inputProps={{id: `form-input-${data.id}`}}
        value={this.state.value}
        format={'YYYY-MM-DD hh:mm'}
        onChange={datetime => {
          datetime = datetime.format('YYYY-MM-DD hh:mm')
          let value = {field: data.id, value: datetime}
          data.error = false
          data.answer = value
          this.setState({value: datetime, data})
          this.props.onUpdated(index, value)
        }}
      />
    )
  }

  _renderInput(data, index){

    let type = data.type

    return (
      <>
        <MuiPickersUtilsProvider
          utils={MomentUtils}
        >
          {
            type === "date" &&
            this._renderDateInput(data, index)
          }

          {
            type === "time" &&
            this._renderTimeInput(data, index)
          }

          {
            type === "datetime" &&
            this._renderDateTimeInput(data, index)
          }

        </MuiPickersUtilsProvider>
      </>
    )
  }

  render() {
    let {
      data,
      index
    } = this.state

    return (
      <>
        { this._renderInput(data, index)}
      </>
    )
  }
}
