import React from 'react'

import Dropdown from './Dropdown';

import $ from 'jquery';

import General from '../utils/General'
import Backend from '../utils/Backend'

import { FONT_GROUPS } from '../utils/Fonts'

export default class FontSelect extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
        value: props.value
    }
    this.options = this._getOptions()
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
    this.options = this._getOptions()
  }

  static _createOption(font){
    return {
      label: font.familyName,
      value: font.familyName,
      className: font.className,
      weights: font.weights
    }
  }

  _getOptions(){
    return window.FONTS.map(font => {
      return FontSelect._createOption(font)
    })
  }

  static getOption(value){
    for(var i=0; i<window.FONTS.length; i++){
      let font = window.FONTS[i]
      if(font.familyName === value){
        return FontSelect._createOption(font)
      }
    }
    return null
  }

  render() {
    let {
      value,
    } = this.state

    let option = FontSelect.getOption(value)
    return (
      <>
        <Dropdown
            options={this.options}
            className="font-select"
            value={option}
            onChange={option => {
              this.setState({ value })
              this.props.onUpdated(option.value)
            }}
            placeholder="Select a font"
            placeholderClassName={option ? option.className : null}
        />
      </>
    )
  }
}
