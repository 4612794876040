import React from "react";

import $ from "jquery";

import DOMPurify from "dompurify";
import Event from "./Event";

import { CLOSE_TOUR } from "../constants/Events";

export default class General {
  static uuid() {
    var dt = new Date().getTime();
    var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );
    return uuid;
  }

  static closeActiveModal() {
    if (!window.mr) {
      console.warn("mr modals not initiated");
      return;
    }
    window.mr.modals.closeActiveModal();
  }

  static updateImageBackgrounds() {
    if (!window.mr) {
      console.warn("mr backgrounds not initiated");
      return;
    }
    window.mr.backgrounds.documentReady(window.$);
  }

  static updateGradients() {
    if (window.$) {
      window.$("canvas[id*='granim']").remove();
    }
    if (!window.mr) {
      console.warn("mr granim not initiated");
      return;
    }
    window.mr.granim.documentReady(window.$);
  }

  static updateParallax() {
    if (!window.mr) {
      console.warn("mr parallax not initiated");
      return;
    }
    window.mr.parallax.update();
  }

  static updateSlides() {
    if (!window.mr) {
      console.warn("mr sliders not initiated");
      return;
    }
    window.mr.sliders.documentReady(window.$);
  }

  static updateScroll() {
    if (!window.mr) {
      console.warn("mr Scroll not initiated");
      return;
    }
    window.mr.scroll.classModifiers.documentReady(window.$);
  }

  static updateToggleClass() {
    if (!window.mr) {
      console.warn("mr Toggle Class not initiated");
      return;
    }
    setTimeout(() => {
      window.mr.toggleClass.documentReady(window.$);
    }, 500);
  }

  static updateModals() {
    if (!window.mr) {
      console.warn("mr modals Class not initiated");
      return;
    }
    setTimeout(() => {
      $("div.all-page-modals").remove();
      window.mr.modals.documentReady(window.$);
    }, 500);
  }

  static updateTabs() {
    if (!window.mr) {
      console.warn("mr tabs not initiated");
      return;
    }
    window.mr.tabs.documentReady(window.$);
  }

  static updateVideo() {
    if (!window.mr) {
      console.warn("mr video not initiated");
      return;
    }
    window.mr.video.documentReady(window.$);
  }

  static updateCountdownTimer() {
    if (!window.mr) {
      console.warn("mr countdown not initiated");
      return;
    }
    window.mr.countdown.documentReady(window.$);
  }

  static updateBackgroundImageHolder() {
    if (!window.mr || !window.mr.backgrounds) {
      console.warn("mr backgrounds not initiated");
      return;
    }
    window.mr.backgrounds.documentReady(window.$);
  }

  static updateLazyBackgroundImages() {
    if (!window.mr || !window.mr.lazyBackgroundImages) {
      console.warn("mr lazyBackgroundImages not initiated");
      return;
    }
    window.mr.lazyBackgroundImages.documentReady(window.$);
  }

  static updateBlockAnimateOnScroll() {
    if (!window.mr || !window.mr.blockAnimateOnScroll) {
      console.warn("mr blockAnimateOnScroll not initiated");
      return;
    }
    window.mr.blockAnimateOnScroll.documentReady(window.$);
  }

  static updateHomePagePaddingTop() {
    if (!window.mr || !window.mr.updateHomePagePaddingTop) {
      console.warn("mr updateHomePagePaddingTop not initiated");
      return;
    }
    window.mr.updateHomePagePaddingTop.documentReady(window.$);
  }

  static updateEasyPieCharts() {
    if (!window.mr) {
      console.warn("mr countdown not initiated");
      return;
    }
    window.mr.easypiecharts.documentReady(window.$);
  }

  static updateForms() {
    if (!window.mr) {
      console.warn("mr forms not initiated");
      return;
    }
    window.mr.forms.documentReady(window.$);
  }

  static updateAll() {
    General.updateSlides();
    General.updateParallax();
    General.updateGradients();
    General.updateImageBackgrounds();
    General.updateTabs();
    General.updateForms();
    General.updateLazyBackgroundImages()
    General.updateBlockAnimateOnScroll()

    // TODO: proper fix for this, when synchronous the video
    // does not play
    setTimeout(() => {
      General.updateVideo();
      General.updateEasyPieCharts();
    }, 500);

  }

  static updateFormListeners() {
    if (!window.mr) {
      console.warn("mr forms not initiated");
      return;
    }
    window.mr.forms.documentReady(window.$);
  }

  static _renderDisableBodyScrollStyle() {
    return (
      <style
        dangerouslySetInnerHTML={{
          __html: `
         body {
           overflow-y: hidden !important;
         }
       `,
        }}
      />
    );
  }

  static _renderMenuStyle(settings) {
    if (!settings) {
      return null;
    }

    return (
      // todo remove this as it's a security risk
      // regardless of below

      // colours are sanitized by the API so can
      // only contain hexcode / word base colors
      <>
        {settings.secondary_color && (
          <style
            dangerouslySetInnerHTML={{
              __html:
                `
             @media (min-width: 768px){
              nav.d-header li a {
                color: ` +
                settings.secondary_color +
                ` !important;
              }
             }
           `,
            }}
          />
        )}
        {settings.primary_color && (
          <style
            dangerouslySetInnerHTML={{
              __html:
                `
                  @media (max-width: 767px){
                    .m-header .hamburger-toggle span{
                      background-color: ` +
                        settings.primary_color +
                      ` !important;
                    }
                  }
                  @media (max-width: 767px){
                    .m-menu li a,
                    .m-menu .dropdown__container .dropdown__content li a,
                    .d-header .menu-horizontal li a{
                      color: ` +
                      settings.primary_color +
                      ` !important;
                    }
                  }
                  @media (min-width: 768px){
                    .s-header .h-bar.pos-fixed .d-header li a,
                    nav.d-header .dropdown__container .dropdown__content li a{
                      color: ` +
                      settings.primary_color +
                      ` !important;
                    }
                  }
                `,
            }}
          />
        )}
        {settings.quaternary_color && (
          <style
            dangerouslySetInnerHTML={{
              __html:
                `
              @media (min-width: 768px){
                nav.bar--transparent {
                  background-color: ` +
                settings.quaternary_color +
                ` !important;
                }
              }
           `,
            }}
          />
        )}
        {settings.tertiary_color && (
          <style
            dangerouslySetInnerHTML={{
              __html:
                `
            @media (max-width: 767px){
              .s-header,
              .s-header .h-bar.pos-fixed{
                background-color: ` +
                settings.tertiary_color +
                ` !important;
              }
            }
            @media (min-width: 768px){
                .s-header .h-bar.pos-fixed .d-header,
                .nav-container .dropdown__container .dropdown__content {
                  background-color: ` +
                settings.tertiary_color +
                ` !important;
                }
              }
           `,
            }}
          />
        )}
      </>
    );
  }

  static renderTabStyle(settings, uuid) {
    if (!settings) {
      return null;
    }

    return (
      // todo remove this as it's a security risk
      // regardless of below

      // colours are sanitized by the API so can
      // only contain hexcode / word base colors
      <style
        dangerouslySetInnerHTML={{
          __html:
            `
        #` +
            uuid +
            ` .tabs li.active .tab__title span,
        #` +
            uuid +
            ` .tabs li.active .tab__title textarea  {
          color: ` +
            settings.secondary_color +
            ` !important;
        }
        #` +
            uuid +
            `.tabs--folder .tabs > li.active {
          border-top: 3px solid ` +
            settings.secondary_color +
            ` !important;
        }
      `,
        }}
      />
    );
  }

  static renderFormStyles(data){
    let backgroundColor = data.settings.senary_color?.length === 9 ? data.settings.senary_color : `${data.settings.senary_color}03`
    return (
      <style dangerouslySetInnerHTML={{__html: `
          #${data.anchor} .form-crm .MuiCheckbox-colorSecondary.Mui-checked,
          #${data.anchor} .form-crm .MuiRadio-colorSecondary.Mui-checked,
          #${data.anchor} .form-crm .MuiSwitch-colorSecondary.Mui-checked,
          #${data.anchor} .form-crm .MuiCheckbox-colorSecondary.Mui-checked,
          #${data.anchor} .form-crm .MuiFormLabel-root.Mui-focused,
          .MuiButton-textPrimary{
            color:${data.settings.senary_color} !important;
          }
          #${data.anchor} .form-crm .MuiRadio-colorSecondary.Mui-checked:hover,
          #${data.anchor} .form-crm .MuiIconButton-colorSecondary:hover,
          #${data.anchor} .form-crm .MuiSwitch-colorSecondary.Mui-checked:hover,
          #${data.anchor} .form-crm .MuiCheckbox-colorSecondary.Mui-checked:hover{
            background-color: ${backgroundColor} !important;
          }
          #${data.anchor} .form-crm .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track,
          .MuiPickersDay-daySelected{
            background-color: ${data.settings.senary_color} !important;
          }
          #${data.anchor} .form-crm .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
            border-color: ${data.settings.senary_color} !important;
          }
          #${data.anchor} .form-crm .MuiFormControlLabel-label a{
            color:${data.settings.senary_color} !important;
          }
        `}}
      />
    )
  }

  static debounce(func, wait, immediate) {
    var timeout;

    return function executedFunction() {
      var context = this;
      var args = arguments;

      var later = function () {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };

      var callNow = immediate && !timeout;

      clearTimeout(timeout);

      timeout = setTimeout(later, wait);

      if (callNow) func.apply(context, args);
    };
  }

  static snakeCaseToTitleCase(str){
    let words = str.replace(/_/g,' ')
    return General.toTitleCase(words)
  }

  static toTitleCase(str) {
    return str.replace(/\w\S*/g, function(txt){
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  static getYoutubeVideoId(youtubeUrl) {
    if (!youtubeUrl) {
      return null;
    }

    var regExp =
      /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = youtubeUrl.match(regExp);
    if (match && match[2].length == 11) {
      return match[2];
    } else {
      return null;
    }
  }

  static showNotification(message, success = true, clickable = false) {
    let delay = 500;
    let $notificationCotennt = $("#notification-content");
    let $notificationContainer = $("#notification-container");
    if (success) {
      $notificationCotennt.addClass("success");
      $notificationCotennt.removeClass("error");
      if (clickable) {
        delay = 3000;
        $notificationContainer.addClass("pointer-events-unset");
      }
    } else {
      delay = 3000;
      $notificationCotennt.addClass("error");
      $notificationCotennt.removeClass("success");
      $notificationContainer.removeClass("pointer-events-unset");
    }
    $("#notification-content").text(message);
    $("#notification-container").fadeIn().delay(delay).fadeOut();
  }

  static deepCopy(object) {
    return JSON.parse(JSON.stringify(object));
  }

  static getUrlParameter(name) {
    let url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  static renderTourButtonTitle(position, buttonTitle) {
    let tourButton = (
      <span
        className={`tooltiptext tooltip-${position}`}
      >
        {buttonTitle}
        <i
          className="material-icons-two-tone"
          onClick={(e) => {
            e.stopPropagation();
            Event.emit(CLOSE_TOUR);
          }}
        >close</i>
      </span>
    );

    return tourButton;
  }

  static clone(data) {
    return data ? JSON.parse(JSON.stringify(data)) : null;
  }

  static sanitizeData(text) {
    text = text?.replaceAll("<p>", "").replaceAll("</p>", "").replaceAll("<br>", "")
    const sanitizedData = () => ({
      __html: DOMPurify.sanitize(text),
    });

    return sanitizedData();
  }

  static loadScript(url){
    const script = document.createElement('script')

    script.src = url

    document.body.appendChild(script)
  }
}
