import React from 'react'
import moment from "moment";

import Img, { getImageUrl, Sizes } from '../components/blocks/editable/Img'

import { Helmet } from "react-helmet";

import renderHTML from 'react-render-html';

import ArticleItem from "../components/common/ArticleItem";

import LazyLoadingList from "../components/common/LazyLoadingList";

import General from '../utils/General'
import ScriptCache from '../utils/ScriptCache'

export default class Article extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      loadingSimilarArticles: true,
      quantity: 1,
      variant: null,
      website: props.website,
      article: props.article,
      similarArticles: []
    }
  }

  componentDidMount() {
    this._setup()
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _setup(){
    let {
      article
    } = this.state

    this.setState({
      article,
      loading: false
    }, () => this._loadJs())
  }

  _loadJs(){
    ScriptCache.loadDefaults()
    setTimeout(() => {
      General.updateAll()
    }, 1000)
  }

  _renderMeta(){
    let {
      article
    } = this.state

    if(article == null){
       return null
    }

    let website = article.website
    let favIconUrl = website.favicon ? website.favicon.original : "/favicon.png"

    return (
      <Helmet>
          <title>{`${article.title} | ${website.name}`}</title>
          <meta name="description" content={ website.descrption } />
          <meta name="keywords" content={ article.tags.join(",") || website.keywords } />
          <link rel="icon" href={ favIconUrl }/>
          <meta property="og:title" content={ article.title }/>
          <meta property="og:image" content={ article.thumbnail.thumbnail }/>
          <meta property="og:type" content="website"/>
          <meta property="og:url" content={ window.location.href }/>
          <meta property="twitter:card" content="summary_large_image"/>
          <meta property="twitter:url" content={ window.location.href }/>
          <meta property="twitter:title" content={ article.title }/>
          <meta property="twitter:image" content={ article.thumbnail.thumbnail }/>
      </Helmet>
    )
  }

  _renderTags(){
    let {
      article
    } = this.state

    return article.tags.map(tag => {
      return(
        <>
          <span className="tag">{tag.name}</span>
        </>
      )
    })
  }

  _renderGallery(){
    let {
      article
    } = this.state

    if(article.gallery == null){
      return null
    }
    else if(article.gallery.images.length == 0){
      return null
    }

    let className = article.gallery.type === 'regular' ? 'col-lg-3' : "col-lg-6"

    let images = [...article.gallery.images]

    return (
      <>
        { this._renderMeta() }
        <section className="unpad--top">
          <div className="container">
            <div className="masonry">
              <div className="masonry__container row masonry--active">
                <div className="gallery container">
                  <div className="row">
                    {
                      images.map((image, index) => {
                        return(
                          <div key={ `${index}_${image.id}` }className="col-md-4 col-6 slide mb--30px">
                            <a 
                              href={ image.original } 
                              data-lightbox="Gallery 1" 
                              style ={{ backgroundImage: "url("+getImageUrl(image, "article-gallery")+")" }}
                              priority={this.props.index}
                              className="lazy-background"
                            >
                                &nbsp;
                            </a>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }

  _renderSimilarArticles(){
    let {
      article,
      loadingSimilarArticles,
      similarArticles
    } = this.state

    if(article == null){
      return null
    }

    if(similarArticles.length === 0 && !loadingSimilarArticles){
      return null
    }

    return (
      <>
        <section className="text-center unpad--bottom mb--2">
          <div className="container">
            <div className="row">
              <div className="col-md-10 col-lg-8">
                <h2>Similar Articles</h2>
                <p className="lead">Browse through other similar article</p>
              </div>
            </div>
          </div>
        </section>
        <section className="unpad--top">
          <div className="container">
            <div className="row text-block">
            </div>
            <div className="row justify-content-around">
              <LazyLoadingList
                ref={this.lazyLoadingList}
                endpoint={`${window.Api.Articles}?similar_to_article_id=${article.id}`}
                onItemUpdated={similarArticles => {
                  this.setState({
                    similarArticles,
                    loadingSimilarArticles: false
                  })
                }}
                renderItem={(article) => {
                  return (
                    <ArticleItem
                      article={article}
                    />
                  )
                }}
              />
            </div>
          </div>
        </section>
      </>
    )
  }


  render(){
    let {
      article,
    } = this.state

    let publishedAt = moment(article.published_at).format("DD MMMM Y")

    let categories = article.categories.map(category => category.name)
    return (
      <div className="articles">
        
        <section className={"imagebg text-center height-50"} data-overlay="6" style={{zIndex: 0}}>
          <div className="background-image-holder lazy-background">
            <Img 
              alt="background" 
              img={article.banner} 
              block={"article"}
              priority={this.props.index}
            />
          </div>
          <div className="container pos-vertical-center">
            <div className="row">
              <div className="col-md-10 col-lg-10">
                <h2 className="mt--1">{ article.title }</h2>
                <p className="lead">{ categories.join(", ") }</p>
                <p className="lead">{ publishedAt }</p>
              </div>
            </div>
          </div>
        </section>

        <section className="switchable switchable--switch">
          <div className="container">

            <div className="row justify-content-between">
              <div className="col-md-8 col-lg-9 pre article-body">
                { renderHTML(article.body) }
              </div>
              <div className="col-md-4 col-lg-3 boxed bg--secondary boxed--lg">
                <h3>Category</h3>
                <div className="list-v2-icons tags-v1 pt-0">
                  { categories.map(category => {
                      return (
                        <span className="tag">{category}</span>
                      )
                    })
                  }
                </div>
                { article.tags.length > 0 &&
                  <>
                    <h3 className="mt-3">Tags</h3>
                    <div className="list-v2-icons tags-v1 pt-0">
                      { this._renderTags() }
                    </div>
                  </>
                }
              </div>
            </div>
            {/*
            <div className="row mt-4">
              <div className="col-12">
                <div className="col tags-v1">
                  { this._renderTags() }
                </div>
              </div>
            </div>
            */}

          </div>
        </section>

        { this._renderGallery() }

        { this._renderSimilarArticles() }

      </div>
    )
  }
}
