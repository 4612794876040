import React from "react";

import TextInput from "./TextInput";

import General from "../../../utils/General";

export default class H extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      level: props.level || 1,
      text: props.text,
      color: props.color,
      textSize: props.textSize,
      className: props.className,
      textAlign: props.textAlign,
      editable: props.editable,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  _renderEditable(text, color, className, textAlign, textSize) {
    return (
      <TextInput
        className={className}
        value={text}
        color={color}
        textAlign={textAlign}
        textSize={textSize}
        onUpdated={(text) => this.props.onUpdated(text)}
      />
    );
  }

  render() {
    let { level, text, color, textSize, className, textAlign, editable } =
      this.state;

    let Tag = "h" + level;

    className = className || "";

    if (Tag == "h1" && textSize != (null || undefined)) {
      // if tag is h1 & if texsize has any value
      switch (textSize) {
        case "5em":
          textSize = "fs-xl";
          break;
        case "4.5em":
          textSize = "fs-lg";
          break;
        case "4em":
          textSize = "fs-md";
          break;
        case "3.5em":
          textSize = "fs-sm";
          break;
        default:
          textSize = "fs-xs";
      }
      className += " " + textSize;
    }

    if (editable) {
      return this._renderEditable(
        text,
        color,
        "h" + level + " " + className,
        textAlign,
      );
    }

    return (
      <Tag
        className={className}
        style={{ color, textAlign }}
        dangerouslySetInnerHTML={General.sanitizeData(text)}
      />
    );
  }
}
