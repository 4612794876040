import React from "react";
import "../assets/css/metronic/pages/login/login-1.css";

import moment from "moment"

import { Helmet } from "react-helmet";

import Form from "../components/form/Form";

import Div from "../components/layouts/Div";

import WeekCalendar from "../components/common/WeekCalendar";
import LazyLoadingList from "../components/common/LazyLoadingList";

import General from "../utils/General";
import Backend from "../utils/Backend";
import Currency from "../utils/Currency";

import Notify from "../utils/Notify";
import Website from "../utils/Website";
import AuthManager from "../utils/AuthManager";


var PRIMARY_COLOR = "#37db70"

export default class Booking extends React.Component {
  constructor(props) {
    super(props);

    PRIMARY_COLOR = window.CURRENT_PAGE_PRIMARY_COLOR

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const serviceId = params.get("service_id");
    const formId = params.get("form_id");

    this.state = {
      loading: true,

      formId,
      serviceId,

      website: props.website,

      selectedDate: moment(),
      minDate: moment(),
      maxDate: moment().add(100, "days"),
      availableDays: [],

      openSlots: [],
      loadingSlots: true,

      selectedSlot: null,
      serviceResource: null
    };
  }

  componentDidMount() {
    this._setup();
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  _setup() {
    let { website, serviceId, formId, minDate, maxDate } = this.state;

    Backend.getForm(formId, website.id)
    .then(form => {
      console.log("uuuu", form, formId)
      this.setState({ form })
    })

    Backend.getService(serviceId, website.id)
    .then(service => {
      this.setState({ service })
    })

    Backend.getAvailableDays(serviceId, minDate, maxDate)
    .then(availableDays => {
      this.setState({ availableDays })
    })

    this._loadSlots()
  }

  _loadSlots(){
    let { serviceId, formId, selectedDate } = this.state;

    this.setState({ openSlots: [], loadingSlots: true })
    Backend.getOpenSlots(serviceId, selectedDate)
    .then(openSlots => {
      this.setState({ openSlots, loadingSlots: false })
    })
  }

  _renderSlots(){
    let {
      website,
      service,
      openSlots,
      loadingSlots,
      selectedSlot,
      selectedDate,
      availableDays,
    } = this.state

    let closestAvailableDay = availableDays.length > 0 ? moment(availableDays[0]) : null
    let btnCSSClass = "btn btn--primary w-100"
    if(!selectedSlot){
      btnCSSClass += " disabled"
    }
    return (
      <>

        <WeekCalendar
          selectedDate={selectedDate.toDate()}
          onSelected={date => {
            this.setState({ selectedDate: moment(date) }, () => this._loadSlots())
          }}
          isDisabled={date => {
            let dateStr = moment(date).format("YYYY-MM-DD")
            return availableDays.find(availableDay => availableDay == dateStr) == null
          }}
        />

        <div className="slots m-height">
          { openSlots.length > 0 && (
              <>
                { openSlots.map(slot => {
                    let startsAt = moment(slot.starts_at).format("hh:mma")

                    let isSelected = selectedSlot?.starts_at == slot.starts_at
                    let className = "slot btn btn--secondary"
                    if(isSelected){
                      className += " selected flaticon2-check-mark"
                    }
                    return (
                      <button
                        className={className}
                        onClick={() => {
                          this.setState({
                            selectedSlot: slot,
                            slotConfirmed: false,
                            selectedServiceResource: null,
                            serviceResourceConfirmed: null
                          })
                        }}
                      >
                        {startsAt}
                      </button>
                    )
                  })
                }
              </>
            )
          }
          { (!loadingSlots && openSlots.length == 0) && (
              <>
                <div className={"text-center mt-5 mb-5"}>
                  <h3 className="text-no-availability">
                    No availability for '{service.name}' on {selectedDate.format("MMM Do")}
                  </h3>
                  { (availableDays.length > 0) && (
                    <>
                      <h3 className="text-availability">
                        There is availability on {closestAvailableDay.format("dddd Do MMM")}
                      </h3>
                      <a
                        className="btn btn--primary btn--sm mt-2"
                        onClick={() => {
                          this.setState({
                            selectedDate: closestAvailableDay
                          }, () => this._loadSlots())
                        }}
                      >
                        <span class="btn__text">
                          Go To {closestAvailableDay.format("dddd Do MMM")}
                        </span>
                      </a>
                    </>
                  )}
                </div>
              </>
            )
          }
        </div>

        { openSlots.length > 0 && (
          <>
            <button
              className={btnCSSClass}
              disabled={!selectedSlot}
              onClick={() => {
                this.setState({
                  slotConfirmed: true
                })
              }}
            >
              Continue
            </button>
            </>
          )
        }

      </>
    )
  }

  _renderServiceResourceSelector(){
    let {
      website,

      service,
      selectedSlot,

      selectedServiceResource,
      serviceResourceConfirmed
    } = this.state

    let btnCSSClass = "btn btn--primary w-100"
    if(!selectedServiceResource){
      btnCSSClass += " disabled"
    }

    return (
      <>
        <div class="slots service-resource m-height">
          { selectedSlot.slots.map(slot => {
              return this._renderServiceResourceSlot(slot)
            })
          }
        </div>
        <button
          className={btnCSSClass}
          disabled={!selectedServiceResource}
          onClick={() => {
            this.setState({
              serviceResourceConfirmed: true
            })
          }}
        >
          Continue
        </button>
      </>
    )
  }

  _renderServiceResourceSlot(slot, highlight=true){
    let {
      service,
      selectedServiceResource,
    } = this.state

    let isSelected = selectedServiceResource?.slot_id == slot.slot_id
    let className = "slot btn btn--secondary"
    if(isSelected && highlight){
      className += " selected"
    }
    else if(!highlight){
      className += " unhighlighted"
    }

    let priceLabel = "Free"
    if(slot.price){
      priceLabel = Currency.format(
        slot.price,
        window.CURRENT_WEBSITE_SHOP_CURRENCY || ""
      )
    }

    let startsAtLabel = moment(slot.starts_at).format("HH:mm")
    let endsAtLabel = moment(slot.ends_at).format("HH:mm")

    let duration = `${startsAtLabel} - ${endsAtLabel}`

    let title = `${slot.resource.name} - ${priceLabel}`
    let subtitle = slot.resource.description
    let label = duration

    return (
      <button
        className={className}
        onClick={() => {
          this.setState({
            selectedServiceResource: {
              ...slot,
              service,
              usage_no: 1,
              slot: slot.slot_id,
              service_resource: slot.service_resource_id
            }
          })
        }}
      >
        { slot.resource.image &&
          <img src={slot.resource.image.thumbnail}/>
        }

        <b className="title">
            {title}<small>{subtitle}</small>
        </b>

        <b className="time">
          {label}
        </b>

      </button>
    )
  }

  _renderPortalPlaceholder(id, className) {
    return <div id={id} className={className}></div>;
  }

  _renderContent(){
    let {
      form,
      service,
      selectedDate,
      availableDays,

      selectedSlot,
      slotConfirmed,

      selectedServiceResource,
      serviceResourceConfirmed
    } = this.state

    if(!service || !form){
      return null
    }

    let showSlotSelector = !slotConfirmed
    let showResourceSelector = slotConfirmed && !serviceResourceConfirmed
    let showForm = serviceResourceConfirmed

    let data = {
      settings: {
        senary_color: PRIMARY_COLOR
      }
    }

    let title = "Select Date & Time"
    if(showResourceSelector){
      title = "Select Resource"
    }
    if(showForm){
      title = "Complete Booking"
    }

    return (
      <>

          <h2>{title}</h2>
          { showSlotSelector && this._renderSlots() }
          { showResourceSelector && this._renderServiceResourceSelector() }
          { showForm &&
            <>
              <div class="slots service-resource">
                { this._renderServiceResourceSlot(selectedServiceResource, false) }
              </div>
              <div
                className="boxed boxed--border form-container form-crm"
              >
                <Form
                  data={data}
                  form={form}
                  hideBookingField={true}
                  preConfirguredBooking={selectedServiceResource}
                />
              </div>
            </>
          }

      </>
    )
  }

  render(){
    let {
      form,
      loading,
    } = this.state

    return (
      <div className="main-container page booking">
        <Div
          className="container appointment"
          disabled={false}
          spinner={true}
          loadingText={() =>  (
            <div className="text-center">
              Processing...
              <br/>
              <span style={{ fontSize: 20 }}>(Please don't refresh your page)</span>
            </div>
          )}
        >
          { this._renderContent() }
        </Div>
        {this._renderPortalPlaceholder("portal-modal")}
      </div>
    )
  }

}
