import React from 'react'

import Collapsible from 'react-collapsible';
import Border from '../Border'

import H2 from './editable/H2'
import Img, { Sizes } from './editable/Img'
import P from './editable/P'
import Span from './editable/Span'
import Section from './editable/Section'
import Socials from './editable/Socials'

import General from '../../utils/General'
import Backend from '../../utils/Backend'

export default class Block27 extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      data: props.data,
      editable: props.editable
    }

    this.updateContentBlock = General.debounce(() => {
      console.log("3333", this.state.data)
      Backend.updateContentBlock(this.state.data)
    }, 1000, false)
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _update(data, callback){
    this.setState({ data }, () => {

      if(callback){
        callback()
      }
      this.updateContentBlock()
    })
  }

  _getModalContent(data){
    if(data.socials == null){
      data.socials = {}
    }
    return (
      <Border>
        <Collapsible
          trigger="Social Links"
        >
          <br/>
          { this._renderSocialInput("Email", "mail", data.socials.mail, data)}
          { this._renderSocialInput("Website", "website", data.socials.website, data)}
          { this._renderSocialInput("Twitter", "twitter", data.socials.twitter, data)}
          { this._renderSocialInput("Instagram", "instagram", data.socials.instagram, data)}
          { this._renderSocialInput("LinkedIn", "linkedIn", data.socials.linkedIn, data)}
        </Collapsible>
      </Border>
    )
  }

  _renderSocialInput(title, key, value, data){
    return (
      <>
        <span>
          { title }
        </span>
        <input
            type="text"
            defaultValue={ value }
            onChange={event => {
              data.socials[key] = event.target.value
              this._update(data)
            }}
        />
        <br/><br/>
      </>
    )
  }

  render(){
    let { data, editable } = this.state
    let showTitlesBlock = data.title || data.subtitle
    let showTitle = data.title
    let showSubtitle = data.subtitle
    return (
      <Section
          data={ data }
          index={ this.props.index }
          className={ "block-27 switchable feature-large " + this.props.className }
          editable={ editable }
          onUpdated={data => this._update(data)}
          onDelete={() => this.props.onDelete()}
          primaryColor={false}
          onAddContentBlockPressed={contentBlock => this.props.onAddContentBlockPressed(contentBlock)}
          renderModalContent={() => this._getModalContent(data)}
          canMoveUp={ this.props.canMoveUp }
          canMoveDown={ this.props.canMoveDown }
          onVisibilityButtonPressed={(index) => this.props.onVisibilityButtonPressed(index)}
          onMoveUpPressed={ () => this.props.onMoveUpPressed() }
          onMoveDownPressed={ () => this.props.onMoveDownPressed() }
      >
          <div className="container">
              <div className="row justify-content-between">
                  <div className="col-md-6">
                      <Img
                        className="border--round"
                        img={ data.foreground_image }
                        block={27}
                        priority={this.props.index}
                        alt={ "" }
                        editable={ editable }
                        onUpdated={image => {
                          data.foreground_image = image
                          this._update(data)
                        }}
                      />
                  </div>
                  <div className="col-md-6 col-lg-5">
                      <div className="switchable__text">

                        { showTitlesBlock &&
                          <div className="text-block">
                            {showTitle &&
                              <H2
                                text={ data.title}
                                color={ data.title_color }
                                editable={ editable }
                                textAlign="left"
                                onUpdated={text => {
                                  data.title = text
                                  this._update(data)
                                }}
                              />
                            }
                            {showSubtitle &&
                              <Span
                                text={ data.subtitle}
                                color={ data.subtitle_color }
                                editable={ editable }
                                textAlign="left"
                                onUpdated={text => {
                                  data.subtitle = text
                                  this._update(data)
                                }}
                              />
                            }
                          </div>
                        }

                          <P
                            text={ data.body }
                            color={ data.settings.secondary_color }
                            editable={ editable }
                            textAlign="left"
                            onUpdated={text => {
                              data.body = text
                              this._update(data)
                            }}
                          />
                          <Socials socials={ data.socials }/>
                      </div>
                  </div>
              </div>
          </div>
      </Section>
    )
  }
}
