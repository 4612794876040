import React from 'react'

import General from '../../../utils/General'
import PortalModal from "../PortalModal";

export default class DeleteModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      imageInfo: props.imageInfo,
      isLoading: false,
      showModal: false,
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  render(){

    return (
      <PortalModal
        show={this.state.showModal}
        showAsSideMenu={"false"}
        onHide={() => this.props.onHide()}
      >
        <div className="modal-share">

          <div className="modal-head">
            <h2 className="mb-0">Delete Block</h2>
          </div>

          <div className="row modal-body">
            <div className="col-md-12">

              <h4>
                Are you sure you want to delete this block. <br></br>
                You cannot undo this action.
              </h4>

            </div>
          </div>

          <div className="text-right modal-footer">
            <a
              className="btn type--uppercase"
              onClick={() => {
                this.props.onHide()
              }}>
                <span className="btn__text">
                    Cancel
                </span>
            </a>
            <a
              className="btn btn--primary type--uppercase"
              style={{
                background: "#fa6160",
                borderColor: "#fa6160"
              }}
              onClick={() => {
                this.props.onDelete()
              }}>
                <span className="btn__text">
                    Delete
                </span>
            </a>
          </div>

        </div>

        
      </PortalModal>
    )
  }
}
