import React from 'react'

import Collapsible from 'react-collapsible';
import Border from '../Border'
import Button from '../Button'

import H1 from './editable/H1'
import P from './editable/P'
import YouTube from 'react-youtube';
import Dropdown from '../Dropdown';

import { DEFAULT_PRIMARY_BUTTON, DEFAULT_SECONDARY_BUTTON } from './editable/Section'

import ImageUploader from './editable/ImageUploader'

import H5 from './editable/H5'
import Img from './editable/Img'

import Span from './editable/Span'
import BlockQuote from './editable/BlockQuote'
import Section from './editable/Section'

import TextInfoInputItem from '../sectioninputs/TextInfoInputItem'

import General from '../../utils/General'
import Backend from '../../utils/Backend'

import Titles from './editable/Titles';

let VIDEO_SIZE = [
  {
    value: "large",
    label: "Large"
  },
  {
    value: "small",
    label: "Small"
  },
]

export default class Block52 extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      key: 0,
      data: props.data,
      editable: props.editable,
      uuid: "cb_"+General.uuid()
    }

    this.section = React.createRef();

    this.updateContentBlock = General.debounce(() => {
      console.log("3333", this.state.data)
      Backend.updateContentBlock(this.state.data)

      this.setState({
        key: this.state.key + 1
      }, () => General.updateSlides())
    }, 1000, false)
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _update(data, callback){
    let {
      key
    } = this.state

    this.setState({ ...data }, () => {

      if(callback){
        callback()
      }
      this.updateContentBlock()
    })
  }

  _getModalContent(data){

    return (
      <>

        <Border>
          <Collapsible trigger="General Options">
            <br/>
            { this._renderToggle('body', 'Text Under Video', "Hover and click into this text to change it your own text to suit your website.")}
            { this._renderToggle('primary_button', 'Primary Button', DEFAULT_PRIMARY_BUTTON)}
            { this._renderToggle('secondary_button', 'Secondary Button', DEFAULT_SECONDARY_BUTTON)}
          </Collapsible>
        </Border>

        <Border>
          <Collapsible trigger="Video">
            <br/>
            <span>Size</span>
            <Dropdown
              options={VIDEO_SIZE}
              value={VIDEO_SIZE.find(mode => mode.value === data.variation)}
              onChange={option => {
                data.variation = option.value
                this._update(data)
              }}
              placeholder="Video Size"
            />
            <br/><br/>
            <span>Youtube Video URL</span>
            <input
                type="text"
                defaultValue={ data.background_video.url }
                onChange={event => {
                  data.background_video.url = event.target.value
                  this._update(data, () => {
                    General.updateVideo()
                    General.updateImageBackgrounds()
                  })
                }}
            />
            <br/><br/>
            <ImageUploader
              title={ "Place an image over your video player when the video is not playing" }
              titleTag="span"
              src={data.foreground_image ? data.foreground_image.original : null}
              onUploaded={image => {
                data.foreground_image = image
                this._update(data, () => {
                  General.updateVideo()
                  General.updateImageBackgrounds()
                })
              }}
            />
            <br/><br/>
          </Collapsible>
        </Border>

      </>
    )
  }

  _renderToggle(key, title, defaultOnValue, defaultOffValue=null, callback=null, onChange=null){
    let {
      data
    } = this.state

    let checked = data[key] != null && data[key] != false
    if(key == "overlay"){
      checked = data.settings.overlay > 0
    }

    let labelId = "tg_"+data.id+"__"+key+"__cb_pb"
    return (
      <div class="row">
        <div class="col-md-9">
          <h4>{ title }</h4>
        </div>
        <div class="col-md-3 text-right">
          <div class="input-checkbox input-checkbox--switch">
            <input
              id={labelId}
              type="checkbox"
              checked={checked}
              onChange={e => {
                if(onChange){
                  onChange(e)
                  return
                }
                data[key] = e.target.checked ? defaultOnValue : defaultOffValue
                this._update(data, callback)
              }}
            />
            <label for={labelId}></label>
          </div>
        </div>
      </div>
    )
  }

  render(){
    let { data, editable, uuid, key } = this.state

    let videoUrl = data.background_video?.url
    let videoId = General.getYoutubeVideoId(videoUrl)

    let textAlign = "center"
    let videoContainerClassName = "col-md-8 col-lg-6"

    if(data.variation === "large"){
      videoContainerClassName = " col-md-12 col-lg-10"
    }

    return (
      <Section
          ref={this.section}
          data={ data }
          index={ this.props.index }
          className={ " block-52 text-center " + this.props.className }
          editable={ editable }
          onUpdated={data => this._update(data)}
          onDelete={() => this.props.onDelete()}
          primaryColor={false}
          renderModalContent={() => this._getModalContent(data)}
          onAddContentBlockPressed={contentBlock => this.props.onAddContentBlockPressed(contentBlock)}
          canMoveUp={ this.props.canMoveUp }
          canMoveDown={ this.props.canMoveDown }
          onVisibilityButtonPressed={(index) => this.props.onVisibilityButtonPressed(index)}
          onMoveUpPressed={ () => this.props.onMoveUpPressed() }
          onMoveDownPressed={ () => this.props.onMoveDownPressed() }
      >
          <div class="container">

            <Titles
              data={ data }
              editable={ editable }
              onUpdated={data => this._update(data)}
            />

            {/* stats : Video row */}
            <div class="row mt-4" key={videoId+data.foreground_image?.id}>
              <div class={videoContainerClassName}>
                <div class="video-cover border--round">
                  <div class="background-image-holder">
                      <Img
                        alt="image"
                        img={ data.foreground_image }
                        block={52}
                        priority={this.props.index}
                      />
                   </div>
                  <div class="video-play-icon"></div>
                  <iframe
                    width="100%"
                    height="100%"
                    src={`https://www.youtube-nocookie.com/embed/${videoId}?controls=0&amp;autoplay=1&showinfo=0&mute=0&loop=1&playlist=${videoId}`}
                    frameborder="0"
                    allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                  />
                </div>
                { data.body &&
                <div>
                  <Span
                    text={ data.body }
                    color={ data.settings.secondary_color }
                    editable={ editable }
                    onUpdated={text => {
                      data.body = text
                      this._update(data)
                    }}
                  />
                </div>
                }
              </div>
            </div>
            {/* stats : Video end */}


            {/* stats : Buttons row */}
            <div className="row mt-4">
              <div className="col-md-10 col-lg-8">

                <div className="c-btn-group">
                  <Button
                      data={data.secondary_button}
                      type="secondary"
                      editable={ editable }
                      renderModal={content => this.props.renderModal(content)}
                      onUpdated={button => {
                        data.secondary_button = button
                        this._update(data)
                      }}
                  />

                  <Button
                      data={data.primary_button}
                      editable={ editable }
                      renderModal={content => this.props.renderModal(content)}
                      onUpdated={button => {
                        data.primary_button = button
                        this._update(data)
                      }}
                  />

                </div>

              </div>
            </div>
            {/* end : Buttons row */}

          </div>
      </Section>
    )
  }
}
