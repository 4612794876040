import React from 'react'
import Collapsible from 'react-collapsible';
import Border from '../Border'

import H5 from './editable/H5'
import Img from './editable/Img'
import P from './editable/P'
import Span from './editable/Span'
import BlockQuote from './editable/BlockQuote'
import Section from './editable/Section'
import Titles from './editable/Titles';

import AsyncSelect from '../common/AsyncSelect'
import ServiceItem from "../common/ServiceItem";

import TextInfoInputItem from '../sectioninputs/TextInfoInputItem'

import General from '../../utils/General'
import Backend from '../../utils/Backend'

export default class Block56 extends React.Component {
  constructor(props){
    super(props)

    let form = props.data.forms ? props.data.forms[0] : null

    this.state = {
      form,
      key: 0,
      data: props.data,
      services: [],
      editable: props.editable,
      uuid: "cb_"+General.uuid(),
      websiteId: props.websiteId,
    }

    this.section = React.createRef();

    this.updateContentBlock = General.debounce(() => {
      let data = JSON.parse(JSON.stringify(this.state.data))
      let formIds = data.forms.map(form => {
        return form.id
      })
      data.forms = formIds
      console.log("3333", data)
      Backend.updateContentBlock(data)

      this.setState({
        key: this.state.key + 1
      }, () => General.updateSlides())
    }, 1000, false)
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  componentDidMount(){
    this._loadServices()
  }

  _loadServices(){
    let {
      data,
      form,
      websiteId
    } = this.state

    if(!form){
      return
    }

    Backend.getServices(websiteId, form.id)
    .then(services => {
      this.setState({
        services,
        loadingServices: false
      }, () => General.updateImageBackgrounds())
    }).catch(e => {
      // Notify.error(e.message)
    })
  }

  _update(data, callback){
    let {
      key
    } = this.state

    this.setState({ ...data }, () => {

      if(callback){
        callback()
      }
      this.updateContentBlock()
    })
  }

  _getModalContent(data){
    return (
      <>
        <Border>
          <Collapsible
            trigger="Booking Form"
          >
            <br/>
            <p>Select a Booking form to tailor the Services that are displayed and information collected</p>
            {this._renderFormPicker(data)}
          </Collapsible>
        </Border>
      </>
    )
  }

  _getFormOption(form){
    if(!form){
      return null
    }
    return {
      label: form.name,
      value: form.id,
      data: form
    }
  }

  _renderFormPicker(data){
    let { form } = data
    return (
      <AsyncSelect
        endpoint={window.Api.Forms}
        filter={`website_id=${window.CURRENT_WEBSITE.id}&booking=true`}
        classNamePrefix="async-select-paginate"
        value={this._getFormOption(form)}
        placeholder="Please Select A Booking Form"
        onSelected={form => {
          console.log("£££", form)
          data.forms = [form]
          this.setState({ data, form })
          this._update(data, () => this._loadServices())
        }}
        getOptions={options => options.map(option => this._getFormOption(option))}
        noOptionsMessage={() => "No Booking Forms Found"}
      />
    )
  }


  _renderServices() {
    let { form, services } = this.state

    return services.map(service => {
      return (
        <ServiceItem service={service} form={form}/>
      )
    })
  }


  render() {
    let { data, form, editable, uuid, key, showSettingsModal } = this.state


    if(!editable && !form){
      return null
    }

    let currentFormId = form?.id

    return (
      <Section
          ref={this.section}
          data={ data }
          index={ this.props.index }
          className={ " block-56 text-center " + this.props.className }
          editable={ editable }
          onUpdated={data => this._update(data)}
          onDelete={() => this.props.onDelete()}
          showSettingsModal={showSettingsModal}
          onAddContentBlockPressed={contentBlock => this.props.onAddContentBlockPressed(contentBlock)}
          renderModalContent={() => this._getModalContent(data)}
          canMoveUp={ this.props.canMoveUp }
          canMoveDown={ this.props.canMoveDown }
          onVisibilityButtonPressed={(index) => this.props.onVisibilityButtonPressed(index)}
          onMoveUpPressed={ () => this.props.onMoveUpPressed() }
          onMoveDownPressed={ () => this.props.onMoveDownPressed() }
      >
        <div class="container">

          <Titles
            data={ data }
            editable={ editable }
            onUpdated={data => this._update(data)}
            headingNumber={2}
          />

          { form ? (
              <div className='row items text-left'>

                {this._renderServices()}

              </div>
            ) : (
              <div className="row">
                  <div className="col-md-4 mx-auto">
                      <div className="alert-addproduct feature feature-3 boxed boxed--lg boxed--border unmarg--bottom">

                        <div className="icon-div text-center">
                          <span className="position-relative inline-block">
                            <i className="icon icon-Danger icon--lg color--white"></i>
                          </span>
                        </div>
                        <h5 className="my-5 text-center color--white">SELECT A BOOKING FORM IN ORDER TO DISPLAY SERVICES / COLLECT INFORMATION</h5>
                        <a
                          target="_blank"
                          className="btn btn--white w-100"
                          onClick={() => {
                            this.setState({ showSettingsModal: true })
                          }}
                        >
                          SELECT BOOKING FORM
                        </a>
                      </div>
                  </div>
                </div>
            )
          }

        </div>
      </Section>
    )
  }
}
