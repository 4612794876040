import React from 'react'

import DeleteModal from '../modal/settings/DeleteModal'
import Collapsible from 'react-collapsible';
import Border from '../Border'

import H5 from './editable/H5'
import { getImageUrl, Sizes } from './editable/Img'

import Span from './editable/Span'

import Section from './editable/Section'

import ImageInfoInputItem from '../sectioninputs/ImageInfoInputItem'

import General from '../../utils/General'
import Backend from '../../utils/Backend'

import Titles from './editable/Titles';

export default class Block25 extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      key: 0,
      data: props.data,
      editable: props.editable
    }

    this.section = React.createRef();

    this.updateContentBlock = General.debounce(() => {
      Backend.updateContentBlock(this.state.data)
    }, 1000, false)
  }


  _update(data, callback){
    this.setState({ data }, () => {

      if(callback){
        callback()
      }
      this.updateContentBlock()
    })
  }


  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _addImageInfo(data){
    let url = "https://d1tv988a92fn4r.cloudfront.net/companies/2/websites/images/original_image_06-08-2019_16:54:55.png"
    let imageInfo = {
      title: "Title goes here",
      subtitle: "Subtitle goes here",
      image: {
        original: url,
        thumbnail: url,
        banner: url,
        type: "photo"
      },
      settings: {}
    }

    data.image_infos.push(imageInfo)
    this._update(data)
  }

  _renderModalContent(data){
    return (
      <Border>
        <Collapsible
          trigger="Team"
        >
          <br/>
          { this._renderModalContentList(data) }
        </Collapsible>
      </Border>
    )
  }

  _renderModalContentList(data){
    let imageInfos = data.image_infos
    let imageInfosContent = imageInfos.map((imageInfo, index) => {
      return (
        <ImageInfoInputItem
          key={index}
          index={index}
          imageInfo={imageInfo}
          onTextUpdated={imageInfo => {
            data.image_infos[index] = imageInfo
            this._update(data)
          }}
          onUpdated={imageInfo => {
            data.image_infos[index] = imageInfo
            this._update(data)
          }}
          onDelete={() => {
            if(data.image_infos.length == 3){
              alert("There must be at least 3 items")
              return
            }
            imageInfos.splice(index, 1)
            data.image_infos = imageInfos
            this._update(data)
          }}
        />
      )
    })

    return (
      <Border>
          { imageInfosContent }
          <div className="row">
            <div className="col-md-12">
              <a className="btn"
                onClick={() => this._addImageInfo(data)}
              >
                  <span className="btn__text">
                      Add Item
                  </span>
              </a>
            </div>
          </div>
      </Border>
    )
  }

  _renderImageInfos(data, key, editable){
    let imageInfos = data.image_infos
    if(!imageInfos){
      return null
    }

    return imageInfos.map((imageInfo, index) => {
      return (
        <div key={ imageInfo.id } className="col-md-4 item image-info-item">
            <div className="feature feature-8">
                <div
                  className="bg-img-rounded lazy-background"
                  style ={{ backgroundImage: "url("+getImageUrl(imageInfo.image, 25)+")" }}
                  priority={this.props.index}
                ></div>
                <H5
                  text={ imageInfo.title }
                  color={ data.settings.primary_color }
                  editable={ editable }
                  onUpdated={text => {
                    imageInfo.title = text
                    imageInfos[index] = imageInfo
                    data.image_infos = imageInfos
                    this._update(data)
                  }}
                />
                <Span
                  text={ imageInfo.subtitle }
                  color={ data.settings.secondary_color }
                  editable={ editable }
                  onUpdated={text => {
                    imageInfo.subtitle = text
                    imageInfos[index] = imageInfo
                    data.image_infos = imageInfos
                    this._update(data)
                  }}
                />
            </div>
        </div>
      )
    })
  }

  render(){
    let { key, data, editable } = this.state
    let blockClassName = " block-25 text-center ";
    if (window.IS_CARD_STYLE_WEBSITE) {
      blockClassName += " deep-cardify";
    }
    return (
      <Section
          ref={ this.section }
          data={ data }
          index={ this.props.index }
          className={ blockClassName +this.props.className }
          editable={ editable }
          onUpdated={data => this._update(data)}
          onDelete={() => this.props.onDelete()}
          onAddContentBlockPressed={contentBlock => this.props.onAddContentBlockPressed(contentBlock)}
          renderModalContent={() =>  this._renderModalContent(data)}
          primaryColor={true}
          secondaryColor={true}
          canMoveUp={ this.props.canMoveUp }
          canMoveDown={ this.props.canMoveDown }
          onVisibilityButtonPressed={(index) => this.props.onVisibilityButtonPressed(index)}
          onMoveUpPressed={ () => this.props.onMoveUpPressed() }
          onMoveDownPressed={ () => this.props.onMoveDownPressed() }
      >
          <div className="container items">
            <Titles
              data={ data }
              editable={ editable }
              onUpdated={data => this._update(data)}
              headingNumber={2}
            />
              <div key={ key } className="row dc-row-v3">
                  { this._renderImageInfos(data, key, editable) }
              </div>
          </div>
      </Section>
    )
  }
}
