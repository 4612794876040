import React from 'react'

import Collapsible from 'react-collapsible';

import ImageUploader from './blocks/editable/ImageUploader'

import Dropdown from './Dropdown';

import LogoMaker from './LogoMaker';

import Border from './Border';
import ColorPicker from './ColorPicker';
import FontSelect from './FontSelect';
import IconPickerModal from './modal/IconPickerModal';

let OPTIONS_LOGO_TYPE = [
  {
    value: "false",
    label: "Upload My Logo"
  },
  {
    value: "true",
    label: "Create Logo"
  }
]

let OPTIONS_LOGO_POSITION = [
  {
    value: "left",
    label: "Display Logo Left"
  },
  {
    value: "center",
    label: "Display Logo Centre"
  }
]

let OPTIONS_LOGO_SIZES = [
  {
    value: 15,
    label: "Small"
  },
  {
    value: 22,
    label: "Medium"
  },
  {
    value: 30,
    label: "Large"
  },
  {
    value: 40,
    label: "X Large"
  },
  {
    value: 50,
    label: "XX Large"
  }
]

export default class LogoEditor extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      menu: props.menu
    }

  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _renderLogoUpload(menu){
    if(menu.logo_custom !== false){
      return null
    }

    return (
      <>
        <Border darkMode={false}>
          { this._renderModalImageUploader(
            menu.secondary_image,
            "Light Logo",
            "Displayed when on transparent background",
            secondaryImage => {
              menu.secondary_image = secondaryImage
              this.setState({ menu })
              this.props.onUpdated(menu)
            }
          )}
        </Border>

        <Border darkMode={false}>
          { this._renderModalImageUploader(
            menu.primary_image,
            "Darker Logo",
            "Displayed when on solid background colour (such as white)",
            primaryImage => {
              menu.primary_image = primaryImage
              this.setState({ menu })
              this.props.onUpdated(menu)
            }
          )}
        </Border>
      </>
    )
  }

  _renderModalImageUploader(image, title, subtitle, onUpdated){
    return (
      <>
        <h3 className="mb-0">{title}</h3>
        <label className="mb-3">{subtitle}</label>
        <ImageUploader
          title={ false}
          src={image ? image.original : null}
          closeOnUploaded={false}
          onUploaded={image => onUpdated(image)}
        />
      </>
    )
  }

  render() {
    let { menu } = this.state

    let logoCustom = menu.logo_custom != null ? menu.logo_custom.toString() : null
    let slectedLogoType = OPTIONS_LOGO_TYPE.find(logoType => logoType.value === logoCustom )
    let slectedLogoPosition = OPTIONS_LOGO_POSITION.find(logoPosition => logoPosition.value === menu.logo_position )
    let selectedLogoSize = OPTIONS_LOGO_SIZES.find(logoSize => logoSize.value === menu.logo_size )

    return (
      <Border>

        <Collapsible
          trigger="Logo"
          triggerWhenOpen="Logo"
        >
          
          <br/>
          <label className="mb-3">You can upload your own or create a quick logo</label>
          <br/>

          <span>
            Logo Type
          </span>
          <Dropdown
              options={OPTIONS_LOGO_TYPE}
              value={slectedLogoType}
              onChange={option => {
                menu.logo_custom = option.value === "true"
                if(menu.logo_custom && !menu.primary_logo){
                  menu.primary_logo = {
                    icon: "icon-Atom",
                    font: {
                      family_name: "Open Sans"
                    }
                  }
                  menu.secondary_logo = { ...menu.primary_logo }
                }
                this.setState({ menu })
                this.props.onUpdated(menu)
              }}
              placeholder="Select An Option"
          />

          <br/>

          <span>
            Logo Size
          </span>
          <Dropdown
            options={OPTIONS_LOGO_SIZES}
            value={selectedLogoSize}
            onChange={option => {
              menu.logo_size = option.value
              this.setState({ menu })
              this.props.onUpdated(menu)
            }}
            placeholder="Logo Size"
          />


          <br/>

          { menu.logo_custom != null &&
            <>
              <span>
                Logo Position
              </span>
              <Dropdown
                  options={OPTIONS_LOGO_POSITION}
                  value={slectedLogoPosition || "left"}
                  onChange={option => {
                    menu.logo_position = option.value
                    this.setState({ menu })
                    this.props.onUpdated(menu)
                  }}
                  placeholder="Select An Option"
              />

              <br/>
            </>
          }

          <div className="upload-my-logo">
            {this._renderLogoUpload(menu)}
          </div>

          <LogoMaker
            show={menu.logo_custom}
            logo={menu.secondary_logo}
            logoSize={menu.logo_size}
            onUpdated={logo => {
              menu.secondary_logo = logo
              menu.primary_logo.icon = logo.icon
              menu.primary_logo.text = logo.text
              menu.primary_logo.style = logo.style
              menu.primary_logo.font = logo.font
              menu.primary_logo.text_size = logo.text_size
              this.setState({ menu })
              this.props.onUpdated(menu)
            }}
          />

          <LogoMaker
            show={menu.logo_custom}
            logo={menu.primary_logo}
            logoSize={menu.logo_size}
            backgroundColor={menu.settings.tertiary_color}
            logoDarkMode={true}
            onUpdated={logo => {
              menu.primary_logo = logo
              menu.secondary_logo.icon = logo.icon
              menu.secondary_logo.text = logo.text
              this.setState({ menu })
              this.props.onUpdated(menu)
            }}
          />

        </Collapsible>

      </Border>
    )
  }
}


const styles = {
  colorBoxStyle: {
    minWidth: 150,
    width: 150,
    marginRight: 0
  },
  colorTextStyle: {
    overflow: 'hidden',
    minWidth: 90,
    width: 90
  }
}
