import runtimeEnv from '@mars/heroku-js-runtime-env';

const env = runtimeEnv();

window.General = {};

window.General.IS_DEBUG = env.REACT_APP_IS_DEBUG;

window.General.WebsiteNakedDomain = env.REACT_APP_WEBSITE_NAKED_DOMAIN

window.General.ClientDashboardUrl = "http://dashboard." + window.General.WebsiteNakedDomain
