import React from "react";

import General from "../../../utils/General";

import TextInput from "./TextInput";

export default class P extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      text: props.text,
      color: props.color,
      textAlign: props.textAlign,
      className: props.className || "",
      minRows: props.minRows,
      editable: props.editable,
      lead: props.lead,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  _renderEditable(text, color, textAlign, className, minRows) {
    return (
      <TextInput
        className={"p " + className}
        value={text}
        color={color}
        textAlign={textAlign}
        minRows={minRows}
        onUpdated={(text) => this.props.onUpdated(text)}
      />
    );
  }

  render() {
    let { text, color, textAlign, className, minRows, editable, lead } =
      this.state;

    if (lead !== false) {
      className += " lead";
    }
    if (editable) {
      return this._renderEditable(
        text,
        color,
        textAlign,
        className,
        minRows,
      );
    }
    return (
      <>
        <p
          className={className + " pre "}
          style={{ color, textAlign }}
          dangerouslySetInnerHTML={General.sanitizeData(text)}
        />
      </>
    );
  }
}
