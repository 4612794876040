import React from "react";

import Collapsible from "react-collapsible";

import StackGrid from "react-stack-grid";

import { Container, Row, Col } from "react-grid-system";

import Gallery from "react-photo-gallery";

import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import General from "../../utils/General";
import Backend from "../../utils/Backend";

import Border from "../Border";

import Section from "./editable/Section";

import { getImageUrl, Sizes } from "./editable/Img";

import ImageInputItem from "../sectioninputs/ImageInputItem";

import VideoThumbnail from "../VideoThumbnail";

import VideoModal from "../modal/VideoModal";

import videoIcon from "../../assets/icons/video-icon.png";

import Titles from './editable/Titles';

export default class Block24 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      key: 0,
      data: props.data,
      editable: props.editable,
      photoIndex: 0,
      isOpen: false,
    };

    this.section = React.createRef();

    this.updateContentBlock = General.debounce(
      () => {
        Backend.updateContentBlock(this.state.data);
      },
      1000,
      false
    );
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  _update(data, callback) {
    this.setState({ ...data }, () => {
      if (callback) {
        callback();
      }
      this.updateContentBlock();
    });
  }

  _renderModalContent(data) {
    data.variation = data.variation || "slider";
    data.extra = data.extra || {};

    return (
      <>
        <Border>
          <Collapsible trigger="Layout">
            <br />
            <select
              value={data.variation || "slider"}
              onChange={(event) => {
                data.variation = event.target.value;
                if (data.variation == "grid") {
                  data.extra.columns = "4";
                  data.extra.gutter = "20";
                  data.extra.aspectRatio = "1/1";
                } else if (data.variation == "masonry") {
                  data.extra.gutter = "5";
                } else if (data.variation == "waterfall") {
                  data.extra.gutter = "1";
                }

                this._update(data, () => {
                  this.setState(
                    {
                      key: this.state.key + 1,
                    },
                    () => General.updateAll()
                  );
                });
              }}
            >
              <option value="slider">Slider</option>
              <option value="grid">Grid (Instagram)</option>
              <option value="masonry">Masonry (Google Photos)</option>
              <option value="waterfall">Waterfall (Pinterest)</option>
            </select>
            {data.variation === "grid" && (
              <Border>
                <Collapsible trigger="# of Columns">
                  <br />
                  <select
                    value={data.extra.columns || "4"}
                    onChange={(event) => {
                      data.extra.columns = event.target.value;
                      this._update(data);
                    }}
                  >
                    <option value="2">6 Columns</option>
                    <option value="3">4 Columns</option>
                    <option value="4">3 Columns</option>
                    <option value="6">2 Columns</option>
                    <option value="12">1 Column</option>
                  </select>
                </Collapsible>
              </Border>
            )}
            {data.variation === "waterfall" && (
              <Border>
                <Collapsible trigger="Column Width">
                  <br />
                  <select
                    value={data.extra.width || "250"}
                    onChange={(event) => {
                      data.extra.width = event.target.value;
                      this._update(data);
                    }}
                  >
                    <option value="200">Small</option>
                    <option value="250">Regular</option>
                    <option value="350">Large</option>
                  </select>
                </Collapsible>
              </Border>
            )}
            {data.variation === "grid" && (
              <Border>
                <Collapsible trigger="Aspect Ratio">
                  <br />
                  <select
                    value={data.extra.aspectRatio || "1/1"}
                    onChange={(event) => {
                      data.extra.aspectRatio = event.target.value;
                      this._update(data);
                    }}
                  >
                    <option value="16/9">Landscape</option>
                    <option value="1/1">Square</option>
                    <option value="9/16">Portait</option>
                  </select>
                </Collapsible>
              </Border>
            )}
            {data.variation !== "slider" && (
              <Border>
                <Collapsible trigger="Gutter Size">
                  <br />
                  <select
                    value={data.extra.gutter || "5"}
                    onChange={(event) => {
                      data.extra.gutter = event.target.value;
                      this._update(data);
                    }}
                  >
                    <option value="0">No Gutter</option>
                    <option value="1">Super Thin</option>
                    <option value="5">Medium</option>
                    <option value="20">Large</option>
                    <option value="30">Extra Large</option>
                  </select>
                </Collapsible>
              </Border>
            )}
          </Collapsible>
        </Border>
        <Border>
          <Collapsible trigger="Images/Videos">
            <br />
            {this._renderModalContentImages(data)}
          </Collapsible>
        </Border>
      </>
    );
  }

  _renderModalContentImages(data) {
    let videoUrls = this._getVideoUrls(data);

    let images = data.images;

    return images.map((image, index) => {
      const videoUrl = this._getVideoUrl(videoUrls, index);

      return (
        <>
          <ImageInputItem
            key={image.id}
            index={index}
            image={image}
            name="Image/Video"
            onUpdated={(uploadedImage) => {
              data.images[index] = uploadedImage;
              this._update(
                {
                  data,
                  key: this.state.key + 1,
                },
                () => General.updateSlides()
              );
            }}
            onDelete={() => {
              if (data.images.length == 2) {
                alert("There must be at least 2 images");
                return;
              }

              images.splice(index, 1);
              data.images = images;
              videoUrls.splice(index, 1);
              data.extra.video_urls = videoUrls;

              this._update(
                {
                  data,
                  key: this.state.key + 1,
                },
                () => General.updateSlides()
              );
            }}
            renderHeader={() => this._renderVideoInputContent(videoUrls, videoUrl, index, data)
            }
          />
        </>
      );
    });
  }

  _getStateForVideoUrl(videoUrl, index) {
    return videoUrl
      ? { showVideoModal: true, videoUrl }
      : { isOpen: true, photoIndex: index, videoUrl: null };
  }

  _getVideoUrl(videoUrls, index) {
    if (!videoUrls || videoUrls.length < index) {
      return null;
    }

    return videoUrls[index];
  }

  _getVideoUrls(data) {
    let images = data.images;

    return images.map((image, index) => {
      return this._getVideoUrl(data.extra?.video_urls, index);
    });
  }

  _renderVideoInputContent(videoUrls, videoUrl, index, data) {
    return (
      <div className="mb-3">
        <span>Youtube Video URL</span>
        <input
          type="text"
          value={videoUrl}
          placeholder="https://www.youtube.com/watch?v=DGQwd1_dpuc"
          onChange={(event) => {
            videoUrls[index] = event.target.value || null;

            data.extra.video_urls = videoUrls;

            this._update(data);
          }}
        />
      </div>
    );
  }

  _renderVideoIcon(video, position) {
    return (
      video && (
        <span
          style={{
            position: "absolute",
            top: 5,
            right: position,
            cursor: "pointer",
          }}
        >
          <img src={videoIcon} alt="Video" width="60%" />
        </span>
      )
    );
  }

  _renderImages(data, editable) {
    const videoUrls = this._getVideoUrls(data);

    let images = data.images;

    if (!images) {
      return null;
    }

    return images.map((image, index) => {
      const videoUrl = this._getVideoUrl(videoUrls, index);

      return (
        <>
          <li
            key={image.id}
            className="col-md-4 col-6 full-screen"
            onClick={() => {
              let data = this._getStateForVideoUrl(videoUrl, index);

              this.setState(data);
            }}
          >
            {this._renderVideoIcon(videoUrl, 5)}
            <a
              className={!editable && "lazy-background"}
              style={{
                backgroundImage: "url(" + getImageUrl(image, 24) + ")",
              }}
              priority={this.props.index}
            >
              &nbsp;
            </a>
          </li>
        </>
      );
    });
  }

  _renderGridImages(data, editable) {
    let videoUrls = this._getVideoUrls(data);

    let images = data.images;

    if (!images) {
      return null;
    }

    let columns = data.extra?.columns || "4";

    let xsColumns = columns < 4 ? "4" : columns;

    let gutter = data.extra?.gutter || "5";

    let aspectRatio = data.extra?.aspectRatio || "1";

    return images.map((image, index) => {
      const videoUrl = this._getVideoUrl(videoUrls, index);

      return (
        <>
          <Col
            xl={parseInt(columns)}
            xs={parseInt(xsColumns)}
            style={{ marginTop: parseInt(gutter), position: "relative" }}
          >
            {this._renderVideoIcon(videoUrl, 0)}
            <img
              className="full-screen"
              src={image.original}
              alt="Photo"
              width="100%"
              height="100%"
              style={{
                objectFit: "cover",
                aspectRatio: aspectRatio,
              }}
              onClick={() => {
                let data = this._getStateForVideoUrl(videoUrl, index);

                this.setState(data);
              }}
            />
          </Col>
        </>
      );
    });
  }

  _parseGalleryImages(images) {
    const { data } = this.state;

    const videoUrls = this._getVideoUrls(data);

    return images.map((image, index) => {
      return {
        src: image.original,
        width: image.width || [3, 3, 4, 5, 3, 4, 5, 3, 3, 4, 5][index % 10],
        height: image.height || [4, 5, 3, 4, 5, 4, 4, 4, 5, 4, 5][index % 10],
        icon: videoIcon,
        videoUrl: this._getVideoUrl(videoUrls, index),
      };
    });
  }

  _renderMasonryImages(data, editable) {
    const videoUrls = this._getVideoUrls(data);

    let images = data.images;

    if (!images) {
      return null;
    }

    let gutter = data.extra?.gutter || "5";

    const imageRenderer = ({ index, key, photo }) => (
      <VideoThumbnail
        key={key}
        index={index}
        photo={photo}
        margin={parseInt(gutter)}
        onClick={() => {
          const videoUrl = this._getVideoUrl(videoUrls, index);

          let data = this._getStateForVideoUrl(videoUrl, index);

          this.setState(data);
        }}
      />
    );

    return (
      <Gallery
        photos={this._parseGalleryImages(images, editable)}
        renderImage={imageRenderer}
        margin={parseInt(gutter)}
      />
    );
  }

  _renderWaterfallImages(data, editable) {
    const videoUrls = this._getVideoUrls(data);

    let images = data.images;

    if (!images) {
      return null;
    }

    return images.map((image, index) => {
      const videoUrl = this._getVideoUrl(videoUrls, index);

      return (
        <div key={image.original}>
          {this._renderVideoIcon(videoUrl, -10)}
          <img
            src={image.original}
            className="full-screen"
            alt="Photo"
            style={{ objectFit: "cover" }}
            onClick={() => {
              let data = this._getStateForVideoUrl(videoUrl, index);

              this.setState(data);
            }}
          />
        </div>
      );
    });
  }

  _renderFullScreenImages(images, photoIndex, isOpen) {
    return (
      isOpen && (
        <Lightbox
          mainSrc={images[photoIndex].original}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => this.setState({ isOpen: false })}
          onMovePrevRequest={() =>
            this.setState({
              photoIndex: (photoIndex + images.length - 1) % images.length,
            })
          }
          onMoveNextRequest={() =>
            this.setState({
              photoIndex: (photoIndex + 1) % images.length,
            })
          }
        />
      )
    );
  }

  _renderSliderLayout() {
    let { key, data, editable, isOpen, photoIndex } = this.state;

    return (
      <>
        <div className="row">
          <div className="col">
            <div
              key={key}
              className="slider slider--columns"
              data-arrows="true"
              data-paging="true"
            >
              <ul className="slides">{this._renderImages(data, editable)}</ul>
            </div>
          </div>
        </div>
        {this._renderFullScreenImages(data.images, photoIndex, isOpen)}
      </>
    );
  }

  _renderGridLayout() {
    let { data, editable, photoIndex, isOpen } = this.state;

    let images = data.images;

    let gutter = data.extra?.gutter || "5";

    return (
      <Container>
        <Row gutterWidth={parseInt(gutter)}>
          {this._renderGridImages(data, editable)}

          {this._renderFullScreenImages(images, photoIndex, isOpen)}
        </Row>
      </Container>
    );
  }

  _renderMasonryLayout() {
    let { data, editable, photoIndex, isOpen } = this.state;

    let images = data.images;

    return (
      <>
        {this._renderMasonryImages(data, editable)}

        {this._renderFullScreenImages(images, photoIndex, isOpen)}
      </>
    );
  }

  _renderWaterfallLayout() {
    let { data, editable, photoIndex, isOpen } = this.state;

    let images = data.images;

    let gutter = data.extra?.gutter || "5";

    let columnWidth = data.extra?.width || "250";

    return (
      <StackGrid
        monitorImagesLoaded={true}
        columnWidth={parseInt(columnWidth)}
        gutterWidth={parseInt(gutter)}
        gutterHeight={parseInt(gutter)}
      >
        {this._renderWaterfallImages(data, editable)}

        {this._renderFullScreenImages(images, photoIndex, isOpen)}
      </StackGrid>
    );
  }

  renderImageLayoutContent() {
    let { data } = this.state;

    let variation = data.variation || "slider";

    if (variation == "slider") {
      return this._renderSliderLayout();
    } else if (variation == "grid") {
      return this._renderGridLayout();
    } else if (variation == "masonry") {
      return this._renderMasonryLayout();
    } else {
      return this._renderWaterfallLayout();
    }
  }

  _addVideoUrlToDataExtra(data, videoUrl) {
    if (!data.extra) {
      data.extra = {};
    }
    if (!data.extra.video_urls) {
      data.extra.video_urls = [];
    }
    data.extra.video_urls.push(videoUrl || null);
  }

  render() {
    let { data, editable, videoUrl } = this.state;

    return (
      <Section
        ref={this.section}
        data={data}
        index={this.props.index}
        className={" block-24 " + this.props.className}
        editable={editable}
        onUpdated={(data) => this._update(data)}
        onDelete={() => this.props.onDelete()}
        onAddContentBlockPressed={(contentBlock) =>
          this.props.onAddContentBlockPressed(contentBlock)
        }
        renderModalContent={() => this._renderModalContent(data)}
        imageTitle={"Add Image/Video"}
        primaryColor={false}
        secondaryColor={false}
        onImageUpdated={(image) => {
          data.images.push(image);

          this._addVideoUrlToDataExtra(data, videoUrl);

          this._update(
            {
              data,
              key: this.state.key + 1,
            },
            () => General.updateAll()
          );
        }}
        canMoveUp={this.props.canMoveUp}
        canMoveDown={this.props.canMoveDown}
        onVisibilityButtonPressed={(index) => this.props.onVisibilityButtonPressed(index)}
        onMoveUpPressed={() => this.props.onMoveUpPressed()}
        onMoveDownPressed={() => this.props.onMoveDownPressed()}
      >
        <div className="container">

          <Titles
            data={ data }
            editable={ editable }
            onUpdated={data => this._update(data)}
            headingNumber={2}
          />

          {this.renderImageLayoutContent()}
        </div>

        <VideoModal
          showModal={this.state.showVideoModal}
          videoUrl={videoUrl}
          onHide={() => {
            this.setState({ showVideoModal: false });
          }}
        />
      </Section>
    );
  }
}
