import React from 'react'

import IconPickerModal from '../IconPickerModal'

import Dropzone from '../../blocks/editable/Dropzone'

import General from '../../../utils/General'

export default class IconSettingsModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      iconInfo: props.iconInfo,
      showModal: false,
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _renderModal(iconInfo){

    return (
      <IconPickerModal
        show={this.state.showModal}
        onHide={() => this.setState({ showModal: false })}
        onSelected={icon => {
          iconInfo.icon = "icon icon--lg icon-"+icon.name
          this.setState({
            iconInfo,
            showModal: false
          })
          this.props.onUpdated(iconInfo)
        }}
      />
    )
  }

  render(){
    let { iconInfo } = this.state

    return (
      <>
        <a href="javascript:void(0)"
           className="edit"
           onClick={() => this.setState({ showModal: true })}>
             <i className="icon icon--xs icon-Pencil" />
        </a>
        { this._renderModal(iconInfo) }
      </>
    )
  }
}
