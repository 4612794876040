import React from 'react'

import Dropdown from './Dropdown';

import Border from './Border';
import ColorPicker from './ColorPicker';
import FontSelect from './FontSelect';
import IconPickerModal from './modal/IconPickerModal';
import {getImageUrl, Sizes} from "./blocks/editable/Img";


const OPTIONS_ICON_STYLE = [
  {
    value: "icon_left_text_right",
    label: "Display Icon Left + Text Right"
  },
  {
    value: "icon_top_text_bottom",
    label: "Display Icon Top + Text Bottom"
  },
  {
    value: "text_top_icon_bottom",
    label: "Display Text Top + Icon Bottom"
  },
  {
    value: "text_left_icon_right",
    label: "Display Text Left + Icon Right"
  }
]

export default class LogoMaker extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      logo: props.logo
    }

  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _renderTextSettings(logo, title){
    if(this.props.mode === "colors"){
      return null
    }

    let settings = logo.font

    let font = FontSelect.getOption(settings.family_name)
    let weights = []
    if(font){
      weights = font.weights.map(weight => {
        return {
          label: weight.name,
          value: weight.value,
          className: `${font.className} ${font.className}-${weight.value}`
        }
      })
    }

    let selectedWeight = weights.find(weight => weight.value === settings.weight)

    return (
      <>
        <div className="row">
          <div className="col">

            <span>Logo Font</span>
            <FontSelect
              value={settings.family_name}
              onUpdated={value => {
                logo.font.family_name = value
                this.setState({ logo })
                this.props.onUpdated(logo)
              }}
            />

            <br></br>

            <span>Font Thickness</span>
            <Dropdown
              options={weights}
              value={selectedWeight}
              onChange={option => {
                logo.font.weight = option.value
                this.setState({ logo })
                this.props.onUpdated(logo)
              }}
              placeholder="Thickness"
              className="font-select"
              placeholderClassName={font ? `${font.className} ${font.className}-${settings.weight}` : null}
            />
            
          </div>
        </div>
      </>
    )
  }

  _renderTextInput(logo, className=""){
    return (
      <input
        type="text"
        className={`logo-maker-text textarea-edit ${className}`}
        value={ logo.text }
        style={{
          border: 'none',
          height: 'unset',
          fontSize: this.props.logoSize ? this.props.logoSize * 1 : 30,
          fontFamily: logo.font ? logo.font.family_name : null,
          fontWeight: logo.font ? logo.font.weight : null,
          color: logo.text_color,
          padding: 0,

        }}
        placeholder="Logo Text"
        onChange={e => {
          logo.text = e.target.value
          this.setState({ logo })
          this.props.onUpdated(logo)
        }}
      />
    )
  }

  _renderIcon(logo, className=""){
    let {
      logoSize
    } = this.props
    return (
      <div className={`text-center mb-0 icon-item ${className}`}>
        <i className={`icon icon--lg ${logo.icon}`}
          style={{
            color: logo.icon_color,
            fontSize: logoSize ? ((logoSize * 2)+((logoSize * 2)*(0.2))) : (30+(30*0.2))
          }}
          disabled={this.props.logoDarkMode}
          onClick={() => this.setState({ showIconPicker: true })}
        />
      </div>
    )
  }

  _renderPreview(logo){
    if(logo.style == "icon_top_text_bottom"){
      return this._renderPreviewIconTopTextBottom(logo)
    }
    else if(logo.style == "text_top_icon_bottom"){
      return this._renderPreviewTextTopIconBottom(logo)
    }
    else if(logo.style == "text_left_icon_right"){
      return this._renderPreviewTextLeftIconRight(logo)
    }

    return this._renderPreviewIconLeftTextRight(logo)
  }

  _renderPreviewIconTopTextBottom(logo){
    return (
      <div className="row">
        <div className="col-md-12 my-auto">
          { this._renderIcon(logo) }
          { this._renderTextInput(logo, "text-center w-100") }
        </div>
      </div>
    )
  }

  _renderPreviewTextTopIconBottom(logo){
    return (
      <div className="row">
        <div className="col-md-12 my-auto">
          { this._renderTextInput(logo, "text-center w-100") }
          { this._renderIcon(logo) }
        </div>
      </div>
    )
  }

  _renderPreviewTextLeftIconRight(logo){
    return (
      <div className="d-flex align-items-center">
        { this._renderTextInput(logo, 'float-left text-right mr-2') }
        { this._renderIcon(logo, 'float-left') }
      </div>
    )
  }

  _renderPreviewIconLeftTextRight(logo){
    return (
        <div className="d-flex align-items-center">
          { this._renderIcon(logo, 'float-left') }
          { this._renderTextInput(logo, 'float-left ml-2') }
        </div>
    )
  }

  render() {
    let {
      logo,
      showIconPicker
    } = this.state

    if(!this.props.show){
      return null
    }

    let title = "Light Logo"
    let subtitle = "Displayed when on transparent background"
    if(this.props.logoDarkMode){
      title = "Darker Logo"
      subtitle = "Displayed when on solid background colour (such as white)"
    }

    let previewStyle = null
    let previewClassName = "logo-maker-preview"
    if(window.CURRENT_PAGE_PREVIEW && !this.props.logoDarkMode){
      previewStyle = {
        backgroundImage: `url(${getImageUrl(window.CURRENT_PAGE_PREVIEW, Sizes.SMALL)})`,
        padding: 8
      }
      previewClassName = null
    }
    else if(this.props.backgroundColor != null && this.props.logoDarkMode){
      previewStyle = {
        backgroundColor: this.props.backgroundColor,
        padding: 8
      }
      previewClassName = null
    }

    let slectedIconStyle = OPTIONS_ICON_STYLE.find(logoStyle => logoStyle.value === logo.style )

    return (
      <Border darkMode={false}>
        <h3 className="mb-0">{title}</h3>
        <label className="mb-3">{subtitle}</label>
        <br/>
        { !this.props.logoDarkMode &&
          <>
            <span>
              Icon Position
            </span>
            <Dropdown
              options={OPTIONS_ICON_STYLE}
              value={slectedIconStyle}
              onChange={option => {
                logo.style = option.value
                this.setState({ logo })
                this.props.onUpdated(logo)
              }}
              placeholder="Icon Position"
            />
            <br/><br/>
          </>
        }

        <div
          className={previewClassName}
          style={previewStyle}
        >
          { this._renderPreview(logo) }
        </div>

        <br/>

        <ColorPicker
          title={`Logo Icon Colour`}
          className="logo-maker"
          color={ logo.icon_color }
          colorBoxStyle={styles.colorBoxStyle}
          colorTextStyle={styles.colorTextStyle}
          onColorSelected={color => {
            logo.icon_color = color
            this.setState({ logo })
            this.props.onUpdated(logo)
          }}
        />

        <br/>

        <ColorPicker
          title={`Logo Text Colour`}
          className="logo-maker"
          color={ logo.text_color }
          colorBoxStyle={styles.colorBoxStyle}
          colorTextStyle={styles.colorTextStyle}
          onColorSelected={color => {
            logo.text_color = color
            this.setState({ logo })
            this.props.onUpdated(logo)
          }}
        />

        <br/>

        { !this.props.logoDarkMode &&

          <>
            { this._renderTextSettings(logo, title) }

            <br/>
          </>
        }

        <IconPickerModal
          show={showIconPicker}
          onHide={() => this.setState({ showIconPicker: false })}
          onSelected={icon => {
            logo.icon = "icon-"+icon.name
            this.setState({ logo, showIconPicker: false })
            this.props.onUpdated(logo)
          }}
        />
      </Border>
    )
  }
}


const styles = {
  colorBoxStyle: {
    minWidth: 150,
    width: 150,
    marginRight: 0
  },
  colorTextStyle: {
    overflow: 'hidden',
    minWidth: 90,
    width: 90
  }
}
