import React from "react";
import PropTypes from 'prop-types';

import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromRaw,
  convertFromHTML,
} from "draft-js";

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import htmlToDraft from "html-to-draftjs";

import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";

import Color from "../../../utils/Color";
import General from "../../../utils/General";

export default class TextInput extends React.Component {
  constructor(props) {
    super(props);

    let editorState = null;
    let text = props.value;

    if (text) {
      try {
        editorState = EditorState.createWithContent(stateFromHTML(text));
      } catch (error) {
        const contentState = ContentState.createFromText(text);
        editorState = EditorState.createWithContent(contentState);
      }
    } else {
      editorState = EditorState.createEmpty();
    }

    this.state = {
      id:  "a_"+General.uuid(),
      className: props.className,
      color: props.color,
      textAlign: props.textAlign || "center",
      textSize: props.textSize,
      minRows: props.minRows || 1,
      editable: props.editable,
      text,
      editorState,
      focus: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      ...nextProps,
      textAlign: nextProps.textAlign || "center",
      minRows: nextProps.minRows || 1,
    });
  }

  _onEditorStateChange(editorState) {
    let { text } = this.state;

    let oldText = text
    let contentState = editorState.getCurrentContent();

    text = stateToHTML(contentState);
    this.setState({
      text,
      editorState,
    });

    if(text != oldText){
      this.props.onUpdated(text);
    }
  }

  render() {
    let {
      id,
      className,
      color,
      textAlign,
      minRows,
      editable,
      textSize,
      text,
      focus,
      editorState,
    } = this.state;

    let textColor = color || "#fffff";

    let wrapperClassName = `${id} text-editor-wrapper textarea-edit ${className}`;
    if (focus) {
      wrapperClassName += " focus";
    }

    return (
      <>
        <Editor
          toolbarOnFocus
          onFocus={(event) => {
            this.setState({ focus: true });
          }}
          onBlur={(event) => {
            this.setState({ focus: false });
          }}
          editorState={editorState}
          text={text}
          wrapperClassName={wrapperClassName}
          wrapperStyle={{
            color: textColor,
            textAlign: textAlign,
            fontSize: textSize
          }}
          onEditorStateChange={(editorState) =>
            this._onEditorStateChange(editorState)
          }
          toolbar={{
            options: ["inline", "link"],
            inline: {
              options: ["bold", "italic", "underline"],
              bold: { className: "t-bold" },
              underline: { className: "t-underline" },
            },
            link: {
              link: { className: "t-link" },
              unlink: { className: "t-unlink" },
            }
          }}
          handlePastedText={() => false}
        />
      </>
    );

  }
}
