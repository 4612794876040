import React from 'react'
import $ from "jquery";
import Dropdown from './Dropdown';

import Modal from 'react-bootstrap/Modal'

import General from '../utils/General'
import Backend from '../utils/Backend'

import Block1 from '../assets/img/blocks/1.png'
import Block2 from '../assets/img/blocks/2.png'
import Block3 from '../assets/img/blocks/3.png'
import Block4 from '../assets/img/blocks/4.png'
import Block5 from '../assets/img/blocks/5.png'
import Block6 from '../assets/img/blocks/6.png'
import Block7 from '../assets/img/blocks/7.png'
import Block8 from '../assets/img/blocks/8.png'
import Block9 from '../assets/img/blocks/9.png'
import Block10 from '../assets/img/blocks/10.png'
import Block11 from '../assets/img/blocks/11.png'
import Block12 from '../assets/img/blocks/12.png'
import Block13 from '../assets/img/blocks/13.png'
import Block14 from '../assets/img/blocks/14.png'
import Block15 from '../assets/img/blocks/15.png'
import Block16 from '../assets/img/blocks/16.png'
import Block17 from '../assets/img/blocks/17.png'
import Block18 from '../assets/img/blocks/18.png'
import Block19 from '../assets/img/blocks/19.png'
import Block20 from '../assets/img/blocks/20.png'
import Block21 from '../assets/img/blocks/21.png'
import Block22 from '../assets/img/blocks/22.png'
import Block23 from '../assets/img/blocks/23.png'
import Block24 from '../assets/img/blocks/24.png'
import Block25 from '../assets/img/blocks/25.png'
import Block26 from '../assets/img/blocks/26.png'
import Block27 from '../assets/img/blocks/27.png'
import Block28 from '../assets/img/blocks/28.png'
import Block29 from '../assets/img/blocks/29.png'
import Block30 from '../assets/img/blocks/30.png'
import Block31 from '../assets/img/blocks/31.png'
import Block32 from '../assets/img/blocks/32.png'
import Block33 from '../assets/img/blocks/33.png'
import Block34 from '../assets/img/blocks/34.png'
import Block35 from '../assets/img/blocks/35.png'
import Block36 from '../assets/img/blocks/36.png'
import Block37 from '../assets/img/blocks/37.png'
import Block38 from '../assets/img/blocks/38.png'
import Block39 from '../assets/img/blocks/39.png'
import Block40 from '../assets/img/blocks/40.png'
import Block41 from '../assets/img/blocks/41.png'


export default class AddContentBlock extends React.Component {
  blockImages = {
       Block1,
       Block2,
       Block3,
       Block4,
       Block5,
       Block6,
       Block7,
       Block8,
       Block9,
       Block10,
       Block11,
       Block12,
       Block13,
       Block14,
       Block15,
       Block16,
       Block17,
       Block18,
       Block19,
       Block20,
       Block21,
       Block22,
       Block23,
       Block24,
       Block25,
       Block26,
       Block27,
       Block28,
       Block29,
       Block30,
       Block31,
       Block32,
       Block33,
       Block34,
       Block35,
       Block36,
       Block37,
       Block38,
       Block39,
       Block40,
       Block41,
   };

  constructor(props){
    super(props)
    this.state = {
      show: props.show,
      contentBlocks: [],
      filteredContentBlocks: [],
      loading: true
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  componentDidMount(){
    this._load()
    this._stickyMenu()
  }

  _stickyMenu(){
    try {

      var scrollElement = $('#modal-side-menu .add-content-block');
      var afterScroll = 220;

      $(scrollElement).scroll(function() {
        var scrollTop = $(scrollElement).scrollTop();
        if (afterScroll < scrollTop ) {
          $(scrollElement).addClass('sticky');
        } else {
          $(scrollElement).removeClass('sticky');
        }
      });

    }
    catch(err) {
      console.log(err.message);
    }
  }

  _load(){
    Backend.getDemoContentBlocks()
    .then(contentBlocks => {
      let categories = []

      contentBlocks.forEach(contentBlock => {
        contentBlock.block.categories.forEach(category => {
          let label = category.name || "Other"

          categories.push({
            label,
            value: category.id || "other",
            order: category.order
          })
        })
      })

      let selectedCategory = {
        value: "null",
        label: "Show All Block Categories",
        order: 99
      }

      // remove duplicates
      categories = categories.filter((v,i,a)=>a.findIndex(t=>(t.label === v.label && t.value===v.value))===i)

      categories.sort((a, b) => a.order - b.order)

      categories = [
        selectedCategory,
        ...categories
      ]

      this.setState({
        categories,
        contentBlocks,
        selectedCategory,
        loading: false,
        filteredContentBlocks: contentBlocks
      })

      window.DEMO_CONTENT_BLOCKS = contentBlocks
    })
    .catch(error => {
      // to do handle error
    })
  }

  _onSelected(contentBlock){
    if(contentBlock){
      this.props.onSelected(contentBlock)
    }
    else{
      // to do handle error

    }
  }

  _getContentBlock(blockId){
    let {
      contentBlocks
    } = this.state
    for(var i=0; i < contentBlocks.length; i++){
      let contentBlock = contentBlocks[i]

      if(contentBlock.block.id === blockId){
        return contentBlock
      }
    }
  }

  _getMainCategoryName(block){
    let mainCategoryName = "Other"
    if(block.categories.length > 0){
      mainCategoryName = block.categories[0].name
      if(mainCategoryName.endsWith("s")){
        mainCategoryName = mainCategoryName.substring(0, mainCategoryName.length - 1)
      }
    }
    return mainCategoryName
  }

  _renderCategoryDropdown(){
    let {
      categories,
      selectedCategory,
      contentBlocks,
    } = this.state

    if(!categories){
      return null
    }

    return (
      <>
        <Dropdown
            options={categories}
            value={selectedCategory}
            onChange={category => {
              let filteredContentBlocks = []
              if(category.value === "null"){
                filteredContentBlocks = contentBlocks
              }
              else if(category.value === "other"){
                filteredContentBlocks = contentBlocks.filter(contentBlock => contentBlock.block.category == null)
              }
              else{
                filteredContentBlocks = contentBlocks.filter(contentBlock => {
                  return contentBlock.block.categories.find(c => c.id === category.value) != null
                })
              }

              this.setState({
                filteredContentBlocks,
                selectedCategory: category
              })
            }}
            placeholder="Show All Block Categories"
        />
      </>
    )
  }

  _renderBlocks(){
    let {
      loading,
      filteredContentBlocks,
    } = this.state

    if(loading){
      return <h5>Loading</h5>
    }

    return filteredContentBlocks.map((contentBlock, index) => {
      let block = contentBlock.block
      let blockName = "Block" + block.id

      let mainCategoryName = this._getMainCategoryName(block)

      // Override name to block
      mainCategoryName = "Block"
      let name = mainCategoryName + ": " + contentBlock.name
      if(block.name == "Timeline"){
        name = "Timeline"
      }
      const BlockImage = contentBlock.preview_image ? contentBlock.preview_image.thumbnail : this.blockImages[blockName];

      return (
        <div key={block.id} class={"col-md-6 content-block-container "+block.id}>
          <a onClick={() => this._onSelected(contentBlock)}>
            <h5 className='name'>{ name }</h5>
            <span className='image'>
              <img class="content-block-image" loading="lazy" src={ BlockImage }/>
              <span className='button'><button>Click To Add Block</button></span>
            </span>
          </a>
        </div>
      )
    })
  }

  render() {
    let {
      show,
      filteredContentBlocks
    } = this.state

    let className = " modal-container"
    if(show){
      className += " modal-active"
    }

    return (
      <>
        <h2>Add New Block</h2>
        <hr className="short"/>

        <div className='categories'>
          { this._renderCategoryDropdown() }
        </div>

        <div key={filteredContentBlocks.length} class="row">
          { this._renderBlocks() }
        </div>
      </>
    )
  }
}
