import React from 'react'

import TextField from '@material-ui/core/TextField';
import {FormHelperText} from "@material-ui/core";

import FileUploader from '../../common/FileUploader'

import Backend from '../../../utils/Backend'
import AuthManager from '../../../utils/AuthManager'

export default class Image extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      data: props.data,
      index: props.index
    }
  }

  componentWillReceiveProps(nextProps,) {
    this.setState(nextProps)
  }

  render() {
    let { data, index } = this.state

    let className = "field-image "
      className += data.is_required ? "validate-required" : ""
    if(data.error){
      className += " validation-error"
    }

    return (
      <div className={className}>
        <FileUploader
          key={data.answer?.image?.id}
          placeholder={data.title}
          endpoint={window.Api.Images}
          params={{
            type: "photo",
            company_id: window.CURRENT_COMPANY_ID,
          }}
          accept="image/*"
          onUploading={() => {
            this.setState({loading: true})
          }}
          onUploaded={file => {
            let value = {field: data.id, image: file.id};
            data.error = false
            data.answer = value
            this.props.onUpdated(index, value)
            this.setState({data})
          }}
          onRemoved={() => {
            data.answer.error = false
            data.answer.image = null
            this.props.onUpdated(index, data.answer)
            this.setState({data})
          }}
        />

        {
          data.error &&
          <FormHelperText>Please upload an image</FormHelperText>
        }
      </div>
    )
  }
}
