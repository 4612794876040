import React from "react";
import Collapsible from "react-collapsible";

import Socials from "./blocks/editable/Socials";

import PortalModal from "./modal/PortalModal";

import LinkSelect from "./LinkSelect";
import ColorPicker from "./ColorPicker";
import Border from "./Border";

import Backend from "../utils/Backend";
import General from "../utils/General";

import SmartList from "./common/SmartList";

export default class Footer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      canRenderContent: props.canRenderContent,
      website: props.website,
      data: props.footer,
      editable: props.editable,
    };

    this.updateFooter = General.debounce(
      () => {
        console.log("3333", this.state.data);
        Backend.updateFooter(this.state.data);
      },
      1000,
      false
    );
  }

  componentDidMount() {
    this._setup();
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  _setup() {
    let { data, website } = this.state;

    this.setState({
      data,
      loading: false,
    });
  }

  _update(data, callback) {
    this.setState({ data }, () => {
      if (callback) {
        callback();
      }
      this.updateFooter();
    });
  }

  _addColumnItem(data) {
    data.columns.push({
      title: "Column",
      nav_items: [],
    });

    this._update(data);
  }

  _addNavItem(data, colIndex) {
    data.columns[colIndex].nav_items.push({
      text: "New Link",
      url: "#",
      active: true,
      nav_items: [],
      open_in_new_tab: false,
    });

    this._update(data);
  }

  _renderModal() {
    let { data, showModal } = this.state;
    return (
      <PortalModal
        show={showModal}
        onHide={() => this.setState({ showModal: false })}
      >
        <h2>Footer</h2>

        <>
          <Border>
            <Collapsible trigger="Footer Style">
              <br></br>

              <select
                value={data.variation || "standard"}
                onChange={(event) => {
                  data.variation = event.target.value;

                  if (data.columns.length === 0) {
                    data.columns = [
                      {
                        title: `Column Title ${data.columns.length + 1}`,
                        nav_items: [],
                      },
                    ];
                  }
                  this._update(data);
                }}
              >
                <option value="small">Small Footer</option>
                <option value="standard">Large Footer</option>
              </select>
            </Collapsible>
          </Border>

          <Border>
            <Collapsible
              trigger={
                this.isSmallFooter()
                  ? "What Links Would You Like To Show?"
                  : "How Many Columns Would You Like?"
              }
            >
              <br></br>
              {!this.isSmallFooter() && (
                <>
                  <select
                    value={data.columns.length}
                    onChange={(event) => {
                      let num = event.target.value;
                      let difference = num - data.columns.length;
                      if (difference > 0) {
                        for (var i = 0; i < difference; i++) {
                          data.columns.push({
                            title: `Column Title ${data.columns.length + 1}`,
                            nav_items: [],
                          });
                        }
                      } else if (difference < 0) {
                        for (var i = difference; i < 0; i++) {
                          data.columns.pop();
                        }
                      }
                      this._update(data);
                    }}
                  >
                    <option value="0">0 Columns</option>
                    <option value="1">1 Column</option>
                    <option value="2">2 Columns</option>
                    <option value="3">3 Columns</option>
                    <option value="4">4 Columns</option>
                  </select>
                  <br />
                </>
              )}

              {this.isSmallFooter() ? (
                this._renderModalSmallFooterLinks(data)
              ) : (
                <>
                  <br />
                  {this._renderModalColumns(data)}
                </>
              )}
            </Collapsible>
          </Border>
        </>

        {this._renderSocialInputs(data)}

        <Border>
          <Collapsible trigger="Colours">
            <br></br>

            <ColorPicker
              title={"Text Color"}
              color={data.settings.primary_color}
              onColorSelected={(color) => {
                data.settings.primary_color = color;
                this._update(data);
              }}
            />

            <br />

            <ColorPicker
              title={"Background Color"}
              color={data.settings.tertiary_color}
              onColorSelected={(color) => {
                data.settings.tertiary_color = color;
                this._update(data);
              }}
            />
          </Collapsible>
        </Border>
      </PortalModal>
    );
  }

  _renderSocialInputs(data) {
    if (data.socials == null) {
      data.socials = {
        settings: {},
      };
    }

    return (
      <Border>
        <Collapsible trigger="Social Links & Fine Print">
          <br></br>
          <span>Fine Print</span>
          <input
            type="text"
            defaultValue={data.fine_print}
            onChange={(event) => {
              data.fine_print = event.target.value;
              this._update(data);
            }}
          />
          <br />
          <br />
          {this._renderSocialInput("Email", "mail", data.socials.mail, data)}
          {this._renderSocialInput(
            "Website",
            "website",
            data.socials.website,
            data
          )}
          {this._renderSocialInput(
            "Twitter",
            "twitter",
            data.socials.twitter,
            data
          )}
          {this._renderSocialInput(
            "Instagram",
            "instagram",
            data.socials.instagram,
            data
          )}
          {this._renderSocialInput(
            "Facebook",
            "facebook",
            data.socials.facebook,
            data
          )}
          {this._renderSocialInput(
            "LinkedIn",
            "linkedIn",
            data.socials.linkedIn,
            data
          )}
        </Collapsible>
      </Border>
    );
  }

  _renderSocialInput(title, key, value, data) {
    return (
      <>
        <span>{title}</span>
        <input
          type="text"
          defaultValue={value}
          onChange={(event) => {
            data.socials[key] = event.target.value;
            this._update(data);
          }}
        />
        <br />
        <br />
      </>
    );
  }

  _renderModalColumns(data) {
    let color = data.settings.primary_color;
    return (
      <>
        <SmartList
          title="Columns"
          canDelete={false}
          items={data.columns}
          itemName="Column"
          value={(column) => column.title}
          renderItem={(column, index) => {
            return (
              <Collapsible
                trigger={data.columns[index].title}
              >
                <br />
                <div className="boxed boxed--border">
                  <a
                    className="delete-item"
                    onClick={() => {
                      data.columns.splice(index, 1);
                      this._update(data);
                    }}
                  />
                  <div className="ml-auto">
                    <span>Column Title</span>
                    <input
                      type="text"
                      defaultValue={column.title}
                      onChange={(event) => {
                        data.columns[index].title = event.target.value;
                        this._update(data);
                      }}
                    />
                    <br />
                    <br />
                    <span>Links</span>
                    <div style={{ marginBottom: 0 }}>
                      <br />
                      {this._renderModalNavItems(data, index, column.nav_items)}
                      {this._renderModalAddNavItemButton(data, index)}
                    </div>
                  </div>
                </div>
              </Collapsible>
            );
          }}
          onUpdated={(columns) => {
            data.columns = columns;

            this._update(data);
          }}
        />
      </>
    );
  }

  _renderModalSmallFooterLinks(data) {
    let color = data.settings.primary_color;

    let column = data.columns[0];

    let index = 0;

    return (
      <>
        <div className="boxed boxed--border">
          <div className="ml-auto">
            <span>Links</span>
            <div style={{ marginBottom: 0 }}>
              <br />
              {this._renderModalNavItems(data, index, column.nav_items)}
              {this._renderModalAddNavItemButton(data, index)}
            </div>
          </div>
        </div>
      </>
    );
  }

  _renderModalNavItems(data, colIndex, navItems) {
    return (
      <SmartList
        title="Links"
        canDelete={false}
        items={navItems}
        itemName="Link"
        value={(navItem) => navItem.text}
        renderItem={(navItem, index) => {
          return (
            <Collapsible
              trigger={navItem.text}
              className="mb--1"
              overflowWhenOpen="visible"
              triggerSibling={() => {
                return (
                  <a
                    className="delete-item delete-item-collapsible"
                    onClick={() => {
                      data.columns[colIndex].nav_items.splice(index, 1);
                      this._update(data);
                    }}
                  />
                );
              }}
            >
              <div className="boxed boxed--sm overflow-visible">
                <span>Title</span>
                <input
                  type="text"
                  defaultValue={navItem.text}
                  onChange={(event) => {
                    let text = event.target.value;
                    navItem.text = text;
                    data.columns[colIndex].nav_items[index] = navItem;
                    this._update(data);
                  }}
                />
                <br />
                <br />
                <span>Link</span>
                <LinkSelect
                  url={navItem.url}
                  onUpdated={(url) => {
                    navItem.url = url;
                    data.columns[colIndex].nav_items[index] = navItem;
                    this._update(data);
                  }}
                />

                {!navItem.url?.startsWith("/#") && (
                  <>
                    <span>Open In New Tab</span>
                    <select
                      className="mb-3"
                      value={navItem.open_in_new_tab}
                      onChange={(event) => {
                        navItem.open_in_new_tab = event.target.value === "true";

                        data.columns[colIndex].nav_items[index] = navItem;

                        this._update(data);
                      }}
                    >
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </select>
                  </>
                )}
              </div>
            </Collapsible>
          );
        }}
        onUpdated={(navItems) => {
          data.columns[colIndex].nav_items = navItems;

          this._update(data);
        }}
      />
    );
  }

  _renderModalAddColumnButton(data) {
    return (
      <div className="row">
        <div className="col-md-12">
          <a className="btn" onClick={() => this._addColumnItem(data)}>
            <span className="btn__text">Add Column</span>
          </a>
        </div>
      </div>
    );
  }

  _renderModalAddNavItemButton(data, colIndex) {
    return (
      <div className="row">
        <div className="col-md-12">
          <a className="btn" onClick={() => this._addNavItem(data, colIndex)}>
            <span className="btn__text">
              {this.isSmallFooter() ? "Add New Link" : "Add Column Link"}
            </span>
          </a>
        </div>
      </div>
    );
  }

  _renderEditable(editable) {
    if (!editable) {
      return null;
    }

    return (
      <>
        <div className="nav-button-edit">
          <div className="button-slide">
            <a
              className="button"
              href="javascript:void(0)"
              onClick={() => {
                this.setState({ showModal: true });
              }}
            >
              <span class="slide-text">Edit Footer</span>
              <i class="material-icons m-icon-s1 c-white-opa-8">settings</i>
            </a>
          </div>
        </div>
        {this._renderModal()}
      </>
    );
  }

  _renderNavItems(navItems) {
    let { data } = this.state;

    if (this.isSmallFooter()) {
      return (
        <div className="row mt-0">
          {navItems?.map((navItem) => {
            return (
              <div className="col">
                <a
                  href={navItem.url}
                  target={navItem.open_in_new_tab ? "_blank" : ""}
                  style={{
                    color: data.settings.primary_color,
                  }}
                >
                  {navItem.text}
                </a>
              </div>
            );
          })}
        </div>
      );
    }
    return navItems?.map((navItem) => {
      return (
        <div>
          <a
            target={navItem.open_in_new_tab ? "_blank" : ""}
            href={navItem.url}
            style={{
              color: data.settings.primary_color,
            }}
          >
            {navItem.text}
          </a>
        </div>
      );
    });
  }

  _renderColumns(data) {
    if (!data.columns) {
      return null;
    }

    let colWeight = data.columns.length < 4 ? 4 : 3;
    let className = "col-md-" + colWeight + " m-b-50";
    let columns = data.columns.map((column) => {
      return (
        <div className={className}>
          <h5 style={{ color: data.settings.primary_color }}>{column.title}</h5>
          {this._renderNavItems(column.nav_items)}
        </div>
      );
    });

    return <div className="row">{columns}</div>;
  }

  _renderFinePrint(data) {
    let navItems = data.columns[0]?.nav_items;

    let colClassName = this.isSmallFooter() ? "col-sm-4" : "col-sm-6";
    return (
      <div className="row">
        <div className={colClassName}>
          <span
            className="type--fine-print"
            style={{
              color: data.settings.primary_color,
            }}
          >
            {data.fine_print}
          </span>
        </div>
        <div
          className={`${colClassName} ${
            this.isSmallFooter() ? "text-sm-center" : "text-sm-right"
          }`}
        >
          <Socials socials={data.socials} color={data.settings.primary_color} />
        </div>

        {this.isSmallFooter() && (
          <div className={`${colClassName} text-sm-right`}>
            {this._renderNavItems(navItems)}
          </div>
        )}
      </div>
    );
  }

  isSmallFooter() {
    let isSmallFooter = this.state.data.variation == "small";
    return isSmallFooter;
  }

  render() {
    let { data, editable, website } = this.state;

    let className = "space--sm footer-2 bg--primary";
    if (
      data.columns == null ||
      data.columns.length === 0 ||
      this.isSmallFooter()
    ) {
      className = "footer-7 text-center-xs bg--primary";
    }

    if (website.hide_footer || this.props.hideFooter) {
      return null;
    }

    return (
      <footer
        className={className + " section-footer "}
        style={{
          backgroundColor: data.settings.tertiary_color,
        }}
      >
        <div className="container">
          {!this.isSmallFooter() && this._renderColumns(data)}

          {this._renderFinePrint(data)}
        </div>
        {this._renderEditable(editable)}
      </footer>
    );
  }
}
