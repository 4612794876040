import React from 'react'

import moment from 'moment'

import H3 from './editable/H3'
import H5 from './editable/H5'
import Span from './editable/Span'
import P from './editable/P'
import Section from './editable/Section'
import Img from './editable/Img'

import Button from '../Button'

import Product from '../Product'
import AddArticle from '../AddArticle';

import ArticleItem from '../common/ArticleItem';

import General from '../../utils/General'
import Backend from '../../utils/Backend'

import Titles from './editable/Titles';

export default class Block43 extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      data: props.data,
      editable: props.editable,
      loading: true,
      articles: []
    }

    this.updateContentBlock = General.debounce(() => {
      console.log("3333", this.state.data)
      Backend.updateContentBlock(this.state.data)
    }, 1000, false)
  }

  componentDidMount(){
    Backend.getArticles()
    .then(articles => {
      this.setState({ articles, loading: false })
    })
    .catch(error => {
      this.setState({ loading: false })
    })
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _update(data, callback){
    this.setState({ data }, () => {

      if(callback){
        callback()
      }
      this.updateContentBlock()
    })
  }

  _renderArticlesLoading(){
    return (
      <>
        <Product/>
        <Product/>
        <Product/>
      </>
    )
  }

  _renderArticles(data, articles){
    let {
      loading
    } = this.state

    if(loading){
      return this._renderArticlesLoading()
    }

    return articles.map((article, index) => {
      return (
        <ArticleItem article={article}/>
      )
    })
  }

  _renderContent(){
    let {
      data,
      editable,
      articles,
      loading
    } = this.state

    if(!loading && articles.length == 0){
      return <AddArticle/>
    }

    return (
      <>
        <Titles
            data={ data }
            editable={ editable }
            onUpdated={data => this._update(data)}
            rowCssClasses={"dc-mb-1"}
            alignment={"text-left"}
            headingNumber={3}
          />
        <div className="row dc-row-v3">
            { this._renderArticles(data, articles) }
        </div>
      </>
    )

  }

  render(){
    let {
      data,
      editable,
      articles,
      loading
    } = this.state

    if(!loading && !editable && articles.length === 0){
      return null
    }

    let blockClassName = " block-18 block-43 bg--secondary  ";
    if (window.IS_CARD_STYLE_WEBSITE) {
      blockClassName += " deep-cardify";
    }

    return (
      <Section
          data={ data }
          index={ this.props.index }
          className={ blockClassName + this.props.className }
          editable={ editable }
          onUpdated={data => this._update(data)}
          onDelete={() => this.props.onDelete()}
          primaryColor={false}
          secondaryColor={false}
          onAddContentBlockPressed={contentBlock => this.props.onAddContentBlockPressed(contentBlock)}
          canMoveUp={ this.props.canMoveUp }
          canMoveDown={ this.props.canMoveDown }
          onVisibilityButtonPressed={(index) => this.props.onVisibilityButtonPressed(index)}
          onMoveUpPressed={ () => this.props.onMoveUpPressed() }
          onMoveDownPressed={ () => this.props.onMoveDownPressed() }
      >
          <div className="container">
              { this._renderContent() }
          </div>
      </Section>
    )
  }
}
