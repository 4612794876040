import React from 'react'

export default class Border extends React.Component {
  constructor(props) {
    super(props)

    this.state = {

    }

  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }


  render() {
    let {
    } = this.state

    let backgroundColor = this.props.darkMode ? "#f8f8f8" : ""
    let style = {
      backgroundColor
    }
    return (
      <div
        className={`boxed boxed--sm boxed--border overflow-visible sm-box ${this.props.className}`}
        style={style}
      >

        {this.props.children}
      </div>
    )
  }
}

Border.defaultProps = {
  className: '',
  darkMode: true
}
