import React from "react";

import Border from "../Border";

import ImageUploader from "../blocks/editable/ImageUploader";

export default class ImageInputItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      image: props.image,
      index: props.index,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  render() {
    let { image, index } = this.state;

    let Tag = this.props.nameTag;

    return (
      <Border>
        <div className="render-preview-image">
          <div className="row image-head">
            <div className="col-md-6">
              <Tag>
                {this.props.name} {index + 1}
              </Tag>
            </div>
            <div className="col-md-6 text-right">
              {this.props.deletable && (
                <a
                  className="delete-item delete-item-collapsible"
                  style={{ right: 20 }}
                  onClick={() => this.props.onDelete(index)}
                />
              )}
            </div>
          </div>
          {this.props.renderHeader()}
          <ImageUploader
            title={false}
            src={image.thumbnail}
            closeOnUploaded={false}
            onUploaded={(uploadedImage) => this.props.onUpdated(uploadedImage)}
          />
        </div>
      </Border>
    );
  }
}

ImageInputItem.defaultProps = {
  name: "Image",
  nameTag: "h3",
  deletable: true,
  renderHeader: () => null,
};
