import React from 'react'

import H5 from './blocks/editable/H5'
import P from './blocks/editable/P'

export default class TextInfos extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      textInfos: props.textInfos,
      textAlign: props.textAlign || "center",
      color: props.color,
      editable: props.editable
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  render() {
    let {
      textInfos,
      textAlign,
      color,
      editable
    } = this.state

    if(!textInfos){
      return null
    }

    return textInfos.map((textInfo, index) => {
      return (
        <div key={ textInfo.id } className="text-block">
            <H5
              text={ textInfo.title }
              color={ color }
              editable={ editable }
              textAlign={ textAlign }
              onUpdated={text => {
                textInfo.title = text
                textInfos[index] = textInfo
                this.props.onUpdated(textInfos)
              }}
            />
            <P
              text={ textInfo.subtitle }
              color={ color }
              lead={false}
              editable={ editable }
              textAlign={ textAlign }
              onUpdated={text => {
                textInfo.subtitle = text
                textInfos[index] = textInfo
                this.props.onUpdated(textInfos)
              }}
            />
        </div>
      )
    })
  }
}
