import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default class Notify {

  static success(message="Success"){
    toast.success(message, {
      position: "bottom-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      closeButton: false,
      pauseOnFocusLoss: false,
    });
  }

  static error(message="An unexpected error occured, please try again"){
    toast.error(message, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      closeButton: false,
      pauseOnFocusLoss: false,
    });
  }
}
