import React from 'react'
import Collapsible from 'react-collapsible';
import Border from '../Border'

import PortalModal from "./PortalModal"

import IconPickerGrid from '../IconPickerGrid';

import { IconsMind } from "../../utils/IconsMind"
import { MaterialIcons } from "../../utils/MaterialIcons"

export default class IconPickerModal extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      show: props.show,
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  render() {
    let {
      show,
    } = this.state

    return (
      <PortalModal
        show={show}
        id='icon-picker-modal'
        onHide={() => this.props.onHide()}
      >
        <h3>Icons</h3>

        <Border>

            <IconPickerGrid
              type={this.props.type}
              onSelected={icon => this.props.onSelected(icon)}
            />

            <div>
              <a
                className="btn btn--primary w-100 mt-4"
                style={{
                  color: "white",
                  borderColor: "#222",
                  backgroundColor: "#222"
                }}
                onClick={() => this.props.onHide()}
              >
                Cancel
              </a>
            </div>


        </Border>
      </PortalModal>
    )
  }
}

IconPickerModal.defaultProps = {
    message: null,
    loading: false,
    type: "iconsmind"
}
