import React from 'react'

import Collapsible from 'react-collapsible';

import TextareaAutosize from 'react-autosize-textarea';


export default class TextInfoInputItem extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      textInfo: props.textInfo,
      index: props.index,
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _renderSubtitle(textInfo, index){
    if(!textInfo.subtitle && this.props.canHideSubtitle){
      return null
    }

    return (
      <>
        <br/><br/>
        <span>
        { this.props.subtitleName }
        </span>
        <input
            type="text"
            defaultValue={ textInfo.subtitle }
            onChange={event => {
              let subtitle = event.target.value
              textInfo.subtitle = subtitle
              this.setState({ textInfo })
              this.props.onUpdated(textInfo)
            }}
        />
      </>
    )
  }

  render(){
    let {
      textInfo,
      index,
    } = this.state

    return (
      <Collapsible
        trigger={ textInfo.title }
        className="mb--1"
        triggerSibling={() => {
          if(!this.props.deletable){
            return null
          }
          return (
            <a
              className="delete-item delete-item-collapsible"
              style={{ right: 40 }}
              onClick={() => {
                this.props.onDelete(index)
              }}
            />
          )
        }}
      >
        <div className="boxed boxed--sm">
          <span>
          { this.props.titleName }
          </span>
          <input
              type="text"
              defaultValue={ textInfo.title }
              onChange={event => {
                let title = event.target.value
                textInfo.title = title
                this.setState({ textInfo })
                this.props.onUpdated(textInfo)
              }}
          />
          { this._renderSubtitle(textInfo, index) }
          <br/><br/>
          <span>
            { this.props.bodyName }
          </span>
          <TextareaAutosize
              type="text"
              defaultValue={ textInfo.body }
              onChange={event => {
                let body = event.target.value
                textInfo.body = body
                this.setState({ textInfo })
                this.props.onUpdated(textInfo)
              }}
          />
        </div>
      </Collapsible>
    )
  }
}

TextInfoInputItem.defaultProps = {
  titleName: "Title",
  subtitleName: "Subtitle",
  bodyNamr: "Body",
  deletable: true,
  canHideSubtitle: true
}
