import React from 'react'

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from "@material-ui/core/TextField";
import {FormHelperText} from "@material-ui/core";

export default class SingleSelect extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      data: props.data,
      index: props.index
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  _renderDropdown(data, index){
    return (
      <>
        <FormControl
          className='field-dropdown'
          fullWidth
          variant={"outlined"}
          error={data.error}
        >
          <InputLabel>{data.title}</InputLabel>
          <Select
            label={data.title}
            value={data.answer?.option_selected || ''}
            onChange={e => {
              let value = {field: data.id, option_selected: e.target.value};
              data.error = false
              data.answer = value
              this.setState({data})
              this.props.onUpdated(index, value)
            }}
          >
            {
              data.options.map(option => (
                <MenuItem key={option.id} value={option.id}>{option.text}</MenuItem>
              ))
            }
          </Select>
          {
            data.error &&
            <FormHelperText>This field is required.</FormHelperText>
          }
        </FormControl>
      </>
    )
  }

  _renderRadio(data, index){
    return (
      <>
        <FormControl
          className='field-radio'
          component="fieldset"
          fullWidth
          error={data.error}
        >
          <FormLabel component="legend">{data.title}</FormLabel>
          <RadioGroup
            value={parseInt(data.answer?.option_selected) || ''}
            onChange={e => {
              let value = {field: data.id, option_selected: e.target.value};
              data.error = false
              data.answer = value
              this.setState({data})
              this.props.onUpdated(index, value)
            }}
          >
            {
              data.options.map(option => (
                <FormControlLabel
                  value={option.id}
                  control={<Radio/>}
                  label={option.text}
                />
              ))
            }
          </RadioGroup>
          {
            data.error &&
            <FormHelperText>This field is required.</FormHelperText>
          }
        </FormControl>
      </>
    )
  }

  render() {
    let { data, index } = this.state

    if(data.alignment === "vertical"){
      return this._renderDropdown(data, index)
    }
    else{
      return this._renderRadio(data, index)
    }
  }
}
