import React, { Component } from 'react';


import InfiniteScroll from 'react-infinite-scroller';

export default class List extends Component  {

  constructor(props){
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
    this.state = {
      items: props.items,
      grow: props.grow
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  handleScroll(e) {
    if (document.documentElement.scrollHeight - 50 > document.body.offsetHeight + window.pageYOffset){
      return;
    }
    this.props.loadMore()
  }

  componentDidMount(){
    if(this.state.grow){
      window.addEventListener('scroll', this.handleScroll);
      setTimeout(this.handleScroll, 2000);
    }
  }

  componentWillUnmount(){
    if(this.state.grow){
      window.removeEventListener('scroll', this.handleScroll);
    }
  }

  _renderItems(){
    let { items } = this.state
    return items.map(item => {
      return this.props.renderItem(item)
    })
  }

  render(){
    let {
      items,
      grow,
    } = this.state

    if(grow){
      return this._renderItems()
    }

    return (
      <div style={{height: 400, overflow:'auto'}}>
        <InfiniteScroll
            pageStart={0}
            loadMore={this.props.loadMore}
            hasMore={this.props.hasMore}
            useWindow={false}
            loader={<div>Loading ...</div>}
        >
          <div className="row">
            { this._renderItems() }
          </div>
        </InfiniteScroll>
      </div>
    )
  }


}
