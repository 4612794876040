import React from 'react'

export default class Label extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      data: props.data,
    }
  }

  render() {
    let { data } = this.state

    return (
      <h5 className="text-center">{ data.title }</h5>
    )
  }
}
