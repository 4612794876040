import React from 'react'
import Collapsible from 'react-collapsible';

import Button from '../Button'

import H1 from './editable/H1'
import P from './editable/P'
import Section from './editable/Section'

import Border from '../Border'

import General from '../../utils/General'
import Backend from '../../utils/Backend'

export default class Block7 extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      data: props.data,
      editable: props.editable
    }

    this.updateContentBlock = General.debounce(() => {
      console.log("3333", this.state.data)
      Backend.updateContentBlock(this.state.data)
    }, 1000, false)
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _update(data, callback){
    this.setState({ data }, () => {

      if(callback){
        callback()
      }
      this.updateContentBlock()
    })
  }

  _renderModalContent(data){
    if(data.background_video == null){
      data.background_video = {
        "settings": {

        }
      }
    }
    return (
      <Border>
        <Collapsible
          trigger="Youtube Video URL"
        >
          <br></br>
          <input
              type="text"
              defaultValue={ data.background_video.url }
              onChange={event => {
                data.background_video.url = event.target.value
                this._update(data, () => {
                  General.updateVideo()
                })
              }}
          />
        </Collapsible>
      </Border>
    )
  }

  render(){
    let { data, editable } = this.state

    let videoUrl = data.background_video ? data.background_video.url : null
    let videoId = General.getYoutubeVideoId(videoUrl)

    return (
      <Section
          data={ data }
          index={ this.props.index }
          className={ "block-07 imagebg videobg height-100 text-center event-banner "+this.props.className }
          editable={ editable }
          tertiaryColor={false}
          primaryButtonToggle={true}
          secondaryButtonToggle={true}
          onUpdated={data => this._update(data)}
          onDelete={() => this.props.onDelete()}
          onAddContentBlockPressed={contentBlock => this.props.onAddContentBlockPressed(contentBlock)}
          renderModalContent={() => this._renderModalContent(data)}
          canMoveUp={ this.props.canMoveUp }
          canMoveDown={ this.props.canMoveDown }
          onVisibilityButtonPressed={(index) => this.props.onVisibilityButtonPressed(index)}
          onMoveUpPressed={ () => this.props.onMoveUpPressed() }
          onMoveDownPressed={ () => this.props.onMoveDownPressed() }
      >
          <div key={videoId+"_"+this.props.index} className="youtube-background" data-video-url={ videoId }>
          </div>
          <div className="background-image-holder">
            <img alt="" src={ null }/>
          </div>
          <div className="container pos-vertical-center">
              <div className="row">
                  <div className="col-md-8 col-lg-7">
                      <H1
                        text={ data.title }
                        color={ data.settings.primary_color }
                        editable={ editable }
                        onUpdated={text => {
                          data.title = text
                          this._update(data)
                        }}
                      />

                      <P
                        text={ data.subtitle }
                        color={ data.settings.secondary_color }
                        editable={ editable }
                        onUpdated={text => {
                          data.subtitle = text
                          this._update(data)
                        }}
                      />

                      <div className="c-btn-group">

                        <Button
                          data={data.secondary_button}
                          type="secondary"
                          editable={ editable }
                          onUpdated={button => {
                            data.secondary_button = button
                            this._update(data)
                          }}
                        />

                        <Button
                          data={data.primary_button}
                          editable={ editable }
                          onUpdated={button => {
                            data.primary_button = button
                            this._update(data)
                          }}
                        />

                      </div>

                  </div>
              </div>
          </div>
      </Section>
    )
  }
}
