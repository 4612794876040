import React from "react";

import Dropdown from "./Dropdown";

import $ from "jquery";

import General from "../utils/General";
import Backend from "../utils/Backend";

export default class LinkSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      url: props.url,
      options: [],
      pages: window.PAGES,
    };
  }

  componentDidMount() {
    this.setState({
      options: this._getOptions(),
    });

    this._setPages();
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      ...nextProps,
      options: this._getOptions(),
    });
  }

  _setPages() {
    Backend.getPages().then((pages) => {
      this.setState({
        pages,
      });
    });
  }

  _getBlockOptions() {
    let sections = $(".main-container section");
    let blockOptions = [];
    for (var i = 0; i < sections.length; i++) {
      let section = sections[i];

      let blockNo = parseInt($(section).attr("data-index")) + 1;
      let title = "Block " + blockNo + " | " + $(section).attr("data-name");

      let pageSlug = window.CURRENT_PAGE_SLUG;
      let url;

      if (pageSlug === "index") {
        pageSlug = "";
      }

      if (pageSlug != "") {
        url = `/${pageSlug}#${$(section).attr("id")}`;
      } else {
        url = `/#${$(section).attr("id")}`;
      }

      let blockOption = {
        value: url,
        label: title,
        className: "blockOption",
      };

      blockOptions.push(blockOption);
    }
    return blockOptions;
  }

  _getPageOptions() {
    let { pages } = this.state;
    if (!pages) {
      return [];
    }

    return pages.map((page) => {
      let pageSlug = page.slug;
      if (pageSlug === "index") {
        pageSlug = "";
      }
      return {
        value: `/${pageSlug}`,
        label: page.title,
        className: "blockOption",
      };
    });
  }

  _getOptions() {
    const options = [
      { value: "https://", label: "Link To External / Custom Url" },
      {
        type: "group",
        name: "Link To Block",
        items: this._getBlockOptions(),
      },
      {
        type: "group",
        name: "Link To Page",
        items: this._getPageOptions(),
      },
    ];

    return options;
  }

  _getSelectedOption(options, value) {
    if (options.length == 0) {
      return null;
    }

    let blockOptions = options[1]["items"];
    for (var i = 0; i < blockOptions.length; i++) {
      let blockOption = blockOptions[i];
      if (value === blockOption.value) {
        return blockOption;
      }
    }

    let pageOptions = options[2]["items"];
    for (var i = 0; i < pageOptions.length; i++) {
      let pageOption = pageOptions[i];
      if (value === pageOption.value) {
        return pageOption;
      }
    }

    return options[0];
  }

  _renderCustomUrl(url, option) {
    if (!option || option.value !== "https://") {
      return null;
    }

    return (
      <div className="mb--1">
        <span>Url</span>
        <input
          type="text"
          defaultValue={url}
          onChange={(event) => {
            this.props.onUpdated(event.target.value);
          }}
        />
        <p>
          To link to an external you must start your url with http:// or https
        </p>
      </div>
    );
  }

  render() {
    let { options, url } = this.state;

    let option = this._getSelectedOption(options, url);
    return (
      <>
        <Dropdown
          options={options}
          value={option}
          onChange={(option) => {
            this.setState({ url: option.value });
            this.props.onUpdated(option.value);
          }}
          placeholder="Select an option"
        />
        {this._renderCustomUrl(url, option)}
      </>
    );
  }
}
