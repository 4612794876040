import React from 'react'

import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from "@material-ui/core/RadioGroup";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import {FormHelperText} from "@material-ui/core";

export default class MultiSelect extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      data: props.data,
      weight: props.weight,
      index: props.index,
      selected: []
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  render() {
    let {
      data,
      index,
      selected
    } = this.state

    return (
      <>
        <FormControl
          className='field-multi-select'
          component="fieldset"
          fullWidth
          error={data.error}
        >
          <FormGroup
            row
            component="fieldset"
            fullWidth
            inputProps={{id: `form-input-${data.id}`}}
            value={data.answer?.options_selected}
            onChange={e => {
              let valueIndex = selected.indexOf(e.target.value)
              if(valueIndex > -1){
                selected.splice(valueIndex, 1)
              }else{
                selected.push(e.target.value)
              }

              let value = {field: data.id, options_selected: selected};
              data.error = false
              data.answer = value
              this.props.onUpdated(index, value)
              this.setState({selected, data})
            }}
          >
            <FormLabel component="legend">{data.title}</FormLabel>
            {
              data.options.map(option => (
                <FormControlLabel
                  value={option.id}
                  label={option.text}
                  control={<Checkbox/>}
                />
              ))
            }
          </FormGroup>
          {
            data.error &&
            <FormHelperText>This field is required.</FormHelperText>
          }
        </FormControl>
      </>
    )
  }
}
