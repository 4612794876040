import React from 'react'

import General from '../../../utils/General'

import Grapick from 'grapick'

export default class GradientPicker extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      gradient: props.gradient || '',
      title: props.title || '',
      uuid: General.uuid()
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  componentDidMount(){
    let {
      data,
      gradient,
      uuid
    } = this.state

    const gp = new Grapick({
      el: '#gp_' + uuid,
      ttype: 'repeating-linear'
    });

    const gradientColors = gradient.split(',')

    var space = 100 / gradientColors.length

    for(var i=0; i < gradientColors.length; i++){
      var spacing = (i * space) + (space / 2)
      var color = gradientColors[i]
      gp.addHandler(spacing, color);
    }

    gp.on('change', complete => {
      var colors = this._getColorArray(gp)
      this.props.onUpdated(colors)
    })

  }

  _getColorArray(grapicker){
    var colorStrings = grapicker.getColorValue().split(', ')
    var colors = []

    for(var i=0; i < colorStrings.length; i++){
      var colorString = colorStrings[i].split(" ")[0]
      if(colorString.indexOf("#") > -1){
        colors.push(colorString)
      }
    }

    return colors
  }

  render(){
    let {
      data,
      title,
      uuid
    } = this.state

    return (
      <>
        <h3>{ title }</h3>
        <div id={"gp_" + uuid }>
        </div>
        <br/>
        <p className="lead">To edit the gradient, click a line to reveal the colour spot. You can then click the colour spot to edit the color. You can also adjust the gradient by moving lines or adding more colour spots by clicking the bar</p>
      </>
    )
  }
}
