import React from 'react'

import ContentLoader from "react-content-loader";

export default class MenuLoader extends React.Component {
  render() {
    return (
      <div className="text-center">
        <ContentLoader speed={1} style={{ width: "100%", height: "90"}}>
          {/* Only SVG shapes */}
          <rect x="0" y="0" rx="0" ry="0" width="100%" height="50%" />
        </ContentLoader>
      </div>
    )
  }
}
