import React from 'react'

import ContentLoader from "react-content-loader";

export default class ContentBlockLoader extends React.Component {
  render() {
    return (
      <section className="text-center">
        <div className="container">
          <div className="row justify-content-center" style={CENTER}>
            <ContentLoader speed={1} style={{ width: '50%' }}>
              {/* Only SVG shapes */}
              <rect x="0" y="0" rx="10" ry="10" width="100%" height="40" />
              <rect x="20%" y="55" rx="5" ry="5" width="60%" height="25" />
              <rect x="10%" y="100" rx="5" ry="5" width="35%" height="30" />
              <rect x="55%" y="100" rx="5" ry="5" width="35%" height="30" />
            </ContentLoader>
          </div>
        </div>
      </section>
    )
  }
}

const CENTER = {
  height: "80vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}
