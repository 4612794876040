import React from 'react'
import '../../assets/css/blocks/51.css'

import Border from '../Border'

import H2 from './editable/H2'
import Img from './editable/Img'
import P from './editable/P'
import Span from './editable/Span'
import BlockQuote from './editable/BlockQuote'
import Section from './editable/Section'

import TextInfoInputItem from '../sectioninputs/TextInfoInputItem'

import AddPackage from '../AddPackage';

import PackageItem from '../common/PackageItem';

import General from '../../utils/General'
import Backend from '../../utils/Backend'

export default class Block51 extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      key: 0,
      data: props.data,
      editable: props.editable,
      uuid: "cb_"+General.uuid(),
      packages: [],
      loading: true
    }

    this.section = React.createRef();

    this.updateContentBlock = General.debounce(() => {
      console.log("3333", this.state.data)
      Backend.updateContentBlock(this.state.data)

      this.setState({
        key: this.state.key + 1
      }, () => General.updateSlides())
    }, 1000, false)
  }

  componentDidMount(){
    Backend.getPackages()
    .then(packages => {
      this.setState({ packages, loading: false })
    })
    .catch(error => {
      this.setState({ loading: false })
    })
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _update(data, callback){
    let {
      key
    } = this.state

    this.setState({ ...data }, () => {

      if(callback){
        callback()
      }
      this.updateContentBlock()
    })
  }

  _renderPackages(data, packages){
    let {
      loading
    } = this.state

    if(loading){
      return null
    }

    return packages.map((pkg, index) => {
      return (
        <PackageItem pkg={pkg} data={data}/>
      )
    })
  }

  _renderContent(){
    let {
      data,
      editable,
      packages,
      loading
    } = this.state

    if(!loading && packages.length == 0){
      return <AddPackage/>
    }

    return (
      <>
        <div className="row text-block">
            <div className="col-md-12">
                <H2
                  text={ data.title}
                  color={ data.settings.primary_color }
                  editable={ editable }
                  onUpdated={text => {
                    data.title = text
                    this._update(data)
                  }}
                />
            </div>
        </div>
        <div className="row justify-content-center">
          { this._renderPackages(data, packages) }
        </div>
      </>
    )

  }


  render(){
    let { data, editable, uuid, key } = this.state
    return (
      <Section
          ref={this.section}
          data={ data }
          index={ this.props.index }
          className={ " block-51 text-center " + this.props.className }
          editable={ editable }
          onUpdated={data => this._update(data)}
          onDelete={() => this.props.onDelete()}
          primaryColorTitle="Title Colour"
          secondaryColorTitle="Price Colour"
          quaternaryColorTitle="Benefits Colour"
          quinaryColorTitle="Accent Colour"
          senaryColorTitle="Card Background Colour"
          onAddContentBlockPressed={contentBlock => this.props.onAddContentBlockPressed(contentBlock)}
          canMoveUp={ this.props.canMoveUp }
          canMoveDown={ this.props.canMoveDown }
          onVisibilityButtonPressed={(index) => this.props.onVisibilityButtonPressed(index)}
          onMoveUpPressed={ () => this.props.onMoveUpPressed() }
          onMoveDownPressed={ () => this.props.onMoveDownPressed() }
      >
          <div class="container">
            { this._renderContent() }
          </div>
          <style dangerouslySetInnerHTML={{__html: `
               .block-51 .pricing-rates.business-rate:hover {
                 border-color: ` + data.settings.quinary_color + ` !important;
               }
             }
          `}}
          />
      </Section>
    )
  }
}
