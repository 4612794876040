import React from 'react'

import { List, Grid } from "react-virtualized";

import { IconsMind } from "../utils/IconsMind"
import { MaterialIcons } from "../utils/MaterialIcons"

export default class IconPickerGrid extends React.Component {
  constructor(props){
    super(props)

    let icons = props.type === "iconsmind" ? IconsMind : MaterialIcons
    this.state = {
      icons,
      show: props.show,
      searchTerm: "",
      filteredIcons: icons
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _renderIconImage(icon){
    if(this.props.type === "iconsmind"){
      return (
          <i class={`icon icon-${icon.name} color--dark`}
            style={{
              fontSize: 30,
            }}
          />
      )
    }
    else{
      return (
          <i class={`material-icons`}
            style={{
              fontSize: 30,
            }}
          >
            { icon.ligature }
          </i>
      )
    }
  }

  _renderIcon = ({ columnIndex, isScrolling, isVisible, key, parent, rowIndex, style }) => {
    let { filteredIcons } = this.state
    let offset = (rowIndex * 4)
    let icon = filteredIcons[columnIndex + offset]
    if(!icon){
      return <div/>
    }

    return (
      <div
        key={key}
        style={style}
        className="text-center"
      >
        <a onClick={() => this.props.onSelected(icon)} style={{ cursor: 'pointer' }}>
          { this._renderIconImage(icon) }
          <p style={{
            fontSize: 12,
            padding: 8,
            overflow: 'hidden',
            textOverflow: 'ellipsis'
           }}>{ icon.name }</p>
        </a>
      </div>
    );
  };

  render() {
    let {
      show,
      icons,
      searchTerm,
      filteredIcons,
    } = this.state

    return (
      <>
        <div className="form-group">
          <input
            type="text"
            name="search"
            placeholder="Search"
            className="form-control form-control-lg"
            value={searchTerm}
            onChange={e => {
              let searchTerm = e.target.value.toLowerCase()
              filteredIcons = icons.filter(icon => icon.name.toLowerCase().indexOf(searchTerm) > -1)
              this.setState({
                filteredIcons,
                searchTerm
              })
            }}
          />
        </div>

        <div className="icons-grid py-3">

          <Grid
            className={this.props.className}
            cellRenderer={this._renderIcon}
            columnCount={3}
            columnWidth={this.props.columnWidth}
            width={this.props.width}
            height={400}
            rowHeight={this.props.rowHeight}
            rowCount={Math.ceil(filteredIcons.length / 4)}
            overscanRowCount={3}
          />

        </div>
      </>
    )
  }
}

IconPickerGrid.defaultProps = {
    message: null,
    loading: false,
    type: "iconsmind",
    columnWidth : 120,
    width : 390,
    rowHeight : 130,
    className : "r-grid-height"
}
