import React from "react";
import "../assets/css/metronic/pages/login/login-1.css";

import moment from "moment"

import { Helmet } from "react-helmet";

import Form from "../components/form/Form";

import Div from "../components/layouts/Div";

import ServiceItem from "../components/common/ServiceItem";

import WeekCalendar from "../components/common/WeekCalendar";
import LazyLoadingList from "../components/common/LazyLoadingList";

import General from "../utils/General";
import Backend from "../utils/Backend";
import Currency from "../utils/Currency";

import Notify from "../utils/Notify";
import Website from "../utils/Website";

export default class Bookings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      bookingForm: null,
      website: props.website,
    };
  }

  componentDidMount(){
    let {
      website
    } = this.state

    Backend.getBookingForm(website.id)
    .then(forms => {
      this.setState({
        bookingForm: forms[0],
        loadingWebsiteForms: false
      })
    }).catch(e => {
      Notify.error(e.message)
    })
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  render(){
    let {
      loading,
      bookingForm,
      website,
    } = this.state

    if(!bookingForm){
      return <p>Loading...</p>
    }

    return (
      <div className="main-container">
        <section className="block-56 booking m-0">

          <Div
            className="container"
          >
            <div className='row items text-left'>
              <LazyLoadingList
                endpoint={`${window.Api.Services}?website_id=${website.id}&form_id=${bookingForm.id}`}
                onItemUpdated={services => {
                  this.setState({
                    services,
                    loadingServices: false
                  })
                }}
                renderItem={(service) => {
                  return (
                    <ServiceItem service={service} form={bookingForm}/>
                  )
                }}
              />
            </div>
          </Div>
        </section>
      </div>
    )
  }
}
