import React from 'react'

import ProductVariantsModal from './modal/ProductVariantsModal'

import { getImageUrl, Sizes } from '../components/blocks/editable/Img'

import General from '../utils/General'
import Currency from '../utils/Currency'
import Cart from '../utils/Cart'

export default class Product extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      shop : props.shop,
      product: props.product,
    }

  }

  componentDidMount() {
    General.updateLazyBackgroundImages()
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)

  }

  _renderPrice(product){

    if(product.on_sale){
      return (
        <>
          <span class="h4 type--strikethrough m-0 pr-1">{ Currency.format(product.price, window.CURRENT_WEBSITE_SHOP_CURRENCY) }</span>
          <span class="h4 m-0">{ Currency.format(product.sale_price, window.CURRENT_WEBSITE_SHOP_CURRENCY) }</span>
        </>
      )
    }
    return <span class="h4 m-0">{ Currency.format(product.price, window.CURRENT_WEBSITE_SHOP_CURRENCY) }</span>
  }

  render() {
    let {
      product,
      showProductVariantsModal
    } = this.state

    if(product == null){
      return (
        <div class="col-md-4">
            <div class="feature feature-1 position-relative">
                <div className="shimmer w-100" style={{height: 290 }}/>
            </div>
        </div>
      )
    }

    let outOfStock = Cart.outOfStock(product)

    return (
      <div class="col-md-4 tile-product item">
          <div class="feature feature-1 position-relative">
            {
              product.on_sale &&
              <span class="label product-sale-price">Sale</span>
            }
              <a
                class="block"
                priority={this.props.index}
                href={`/shop/product/${product.slug}`}
              >
                <span
                  class="tile-image block lazy-background"
                  style ={{ backgroundImage: "url("+getImageUrl(product.featured_image, "product")+")" }}
                >
                  &nbsp;
                </span>
              </a>
              <div class="feature__body boxed boxed--border pb-0 bt-0 btr-0">
                  <h5>{ product.name }</h5>
                  <div class="d-flex align-items-center price">
                      {this._renderPrice(product)}
                  </div>
                  {/* starts, buttons  */}
                  <div class="row buttons no-gutters">
                      <div class="col-6">
                          <a
                            class="btn btn--primary btn-left btn-products px-0"
                            href={`/shop/product/${product.slug}`}
                          >
                            <span class="btn__text">
                              More Details
                            </span>
                          </a>
                      </div>
                      <div class="col-6">
                         { !outOfStock &&
                          <a
                            class="btn btn--primary btn-right btn-products px-0"
                            onClick={() => {
                              if(product.options && product.options.length > 0){
                                this.setState({ showProductVariantsModal: true })
                              }
                              else{
                                this.props.onAddToCartPressed(product)
                              }
                            }}
                          >
                            <span class="btn__text">
                              Add To Cart
                            </span>
                          </a>
                        }
                        { outOfStock &&
                         <a
                           class="btn btn--primary btn-right btn-products"
                           onClick={() => {}}
                           disabled={true}
                         >
                           <span class="btn__text">
                             Out Of Stock
                           </span>
                         </a>
                       }
                      </div>
                  </div>
                  {/* end, buttons  */}
              </div>
          </div>
        {
          showProductVariantsModal &&
          <ProductVariantsModal
            show={showProductVariantsModal}
            product={product}
            onSelected={variant =>  {
              this.setState({ showProductVariantsModal: false })
              this.props.onAddToCartPressed(product, variant)
            }}
            onHide={() => this.setState({ showProductVariantsModal: false })}
          />
        }
      </div>
    )
  }
}
