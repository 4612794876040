import React from 'react'

import TextField from '@material-ui/core/TextField';

export default class TextArea extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      data: props.data,
      index: props.index
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  render() {
    let { data, index } = this.state

    let classNames = data.is_required ? "validate-required" : ""

    return (
      <>
        <TextField
          className='field-textarea'
          error={data.error}
          helperText={data.error ? "This field is required." : ""}
          multiline
          rows={5}
          fullWidth
          label={data.title}
          variant={"outlined"}
          inputProps={{id: `form-input-${data.id}`}}
          value={data.answer?.value || ''}
          onChange={e => {
            let value = {field: data.id, value: e.target.value};
            data.error = false
            data.answer = value
            this.setState({data})
            this.props.onUpdated(index, value)
          }}
        />
      </>
    )
  }
}
