import React from 'react'

import PortalModal from './PortalModal'

import AuthManager from '../../utils/AuthManager'
import Backend from '../../utils/Backend'

export default class PasswordModal extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      loading: false,
      show: props.show,
      edit: props.edit,
      error: props.error,
      websiteUrl: props.websiteUrl
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  componentDidMount(){
    let emailInput = document.getElementById("email")
    if(emailInput){
      emailInput.focus()
    }
  }

  _onConfirmPressed(){
    let {
      edit,
      email,
      password,
      websiteUrl
    } = this.state

    this.setState({ error: null })

    if(password == null || password == ""){
      this.setState({ error: "Please enter a password" })
      return
    }
    else if(edit && (email == null || email == "")){
      this.setState({ error: "Please enter an email" })
      return
    }

    this.setState({ loading: true })
    this._validate(edit, email, password, websiteUrl)
    .then(() => this.props.onLoggedIn())
    .catch(error => {
        console.log("--", error)
      if(error.message.indexOf("No page found") > -1){
        window.location = "/404"
        return
      }
      this.setState({ error: "Ooops... incorrect email or password", loading: false })
    })
  }

  _validate(edit, email, password, websiteUrl){

    if(edit){
      return AuthManager.login(email, password, websiteUrl)
    }
    else{
      return new Promise((resolve, reject) => {
        localStorage.setItem('password', password)
        resolve()
      })
    }
  }

  _handleKeyDown(e){
    if (e.key === 'Enter') {
      e.preventDefault()
      this._onConfirmPressed()
    }
  }


  _renderInputs(edit){
    return (
      <>
        { this._renderInputEmail(edit) }
        <input
            type="password"
            placeholder="Password"
            onChange={event => this.setState({
              error: null,
              password: event.target.value
            })}
            onKeyDown={e => this._handleKeyDown(e)}
        />
      </>
    )
  }

  _renderInputEmail(edit){
    if(!edit){
      return null
    }

    return (
        <input
            type="email"
            placeholder="Email"
            id="email"
            onChange={event => this.setState({
              error: null,
              email: event.target.value
            })}
            onKeyDown={e => this._handleKeyDown(e)}
        />
    )
  }

  render() {
    let {
      show,
      edit,
      error,
      confirmText,
      loading
     } = this.state

     let title = "Password Required"
     let subtitle= "Please enter your password above. If you don't have a password please contact the site owner"

     if(edit){
       title = "Login Required"
       subtitle = ""
     }

     let buttonText = edit ? "Login" : "Submit"

     return (
      <PortalModal
        showAsSideMenu="false"
        id="root"
        show={show}
        onHide={() => this.setState({ error: "Please fill in the above to access the site"})}
      >
          <h2>{ title }</h2>
          <hr
            className="short"
            style={{borderColor: window.CURRENT_PAGE_PRIMARY_COLOR}}
          />

          { this._renderInputs(edit)}

          <br/><br/>

          <p className="lead"
            style={{
              color: error ? '#fa6160' : null
            }}
          >
            { error || subtitle }
          </p>


          { !loading &&
            <div class="text-right">
              { edit &&
                <a
                  className="btn btn--secondary type--uppercase"
                  onClick={() => this.props.onHide()}>
                    <span className="btn__text">
                        Cancel
                    </span>
                </a>
              }
              <a
                className="btn btn--primary type--uppercase"
                onClick={() => this._onConfirmPressed()}
                style={{
                  borderColor: window.CURRENT_PAGE_PRIMARY_COLOR,
                  backgroundColor: window.CURRENT_PAGE_PRIMARY_COLOR
                }}
              >
                  <span className="btn__text">
                      { buttonText }
                  </span>
              </a>
            </div>
          }
      </PortalModal>
    )
  }
}
