import React from 'react'

import Img, { getImageUrl, Sizes } from '../components/blocks/editable/Img'

import { Helmet } from "react-helmet";

import { Elements, ElementsConsumer, CardElement } from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js/pure';

import Text from './form/fields/Text'

import Div from '../components/layouts/Div'
import BoipaModal from '../components/modal/BoipaModal'

import LocationInput from '../components/blocks/editable/LocationInput'

import General from '../utils/General'
import Backend from '../utils/Backend'
import ScriptCache from '../utils/ScriptCache'
import Cart from '../utils/Cart'
import Currency from '../utils/Currency'
import Notify from '../utils/Notify'

loadStripe.setLoadParameters({advancedFraudSignals: false});
let stripePromise = null

let CARD_ELEMENT_STYLE = {
  base: {
    iconColor: '#666666',
    color: '#fff',
    fontSize: '16px',
    '::placeholder': {
      color: '#87BBFD',
    },
  },
  invalid: {
    iconColor: '#FFC7EE',
    color: '#FFC7EE',
  },
}

export default class PaymentDetails extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      website: props.website,
      shop: props.shop,
      error: null,
      paymentMethod: props.website.payment_method
    }

    this.boipaModal = React.createRef()
  }

  componentDidMount() {
    this._setup()
    this._checkBoipaState()
  }

  _checkBoipaState(){
    let url = new URL(window.location.href)
    console.log("url", url)
    let params = new URLSearchParams(url.search)

    let merchantTxId = params.get("merchantTxId")

    if(merchantTxId == null){
      return
    }

    let result = params.get("result")

    params.delete("merchantTxId")
    params.delete("txId")
    params.delete("currency")
    params.delete("amount")
    params.delete("result")

    let pathName = window.location.pathname
    if(params.toString()){
      pathName += "?" + params.toString()
    }
    window.history.replaceState(null, window.document.title, pathName)

    if(result == "success"){
      this.props.onAsyncPaymentSuccess()
    }
    else{
      this.props.onAsyncPaymentFailed()
    }
  }


  _setup(){
    let {
      shop,
      website,
      paymentMethod
    } = this.state

    let { processor } = paymentMethod

    if(processor == "stripe"){
      if(shop?.stripe_account_id){
        stripePromise = loadStripe(window.Api.StripePubishableKey, {
          stripeAccount: shop.stripe_account_id
        })
      }
    }else if(processor == "boipa"){
      let { javascript_form_url } = paymentMethod
      General.loadScript(javascript_form_url)
    }
  }

  getPaymentDetails(amount, customer){
    let { paymentMethod, paymentIntentId } = this.state
    if(paymentMethod.processor == "boipa"){
      return new Promise((resolve,reject) => {
        let originUrl = window.location.href
        return resolve({
          customer,
          tokenize: true,
          origin_url: "*",
          return_url: originUrl,
          browser: this._getBrowserData(),
        })
      })
    }

    return this._getStripePaymentMethod()
    .then(paymentMethod => {
      let data = {
        payment_method_id: paymentMethod.id
      }
      if(paymentIntentId){
        data.payment_intent_id = paymentIntentId
      }
      return data
    })
  }

  validatePayment(response, amount, currencyCode){
    let { paymentMethod } = this.state
    if(paymentMethod.processor == "boipa"){
      return this.boipaModal.current._setupPaymentForm(
        response.token,
        paymentMethod,
        currencyCode
      )
    }
    return this.stripe.handleCardAction(response.client_secret)
    .then(result => {
      if(result.error){
        throw {vmessage: result.error.message }
        return
      }

      this.setState({ paymentIntentId: result.paymentIntent.id })
      return true
    })
  }

  clearForm(){
    if(!this.elements){
      return
    }
    const cardElement = this.elements.getElement(CardElement)
    cardElement.clear()
  }

  async _getStripePaymentMethod(){
    if(!this.stripe || !this.elements){
      throw { message: "Unexpected error, please try again" }
    }

    const cardElement = this.elements.getElement(CardElement)

    return this.stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name: this.state.first_name + " " + this.state.last_name,
        email: this.state.email
      },
    })
    .then(({paymentMethod, error}) => {
      if(paymentMethod == null){
        throw { message: "Please check your card details and try again later" }
      }
      return paymentMethod
    });
  }

  _getBrowserData(){
    return {
      user_agent: window.navigator.userAgent,
      language: window.navigator.language,
    }
  }

  _renderBoipa(){
    return (
      <BoipaModal
        ref={this.boipaModal}
        modalClassName={"general"}
        onClose={() => this.setState({ token: null })}
      />
    )
  }

  render(){
    let {
      paymentMethod
    } = this.state

    if(Object.keys(paymentMethod).length === 0){
      return (
        <>
          <div className="form-row-container">
            <div className="row form-row align-items-center">
              <div className="col form-field contact_email">
                <Text
                  data={{
                    title: "Card Number",
                    type: "number",
                    alignment: "horizontal",
                    disabled: true
                  }}
                />
              </div>
            </div>
          </div>
        </>
      )
    }

    if(paymentMethod.processor == "boipa"){
      return this._renderBoipa()
    }

    return (
      <>
        { stripePromise &&
          <div class="fields c-card payment-stripe">
            <div class="form-control card-input-container my-auto">
              <Elements stripe={stripePromise}>
                <ElementsConsumer>
                  {({stripe, elements}) => {
                    this.stripe = stripe
                    this.elements = elements

                    return (
                      <CardElement
                        stripe={this.stripe}
                        options={{
                          style: {
                            base: {
                              iconColor: '#000',
                              marginTop: '20px',
                              fontSize: '14px',
                              color: '#333',
                              '::placeholder': {
                                color: '#c5c5c5',
                                iconColor: "red",
                              },
                            },
                          },
                        }}
                      />
                    )
                  }}
                </ElementsConsumer>
              </Elements>
            </div>
          </div>
        }
      </>
    )
  }
}
