import React from 'react'

import PortalModal from "./PortalModal"

import General from '../../utils/General'
import FetchHelper from '../../utils/FetchHelper'
import Notify from '../../utils/Notify'
import Event from '../../utils/Event'
import Backend from '../../utils/Backend'

export default class LeadModal extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      show: false,
      loading: false,
      website: props.website
    }
  }

  componentDidMount(){
    Event.on('SHOW_LEAD_MODAL', () => this.setState({
      show: true
    }));
  }

  componentWillUnmount(){
    Event.off('SHOW_LEAD_MODAL');
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  render() {
    let {
      show,
      loading,
      website
    } = this.state

    let title = website ? website.lead_success_title : "Message Sent"
    let message = website ? website.lead_success_message : "We will be back to you shortly."

    return (
      <PortalModal
        show={show}
        showAsSideMenu="false"
        onHide={() => this.setState({ show: false })}
      >
        <h3>{ title }</h3>

        <p>
          { message }
        </p>

        <div className="c-btn-group text-center mt-5">
          <a
            className="btn btn--primary w-100"
            style={{
              color: "white",
              borderColor: "#222",
              backgroundColor: "#222"
            }}
            onClick={() => this.setState({ show: false })}
          >
              Close
          </a>

        </div>
      </PortalModal>
    )
  }
}
