import React from 'react'

import Button from '../Button'
import P from './editable/P'
import Img, { Sizes } from './editable/Img'
import Section from './editable/Section'

import CountdownTimer from '../CountdownTimer'

import General from '../../utils/General'
import Backend from '../../utils/Backend'

export default class Block1 extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      data: props.data,
      editable: props.editable,
    }

    this.updateContentBlock = General.debounce(() => {
      console.log("3333", this.state.data)
      Backend.updateContentBlock(this.state.data)
    }, 1000, false)
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _update(data, callback){
    this.setState({ data }, () => {

      if(callback){
        callback()
      }
      this.updateContentBlock()
    })
  }


  render(){
    let { data, editable } = this.state

    return (
      <Section
          data={ data }
          index={ this.props.index }
          className={"block-01 imagebg height-100 text-center event-banner "+this.props.className}
          editable={ editable }
          primaryColorTitle="Countdown Color"
          tertiaryColor={false}
          primaryButtonToggle={true}
          secondaryButtonToggle={true}
          onUpdated={data => this._update(data)}
          onDelete={() => this.props.onDelete()}
          onAddContentBlockPressed={contentBlock => this.props.onAddContentBlockPressed(contentBlock)}
          image={data.background_image}
          imageTitle={"Background Image"}
          onImageUpdated={image => {
            data.background_image = image
            this._update(data, () => General.updateImageBackgrounds())
          }}
          onGradientUpdated={gradient => {
            data.background_gradient = gradient
            this._update(data, () => General.updateGradients())
          }}
          onUpdateSettings={settings => {
            data.settings = settings
            this._update(data)
          }}
          canMoveUp={ this.props.canMoveUp }
          canMoveDown={ this.props.canMoveDown }
          onVisibilityButtonPressed={(index) => this.props.onVisibilityButtonPressed(index)}
          onMoveUpPressed={ () => this.props.onMoveUpPressed() }
          onMoveDownPressed={ () => this.props.onMoveDownPressed() }
      >
          <div className="background-image-holder">
            <Img
              key={ data.background_image.id }
              img={ data.background_image }
              size={ Sizes.LARGE }
              alt="background"/>
          </div>
          <div className="container pos-vertical-center">
              <div className="row">
                  <div className="col-md-9">
                      <Img
                        img={ data.foreground_image }
                        alt={ "" }
                        size={Sizes.MEDIUM}
                        className={"banner_logo"}
                        editable={ editable }
                        onUpdated={image => {
                          data.foreground_image = image
                          this._update(data)
                        }}
                      />

                      <CountdownTimer
                          time={ data.countdown_timer }
                          color={ data.settings.primary_color }
                          editable={ editable }
                          onUpdated={time => {
                            data.countdown_timer = time
                            this._update(data)
                          }}
                      />

                      <P
                        text={ data.subtitle }
                        color={ data.settings.secondary_color }
                        editable={ editable }
                        onUpdated={text => {
                          data.subtitle = text
                          this._update(data)
                        }}
                      />

                      <div className="c-btn-group">

                        <Button
                            data={data.secondary_button}
                            type="secondary"
                            editable={ editable }
                            renderModal={content => this.props.renderModal(content)}
                            onUpdated={button => {
                              data.secondary_button = button
                              this._update(data)
                            }}
                        />

                        <Button
                            data={data.primary_button}
                            editable={ editable }
                            renderModal={content => this.props.renderModal(content)}
                            onUpdated={button => {
                              data.primary_button = button
                              this._update(data)
                            }}
                        />

                      </div>

                  </div>
              </div>
          </div>
      </Section>
    )
  }
}
