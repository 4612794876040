import React from 'react'

import Collapsible from 'react-collapsible';
import Border from '../Border'

import Section from './editable/Section'

import IconInfos from '../IconInfos'

import General from '../../utils/General'
import Backend from '../../utils/Backend'

import Titles from './editable/Titles';

export default class Block46 extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      data: props.data,
      editable: props.editable
    }

    this.updateContentBlock = General.debounce(() => {
      console.log("3333", this.state.data)
      Backend.updateContentBlock(this.state.data)
    }, 1000, false)
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _update(data, callback){
    this.setState({ data }, () => {

      if(callback){
        callback()
      }
      this.updateContentBlock()
    })
  }

  _getModalContent(data){
    return (
      <Border>
        <Collapsible
          trigger="Text Alignment"
        >
          <br></br>
          <div className="input-select">
            <select
              value={data.variation || "left"}
              onChange={e => {
                data.variation = e.target.value

                this._update(data)
              }}
            >
              <option value="left">Left Aligned</option>
              <option value="right">Right Aligned</option>
              <option value="center">Centred</option>
            </select>
          </div>
        </Collapsible>
      </Border>
    )
  }


  render(){
    let { data, editable } = this.state

    let blockClassName = " block-46 ";
    let rowClassName = " row ";
    let columnClassName = data.icon_infos.length > 3 ? "col-md-6 col-lg-3" : "col-md-4"

    if (window.IS_CARD_STYLE_WEBSITE) {
      blockClassName += " deep-cardify";
      rowClassName += data.icon_infos.length > 3 ? "dc-row-lg-v2" : ""
      columnClassName = "col-md item"
    }

    let alignment = "left"
    if(data.variation === "center"){
      alignment = "center"
    }else if(data.variation === "right"){
      alignment = "right"
    }

    if(data.settings.msc_enabled){
      data.settings.primary_color = window.CURRENT_PAGE_PRIMARY_COLOR
    }

    return (
      <Section
          data={ data }
          index={ this.props.index }
          className={ blockClassName +this.props.className }
          editable={ editable }
          onUpdated={data => {
            data.settings.msc_enabled = false
            this._update(data)
          }}
          primaryColorTitle="Icon Colour"
          secondaryColorTitle="Title Colour"
          quaternaryColorTitle="Text Colour"
          onDelete={() => this.props.onDelete()}
          onAddContentBlockPressed={contentBlock => this.props.onAddContentBlockPressed(contentBlock)}
          renderModalContent={() => this._getModalContent(data)}
          canMoveUp={ this.props.canMoveUp }
          canMoveDown={ this.props.canMoveDown }
          onVisibilityButtonPressed={(index) => this.props.onVisibilityButtonPressed(index)}
          onMoveUpPressed={ () => this.props.onMoveUpPressed() }
          onMoveDownPressed={ () => this.props.onMoveDownPressed() }
      >
          <div className="container items">
              <Titles
                data={ data }
                editable={ editable }
                onUpdated={data => this._update(data)}
                headingNumber={2}
              />
              <div className={rowClassName}>
                  <IconInfos
                    iconInfos={data.icon_infos}
                    containerClassName={columnClassName}
                    iconContainerClassName="feature feature-6 dc-padding"
                    iconSizeClassName="icon--sm"
                    textAlign={ alignment }
                    iconAlign={ alignment }
                    iconColor={ data.settings.primary_color }
                    titleColor={ data.settings.secondary_color }
                    subtitleColor={ data.settings.quaternary_color }
                    editable={ editable }
                    onUpdated={iconInfos => {
                      data.icon_infos = iconInfos
                      this._update(data)
                    }}
                  />
              </div>
          </div>
      </Section>
    )
  }
}
