import React from 'react'

import TextField from '@material-ui/core/TextField';

import Currency from '../../../utils/Currency'

export default class Price extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      data: props.data,
      index: props.index,
      fixedPrice: props.data.price != null
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  render() {
    let { data, fixedPrice, index } = this.state

    let type = data.type
    let errorMessage = "Invalid amount, must be greater than 50 cents"

    if(type === "phone" || type === "contact_phone"){
      errorMessage = "Please enter a valid phone number."
    }

    if(type === "email" || type === "contact_email"){
      errorMessage = "Please enter a valid email address."
    }

    let symbol = Currency.getSymbol(window.CURRENT_WEBSITE_SHOP_CURRENCY)
    let title = data.title
    if(fixedPrice){
      title = Currency.format(data.price, window.CURRENT_WEBSITE_SHOP_CURRENCY)
    }

    return (
      <div className='fields price'>
        <div class="input-group">
          <span class="input-group-text" id="basic-addon1">{symbol}</span>
          <input
            class="form-control"
            type="number"
            placeholder="0.00"
            disabled={fixedPrice}
            defaultValue={fixedPrice ? (data.price / 100.0) : ""}
            onChange={e => {
              let price = Math.round(parseFloat(e.target.value) * 100)

              let value = {field: data.id, value: price};
              data.error = false
              data.answer = value

              this.setState({data})
              this.props.onUpdated(index, value)
            }}
          />
        </div>
        {data.error &&
          <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">
            {errorMessage}
          </p>
        }
      </div>
    )
  }
}
